import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import fetch from "../shared/Fetch";
import { ReactComponent as CloseIcon } from "../svgs/CloseIcon.svg";

const FilteringBarContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  padding-bottom: 2rem;
  gap: 5rem;
`;

const SectionContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  gap: 1.5rem;
`;

const SectionTitle = styled.span`
  font-size: 1.2rem;
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  gap: 0.5rem;
`;

const FilterPill = styled(Link)`
  background: ${props =>
    props.selected
      ? "var(--filter-button-selected)"
      : "var(--button-background-color)"};
  border-radius: 5rem;
  color: ${props =>
    props.selected ? "white" : "var(--filter-button-selected)"};
  padding: 0.5rem 0.7rem;
  border: 1px solid transparent;
  border-color: ${props =>
    props.selected ? "transparent" : "var(--filter-button-selected)"};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;
  font-weight: ${props => (props.selected ? "bold" : "normal")};
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${props =>
      props.selected ? "white" : "var(--filter-button-hovered-color)"};
    border-color: ${props =>
      props.selected ? "transparent" : "var(--filter-button-hovered-color)"};
  }
  & svg:first-child {
    fill: red;
    margin-right: 0.5rem;
    display: ${props => (props.selected ? "grid" : "none")};
  }
`;

const FilteringBar = ({ selectedGroups, selectedDepartments }) => {
  const [groups, setGroups] = useState([]);
  const [departments, setDepartments] = useState([]);

  let useGroups = false;
  if (selectedGroups) {
    useGroups = true;
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchGroups = async () => {
    try {
      const response = await fetch(`/groups`);
      const sortedGroups = response.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : 0
      );
      setGroups(sortedGroups);
    } catch (err) {
      console.log("Request resulted in an error", err);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await fetch(`/departments`);
      setDepartments(response);
    } catch (err) {
      console.log("Request resulted in an error", err);
    }
  };

  const fetchData = async () => {
    try {
      await Promise.all([fetchGroups(), fetchDepartments()]);
    } finally {
    }
  };

  const getUpdatedDepartmentPath = id => {
    let pathname = window.location.pathname;
    let params = pathname.split("/");
    let departments = params[1];

    let departmentsArray = departments.split(",");
    if (departmentsArray.find(d => d === id)) {
      const filteredDepartments = departmentsArray.filter(d => d !== id);
      if (filteredDepartments.length > 0) {
        departmentsArray = filteredDepartments;
      }
    } else {
      const storedDepartments = [...departmentsArray];
      storedDepartments.push(id);
      departmentsArray = storedDepartments;
    }
    params[1] = departmentsArray.join(",");
    return params.join("/");
  };

  const getUpdatedGroupPath = id => {
    let pathname = window.location.pathname;
    let params = pathname.split("/");
    let groups = params[2];

    let groupsArray = groups.split(",");
    if (groupsArray.find(g => g === id)) {
      const filteredGroups = groupsArray.filter(g => g !== id);
      if (filteredGroups.length === 0) {
        filteredGroups.push("all");
      }
      groupsArray = filteredGroups;
    } else {
      const storedGroups = [...groupsArray];
      storedGroups.push(id);
      groupsArray = storedGroups.filter(g => g !== "all");
    }

    params[2] = groupsArray.length > 0 ? groupsArray.join(",") : groups;

    return params.join("/");
  };

  return (
    <FilteringBarContainer>
      <SectionContainer>
        <SectionTitle>Filtrer etter avdelinger</SectionTitle>
        <ButtonsContainer>
          {departments.map((department, i) => (
            <FilterPill
              key={department.id + "" + i}
              to={getUpdatedDepartmentPath(department.id)}
              selected={
                selectedDepartments &&
                !!selectedDepartments.find(d => d === department.id)
              }
            >
              <CloseIcon />
              {department.name}
            </FilterPill>
          ))}
        </ButtonsContainer>
      </SectionContainer>
      {useGroups && (
        <SectionContainer>
          <SectionTitle>Filtrer etter grupper</SectionTitle>
          <ButtonsContainer>
            {groups.map((group, i) => (
              <FilterPill
                key={group.id + "" + i}
                to={getUpdatedGroupPath(group.id)}
                selected={
                  selectedGroups && !!selectedGroups.find(g => g === group.id)
                }
              >
                <CloseIcon />
                {group.name}
              </FilterPill>
            ))}
          </ButtonsContainer>
        </SectionContainer>
      )}
    </FilteringBarContainer>
  );
};

export default FilteringBar;
