export async function getApiUrl() {
  if (process.env.NODE_ENV !== "production") {
    return "http://localhost:3001";
  }

  try {
    const result = await fetch("/env");
    const data = await result.json();
    return data.API_URL;
  } catch (e) {
    throw new Error("Cannot get API URL dynamically");
  }
}
