import React from "react";
import styled from "styled-components";
import moment from "moment";
import Autocomplete from "react-autocomplete";
import { Link } from "react-router-dom";
import { SelectableGroup } from "react-selectable";
import { getOffsetYearWeek } from "./shared/TimeUtil";
import fetch from "./shared/Fetch";
import StaffingRow from "./sharedComponents/StaffingRow";
import Table from "./sharedComponents/Table";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";
import { Button, AddNewButton } from "./sharedComponents/Atoms";
import { ReactComponent as AddIcon } from "./svgs/AddIcon.svg";

import { StaffingHoursTableCell, Heading } from "./sharedComponents/Atoms";

const HolidayButton = styled(Button)``;

const TableButton = styled(AddNewButton)`
  display: ${props => (props.hidden ? "none" : "flex")};
  margin-bottom: 1rem;
`;

const AbortButton = styled(Button)`
  margin-bottom: 1.5rem;
  padding: 0.4rem 0.9rem;
`;

const CustomerName = styled.td`
  position: relative;
  padding: 0.5rem;
  width: 20%;
  white-space: unset;
  :first-child {
    white-space: unset;
  }

  @media (max-width: 750px) {
    padding: 0;
    input {
      font-size: 0.7rem;
      width: 100%;
    }
  }
`;

const List = styled.ul`
  list-style-type: none;
`;

const Pill = styled.span`
  display: inline-block;
  border-radius: 50%;
  padding: 0.5rem;
`;

const AvailablePill = styled(Pill)`
  background-color: var(--table-available);
`;

const HolidayPill = styled(Pill)`
  background-color: var(--table-vacation);
`;

const OfferedPill = styled(Pill)`
  background-color: var(--table-offered);
`;

const OverbookingPill = styled(Pill)`
  background-color: var(--table-overbooked);
`;

const ProjectName = styled.td`
  position: relative;
  padding: 0.5rem;
  width: 20%;

  @media (max-width: 750px) {
    padding: 0;
    input {
      font-size: 0.7rem;
      width: 100%;
    }
  }
`;

const StyledNewProjectRow = styled.tr``;

const SumLeadingColumn = styled.td`
  padding: 10px 5px 10px 5px;
  @media (max-width: 750px) {
    padding: 5px 0 5px 0;
    font-size: 0.6rem;
  }
`;

const SumRow = styled.tr`
  font-weight: bold;
  padding: 10px 5px 10px 5px;

  @media (max-width: 750px) {
    font-weight: normal;
    font-size: 0.7rem;
  }
`;

const ValidationError = styled.div`
  position: absolute;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  background-color: #ffdbdb;
  color: var(--color-red);
  bottom: 100%;
  z-index: 3;
`;

const TableContainer = styled.div``;

const AddProjectRow = styled.tr`
  width: 100%;
  column-span: all;
`;

const StyledLink = styled(Link)`
  font-size: 1.2rem;
  color: var(--button-primary-color);
  width: max-content;
  margin: 0.5rem 0;
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  border-bottom: 1px solid transparent;

  &:hover {
    color: var(--theme-link-color);
    border-bottom: 1px solid;
  }
`;

const AddIconStyled = styled(AddIcon)`
  height: 1.1rem;
  width: 1.1rem;
  margin-right: 0.3rem;
  & * {
    fill: var(--color-grey);
  }
`;

class Consultant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewProjectRow: false,
      consultant: { engagements: [] },
      customers: [],
      yearWeeks: [],
      orderedWeekSum: [],
      offeredWeekSum: [],
      year: moment().year(),
      vacationWeeks: [],
      vacationDays: [],
      focusEngagementId: "",
      waitingForData: true,
      fullDayHours: 8.0,
      departments: this.props.match.params.departments,
      groups: this.props.match.params.groups,
      calendarFeature: process.env.REACT_APP_SHOW_CALENDAR === "true",
      department: {}
    };

    // This binding is necessary to make `this` work in the callback
    this.toggleNewProject = this.toggleNewProject.bind(this);
    this.saveNewProject = this.saveNewProject.bind(this);
    this.fetchConsultants = this.fetchConsultants.bind(this);
    this.updateWeekSum = this.updateWeekSum.bind(this);
    this.handleDragSelection = this.handleDragSelection.bind(this);
    this.sumbitStaffing = this.sumbitStaffing.bind(this);
    this.engagementDeleted = this.engagementDeleted.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.offset !== prevProps.match.params.offset ||
      this.props.match.params.weeks !== prevProps.match.params.weeks
    ) {
      this.fetchData();
    }
  }

  setStaffing(hours, engagementIndex, weekIndex) {
    let consultant = this.state.consultant;
    let engagementIdToUpdate =
      consultant.engagements[engagementIndex].engagementId;
    let yearWeekToUpdate =
      consultant.engagements[engagementIndex].staffing[weekIndex].yearWeek;

    consultant.engagements[engagementIndex].staffing[weekIndex].hours = hours;
    this.setState({ consultant: consultant });
    this.sumbitStaffing(
      hours,
      this.state.consultant.consultantId,
      engagementIdToUpdate,
      yearWeekToUpdate
    );
  }

  handleDragSelection(selectedKeys) {
    if (!selectedKeys || !selectedKeys[0]) return;

    let firstEngagementIndex = selectedKeys[0].engagementIndex;
    let firstWeekIndex = selectedKeys[0].weekIndex;
    let hours = this.state.consultant.engagements[firstEngagementIndex]
      .staffing[firstWeekIndex].hours;

    for (let i = 1; i < selectedKeys.length; i++) {
      this.setStaffing(
        hours,
        selectedKeys[i].engagementIndex,
        selectedKeys[i].weekIndex
      );
    }
    this.updateWeekSum();
  }

  sumbitStaffing(hours, consultantId, engagementId, yearWeek) {
    fetch("/staffings", {
      method: "POST",
      body: JSON.stringify({
        hours: hours,
        consultantId: consultantId,
        engagementId: engagementId,
        yearWeek: yearWeek
      })
    });
  }

  render() {
    const {
      consultant,
      yearWeeks,
      orderedWeekSum,
      offeredWeekSum,
      bookingStatus,
      departments,
      groups,
      fullDayHours,
      department
    } = this.state;

    const offset = this.props.match.params.offset;
    const weeks = this.props.match.params.weeks;
    const id = this.state.consultant.consultantId;
    const availableOnly = this.props.match.params.availableOnly;
    const to = `/${departments}/${groups}/calendar/${id}/${weeks}/${offset}/${availableOnly}`;

    let vacationButton = null;
    if (this.state.calendarFeature) {
      vacationButton = (
        <Link to={to} style={{ width: "max-content" }}>
          <HolidayButton>Ferie</HolidayButton>
        </Link>
      );
    }
    return (
      <div className="module">
        <AppHeading
          departments={departments}
          offset={offset}
          weeks={weeks}
          groups={groups}
          availableOnly={availableOnly}
          active={"consultants"}
        />
        <div className={"content"}>
          <Heading>
            {consultant.consultantName}
            <StyledLink
              to={`/${departments}/${groups}/administer/${weeks}/${offset}/${availableOnly}`}
            >
              <span>{department.name}</span>/
              <span>
                {`${
                  consultant.groups && consultant.groups.length > 0
                    ? consultant.groups.map(g => g.name).join(", ")
                    : "-"
                }`}
              </span>
            </StyledLink>
            {vacationButton}
          </Heading>
          <TableContainer>
            <div className="consultant">
              {
                <form
                  className="consultant__container"
                  style={{ marginTop: "5px" }}
                >
                  <Spinner show={this.state.waitingForData} />
                  <SelectableGroup
                    preventDefault={false}
                    onSelection={this.handleDragSelection}
                  >
                    <Table
                      leadingHeaders={["Kunde", "Prosjekt", ""]}
                      yearWeeks={yearWeeks}
                      params={this.props.match.params}
                      inner={"consultant/" + id}
                    >
                      {consultant.engagements.map((engagement, i) => (
                        <StaffingRow
                          key={i}
                          engagementIndex={i}
                          leadingValues={[
                            {
                              link: "customer/" + engagement.customerId,
                              value: engagement.customerName
                            },
                            {
                              link: "project/" + engagement.engagementId,
                              value: engagement.engagementName
                            }
                          ]}
                          engagementStatus={engagement.engagementStatus}
                          data={engagement}
                          consultantId={consultant.consultantId}
                          endDate={consultant.endDate}
                          engagementId={engagement.engagementId}
                          vacation={engagement.vacation}
                          availableOnly={this.props.match.params.availableOnly}
                          updateWeekSum={this.updateWeekSum}
                          departments={departments}
                          groups={groups}
                          weeks={this.props.match.params.weeks}
                          offset={this.props.match.params.offset}
                          sumbitStaffing={this.sumbitStaffing}
                          onDelete={this.engagementDeleted}
                          fullDayHours={fullDayHours}
                          setFocus={
                            this.state.focusEngagementId ===
                            engagement.engagementId
                          }
                        />
                      ))}
                      {!this.state.showNewProjectRow ? (
                        <AddProjectRow key="addProjectRow">
                          <td>
                            <TableButton
                              hidden={this.state.showNewProjectRow}
                              type="button"
                              onClick={this.toggleNewProject}
                            >
                              <AddIconStyled />
                              Nytt prosjekt
                            </TableButton>
                          </td>
                        </AddProjectRow>
                      ) : (
                        <NewProjectRow
                          consultant={consultant}
                          customers={this.state.customers}
                          newProjectSelected={this.saveNewProject}
                          weeks={this.props.match.params.weeks}
                          focusedOnMount={true}
                          canceledButton={this.toggleNewProject}
                        />
                      )}
                      <SumRow className="line">
                        <SumLeadingColumn key="emptyCell">
                          Bemanning
                        </SumLeadingColumn>
                        <td />
                        <td />
                        {orderedWeekSum.map((sum, i) => (
                          <StaffingHoursTableCell
                            key={i}
                            ordered={sum}
                            overbooked={bookingStatus[i].overbooked}
                            showoverbooked={true}
                            available={bookingStatus[i].available}
                            holiday={bookingStatus[i].holiday}
                          />
                        ))}
                      </SumRow>
                      <SumRow>
                        <SumLeadingColumn showoverbooked={true}>
                          Tilbud og bemanning
                        </SumLeadingColumn>
                        <td />
                        <td />
                        {offeredWeekSum.map((sum, i) => (
                          <StaffingHoursTableCell
                            key={i}
                            ordered={sum}
                            showoverbooked={true}
                          />
                        ))}
                      </SumRow>
                    </Table>
                  </SelectableGroup>
                </form>
              }
              <List className="table-helpbox-list">
                <li>
                  <OfferedPill /> Tilbud
                </li>
                <li>
                  <AvailablePill /> Ledig tid
                </li>
                <li>
                  <OverbookingPill /> Overbooking
                </li>
                <li>
                  <HolidayPill /> Ferie
                </li>
                <li className="holiday">Helligdag</li>
              </List>
            </div>
          </TableContainer>
        </div>
      </div>
    );
  }

  updateWeekSum() {
    let orderedAndInternalWeekSum = this.getWeekSum(
      this.state.consultant.engagements,
      true,
      true
    );
    this.setState({
      orderedWeekSum: this.getWeekSum(this.state.consultant.engagements, true),
      offeredWeekSum: this.getWeekSum(this.state.consultant.engagements, false),
      bookingStatus: this.calculateBookingStatus(
        orderedAndInternalWeekSum,
        this.state.consultant.settings.fullWeekHourArray,
        this.state.consultant.disableWarnOnOverbooked
      )
    });
  }

  getWeekSum(engagements, isOrdered = true, isInternal = false) {
    let numWeeks = parseInt(this.props.match.params.weeks, 10);
    if (engagements.length === 0) return Array(numWeeks).fill(0);
    return engagements
      .filter(
        engagement =>
          (isOrdered &&
            (engagement.engagementStatus.toLowerCase() === "ordre" ||
              engagement.engagementStatus.toLowerCase() === "ferdig")) ||
          (!isOrdered &&
            engagement.engagementStatus.toLowerCase() === "tilbud") ||
          engagement.engagementStatus.toLowerCase() === "ordre" ||
          engagement.engagementStatus.toLowerCase() === "ferdig" ||
          (isInternal &&
            engagement.engagementStatus.toLowerCase() === "internt")
      )
      .map(engagement => engagement.staffing)
      .reduce((result, currentValue) => {
        for (var i = 0; currentValue.length > i; i++) {
          result[i] += parseFloat(currentValue[i].hours, 10);
        }
        return result;
      }, Array(engagements[0].staffing.length).fill(0));
  }

  saveNewProject(newEngagementId) {
    fetch("/staffings", {
      method: "POST",
      body: JSON.stringify({
        consultantId: this.state.consultant.consultantId,
        engagementId: newEngagementId,
        yearWeek: getOffsetYearWeek(this.props.match.params.offset),
        hours: 0
      })
    }).then(
      response => {
        this.setState(
          { showNewProjectRow: false, focusEngagementId: newEngagementId },
          this.fetchConsultants()
        );
      },
      reason => {
        console.log(
          "Something went wrong while performing the request",
          reason
        );
      }
    );
  }

  toggleNewProject(e) {
    e.preventDefault();
    this.setState({ showNewProjectRow: !this.state.showNewProjectRow });
  }

  engagementDeleted(consultantId, engagementId) {
    let engagements = this.state.consultant.engagements.filter(
      eng => eng.engagementId !== engagementId
    );
    let consultant = this.state.consultant;
    consultant.engagements = engagements;
    this.setState({ consultant: consultant }, this.fetchConsultants());
  }

  fetchData() {
    this.setState({ waitingForData: true });
    this.fetchConsultants();
    if (this.state.customers.length === 0) {
      this.fetchCustomers();
    }
  }

  async fetchVacation() {
    const id = this.props.match.params.id;
    try {
      const response = await fetch(
        `/vacation/consultants/${id}/${this.state.year}`
      );
      let vacationDays = [];
      let spentVacationDays = [];
      let vacationWeeks = [];
      if (response.vacations) {
        for (let vac of response.vacations) {
          if (vac) {
            let m = moment(vac, "DD-MM-YYYY");
            if (m.diff(moment()) <= 0) {
              spentVacationDays.push(
                moment(vac, "DD-MM-YYYY").format("DDMMYYYY")
              );
            }
            vacationDays.push(moment(vac, "DD-MM-YYYY").format("DDMMYYYY"));
            vacationWeeks.push(moment(vac, "DD-MM-YYYY").format("W"));
          }
        }
      }
      this.setState({
        vacationDays: vacationDays,
        spentVacationDays: spentVacationDays,
        vacationWeeks: vacationWeeks,
        waitingForData: false
      });
    } catch (err) {
      console.log("Request resulted in an error", err);
    }
  }

  fetchDepartment() {
    const id = this.state.consultant.departmentId;
    if (!id) {
      return;
    }

    fetch(`/departments/${id}`)
      .catch(reason => {
        console.log("Request resulted in an error", reason);
      })
      .then(response => {
        this.setState({ department: response[0] });
      });
  }

  fetchConsultants() {
    const { id, departments } = this.props.match.params;

    if (this.state.calendarFeature) this.fetchVacation();

    fetch(
      `/consultants/${id}/offset/${this.props.match.params.offset}/weeks/${this.props.match.params.weeks}?departments=${departments}`
    )
      .catch(reason => {
        console.log("Request resulted in an error", reason);
      })
      .then(response => {
        const yearWeeks = response.emptyStaffing.map(week => week.yearWeek);
        const orderedWeekSum = this.getWeekSum(response.engagements, true);
        const offeredWeekSum = this.getWeekSum(response.engagements, false);
        const orderedAndInternalWeekSum = this.getWeekSum(
          response.engagements,
          true,
          true
        );

        response.engagements
          .sort((a, b) => {
            let index =
              a.customerName.toLowerCase() < b.customerName.toLowerCase()
                ? 0
                : 1;

            if (
              b.engagementStatus === "Internt" ||
              b.engagementStatus === "Tilbud"
            ) {
              index = -1;
            }
            return index;
          })
          .reverse();
        const bookingStatus = this.calculateBookingStatus(
          orderedAndInternalWeekSum,
          response.settings.fullWeekHourArray,
          response.disableWarnOnOverbooked
        );

        this.setState({
          consultant: response,
          yearWeeks: yearWeeks,
          orderedWeekSum: orderedWeekSum,
          offeredWeekSum: offeredWeekSum,
          bookingStatus: bookingStatus,
          waitingForData: false,
          fullDayHours: response.settings.fullDayHours
        });
        this.fetchDepartment();
      });
  }

  calculateBookingStatus(
    orderedWeekSum,
    fullWeekHourArray,
    disableWarnOnOverbooked
  ) {
    let bookingStatus = [];

    let vacationWeeks = this.state.vacationWeeks;
    let weeks = [];
    for (let i = 0; i < vacationWeeks.length; i++) {
      weeks.push(vacationWeeks[i] - moment.utc().format("W"));
    }
    for (let i = 0; i < orderedWeekSum.length; i++) {
      let vacation = false;
      for (let j = 0; j < vacationWeeks.length; j++) {
        if (i === weeks[j]) {
          vacation = true;
        }
      }
      let available = fullWeekHourArray[i].fullWeekHours > orderedWeekSum[i];
      let overbooked = false;
      if (!disableWarnOnOverbooked)
        overbooked = fullWeekHourArray[i].fullWeekHours < orderedWeekSum[i];
      let holiday = fullWeekHourArray[i].fullWeekHours < 35;
      bookingStatus.push({
        available: available,
        overbooked: overbooked,
        holiday: holiday,
        vacation: vacation
      });
    }
    return bookingStatus;
  }

  fetchCustomers() {
    fetch("/customers")
      .catch(reason => {
        console.log("Request resulted in an error", reason);
      })
      .then(response => {
        this.setState({ customers: response });
      });
  }
}

class NewProjectRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchCustomerName: "",
      searchEngagementName: "",
      newCustomer: {},
      engagements: [],
      customers: this.purgeProjects(),
      validationMessage: {
        customer: "",
        engagements: ""
      }
    };

    this.customerNameRef = React.createRef();
  }

  purgeProjects() {
    const customers = this.props.customers.slice().map(customer => {
      customer.engagements = customer.engagements.filter(customerEngagement => {
        if (customerEngagement.status === "Tapt") return false;
        let keepEngagement = true;
        this.props.consultant.engagements.forEach(consultantEngagement => {
          if (
            consultantEngagement.engagementId ===
            customerEngagement.engagementId
          ) {
            keepEngagement = false;
          }
        });
        return keepEngagement;
      });
      return customer;
    });
    return customers.filter(customers => customers.engagements.length > 0);
  }

  validateAutocomplete(field, items, item, selector) {
    const store = message =>
      this.setState({
        validationMessage: {
          ...this.state.validationMessage,
          [field]: message
        }
      });
    const predicate = i => selector(i).toLowerCase() === item.toLowerCase();

    if (items.some(predicate)) {
      store("");
    } else {
      store("Valget finnes ikke. Om du vil bruke det må det opprettes først");
    }
  }

  cleanAutocomplete(field) {
    const store = message =>
      this.setState({
        validationMessage: {
          ...this.state.validationMessage,
          [field]: message
        }
      });
    store("");
  }

  componentDidMount() {
    const { focusedOnMount } = this.props;
    if (focusedOnMount) {
      this.customerNameRef.current.focus();
    }
  }

  render() {
    const { customers, validationMessage } = this.state;

    let validationCustomer =
      validationMessage.customer === "" ? null : (
        <ValidationError>{validationMessage.customer}</ValidationError>
      );
    let validationEngagements =
      validationMessage.engagements === "" ? null : (
        <ValidationError>{validationMessage.engagements}</ValidationError>
      );

    return (
      <StyledNewProjectRow>
        <CustomerName>
          <Autocomplete
            ref={this.customerNameRef}
            inputProps={{
              placeholder: "Kunde",
              className: `new-project-input ${
                validationMessage.customer === "" ? "" : "project-input-invalid"
              }`,
              onBlur: e => {
                this.validateAutocomplete(
                  "customer",
                  customers,
                  e.currentTarget.value,
                  item => item.customerName
                );
              },
              onFocus: e => {
                this.cleanAutocomplete("customer");
              }
            }}
            items={customers}
            shouldItemRender={(item, value) =>
              item.customerName.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            getItemValue={item => item.customerName}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.customerId}
                style={{
                  background: isHighlighted ? "lightgray" : "white",
                  padding: "0.5rem",
                  cursor: "pointer"
                }}
              >
                {item.customerName}
              </div>
            )}
            value={this.state.searchCustomerName}
            onChange={e =>
              this.setState({ searchCustomerName: e.target.value })
            }
            onSelect={(value, item) => {
              this.validateAutocomplete(
                "customer",
                customers,
                value,
                item => item.customerName
              );
              this.setState({
                newCustomer: item,
                engagements: item.engagements,
                searchCustomerName: value,
                searchEngagementName: ""
              });
            }}
          />
          {validationCustomer}
        </CustomerName>
        <ProjectName>
          <Autocomplete
            inputProps={{
              placeholder: "Prosjekt",
              className: `new-project-input ${
                validationMessage.engagements === ""
                  ? ""
                  : "project-input-invalid"
              }`,
              onBlur: e => {
                this.validateAutocomplete(
                  "engagements",
                  this.state.engagements,
                  e.currentTarget.value,
                  item => item.engagementName
                );
              },
              onFocus: e => {
                this.cleanAutocomplete("engagements");
              }
            }}
            items={this.state.engagements}
            shouldItemRender={(item, value) =>
              item.engagementName.toLowerCase().indexOf(value.toLowerCase()) >
              -1
            }
            getItemValue={item => item.engagementName}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.engagementId}
                style={{
                  background: isHighlighted ? "lightgray" : "white",
                  padding: "0.5rem",
                  cursor: "pointer"
                }}
              >
                {item.engagementName}
              </div>
            )}
            value={this.state.searchEngagementName}
            onChange={e =>
              this.setState({ searchEngagementName: e.target.value })
            }
            onSelect={(value, item) => {
              this.setState({ searchEngagementName: value });
              this.props.newProjectSelected(item.engagementId);
              this.validateAutocomplete(
                "engagements",
                this.state.engagements,
                value,
                item => item.engagementName
              );
            }}
          />
          {validationEngagements}
        </ProjectName>
        <td>
          <AbortButton onClick={this.props.canceledButton}>Avbryt</AbortButton>
        </td>
        <td colSpan={2} />

        <td colSpan={this.props.weeks} />
        <td />
      </StyledNewProjectRow>
    );
  }
}

export default Consultant;
