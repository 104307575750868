import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Table from "./sharedComponents/Table";
import StaffingRow from "./sharedComponents/StaffingRow";
import { getOffsetYearWeek } from "./shared/TimeUtil";
import Autocomplete from "react-autocomplete";
import fetch from "./shared/Fetch";
import { format } from "./shared/NumberUtil";
import { SelectableGroup } from "react-selectable";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";
import {
  EditableHeading,
  Button,
  AddNewButton
} from "./sharedComponents/Atoms";
import { ReactComponent as AddIcon } from "./svgs/AddIcon.svg";

const Content = styled.div`
  margin-left: 0.2rem;
`;

const SubHeading = styled.h2`
  padding: 0 0 1rem 0;
  font-size: 1rem;
  display: inline;
  margin-left: 7px;
`;

const StyledNewConsultantRow = styled.tr`
  &:nth-child(2n) {
    background: var(--table-band-background);
  }
  &:hover {
    background: var(--table-hover-background);
  }
`;

const ConsultantName = styled.td`
  padding: 0.5rem;
  width: 40%;
`;

const WeekData = styled.td`
  white-space: nowrap;
  text-align: right;
`;

const StatusIndicator = styled.div`
  display: ${props => (props.offer ? "inline-block" : "none")};
  height: 100%;
  width: 5px;
  background: var(--table-offered);
  position: absolute;
  top: 0;
  left: 0;
`;

const EngagementMetaDataInput = styled.input`
  padding: 3px;
  width: 12rem;
`;

const MeataDataLabel = styled.label`
  display: inline-block;
  width: 10rem;
`;

const MetaDataContainer = styled.div`
  margin-top: 10px;
`;

const HiddenSelect = styled.select`
  background: transparent;
  padding: 3px;
  width: 12rem;
  -webkit-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
`;

const SumRow = styled.tr`
  font-weight: bold;
  border-top: 1px solid;
`;

const Container = styled.div``;

const AddNewProjectButton = styled(AddNewButton)`
  display: ${props => (props.hidden ? "none" : "flex")};
`;

const AddIconStyled = styled(AddIcon)`
  margin-right: 0.5rem;
  height: 1.3rem;
  width: 1.3rem;
  & * {
    fill: var(--button-primary-color);
  }
`;

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddConsultantRow: false,
      engagement: {
        consultants: [],
        engagementStatus: "",
        engagementName: "",
        engagementPartner: "",
        engagementProjectManager: ""
      },
      yearWeeks: [],
      engagementWeekSum: [],
      newConsultantId: "",
      focusConsultantId: "",
      fullDayHours: 8.0,
      valueChanged: false,
      waitingForData: true,
      departments: this.props.match.params.departments,
      groups: this.props.match.params.groups
    };
    // This binding is necessary to make `this` work in the callback
    this.toggleAddConsultant = this.toggleAddConsultant.bind(this);
    this.updateWeekSum = this.updateWeekSum.bind(this);
    this.selectNewConsultant = this.selectNewConsultant.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveConsultantToProject = this.saveConsultantToProject.bind(this);
    this.saveEngagement = this.saveEngagement.bind(this);
    this.handleDragSelection = this.handleDragSelection.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.consultantRemoved = this.consultantRemoved.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  toggleAddConsultant() {
    this.setState({ showAddConsultantRow: !this.state.showAddConsultantRow });
  }

  updateWeekSum() {
    this.setState({
      engagementWeekSum: this.getWeekSum(this.state.engagement.consultants)
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.offset !== prevProps.match.params.offset ||
      this.props.match.params.weeks !== prevProps.match.params.weeks
    ) {
      this.fetchData();
    }
  }

  selectNewConsultant(newConsultantId) {
    this.setState({
      newConsultantId: newConsultantId
    });
  }

  handleInputChange(e) {
    const engagement = this.state.engagement;
    const property = e.target.name;
    if (engagement[property] !== e.target.value) {
      engagement[property] = e.target.value;
      if (e.target.type === "select-one") {
        this.setState(
          { engagement: engagement, valueChanged: true },
          this.saveEngagement
        );
      } else {
        this.setState({ engagement: engagement, valueChanged: true });
      }
    }
  }

  render() {
    const {
      yearWeeks,
      engagement,
      engagementWeekSum,
      departments,
      groups
    } = this.state;
    let weeks = this.props.match.params.weeks;
    let offset = this.props.match.params.offset;
    const availableOnly = this.props.match.params.availableOnly;

    return (
      <div className="module">
        <AppHeading
          departments={departments}
          offset={offset}
          weeks={weeks}
          groups={groups}
          availableOnly={availableOnly}
          active={"customers"}
        />
        <div className="project content">
          <StatusIndicator
            offer={engagement.engagementStatus.toLowerCase() === "tilbud"}
          />
          <Container>
            <EditableHeading
              onChange={this.handleInputChange}
              onBlur={this.saveEngagement}
              name="engagementName"
              value={engagement.engagementName}
            />
            <Content>
              for
              <SubHeading>
                <Link
                  to={
                    "/" +
                    departments +
                    "/customer/" +
                    engagement.customerId +
                    "/" +
                    weeks +
                    "/" +
                    offset +
                    "/" +
                    groups +
                    "/" +
                    availableOnly
                  }
                >
                  {engagement.customerName}
                </Link>
              </SubHeading>
              <div>
                <MetaDataContainer>
                  <MeataDataLabel>Ansvarlig partner: </MeataDataLabel>
                  <EngagementMetaDataInput
                    onChange={this.handleInputChange}
                    onBlur={this.saveEngagement}
                    name="engagementPartner"
                    value={engagement.engagementPartner}
                  />
                </MetaDataContainer>
                <MetaDataContainer>
                  <MeataDataLabel>Prosjektleder: </MeataDataLabel>
                  <EngagementMetaDataInput
                    onChange={this.handleInputChange}
                    onBlur={this.saveEngagement}
                    name="engagementProjectManager"
                    value={engagement.engagementProjectManager}
                  />
                </MetaDataContainer>
                <MetaDataContainer>
                  <MeataDataLabel>Status: </MeataDataLabel>
                  <HiddenSelect
                    className="testSelect"
                    onChange={this.handleInputChange}
                    onBlur={this.saveEngagement}
                    value={engagement.engagementStatus}
                    name="engagementStatus"
                  >
                    <option>Tilbud</option>
                    <option>Tapt</option>
                    <option defaultValue>Ordre</option>
                    <option>Avsluttet</option>
                    <option>Internt</option>
                  </HiddenSelect>
                </MetaDataContainer>
              </div>
            </Content>
            <div className="consultant__container">
              <Spinner show={this.state.waitingForData} />
              <SelectableGroup
                preventDefault={false}
                onSelection={this.handleDragSelection}
              >
                <Table
                  leadingHeaders={["Konsulenter", ""]}
                  yearWeeks={yearWeeks}
                  inner={"project/" + engagement.engagementId}
                  params={this.props.match.params}
                >
                  {engagement.consultants.map((consultant, i) => (
                    <StaffingRow
                      key={i}
                      consultantIndex={i}
                      leadingValues={[
                        {
                          link: "consultant/" + consultant.consultantId,
                          value: consultant.consultantName
                        }
                      ]}
                      engagementId={engagement.engagementId}
                      vacation={engagement.vacation}
                      data={consultant}
                      consultantId={consultant.consultantId}
                      updateWeekSum={this.updateWeekSum}
                      engagementStatus={engagement.engagementStatus}
                      availableOnly={availableOnly}
                      departments={departments}
                      groups={groups}
                      fullDayHours={this.state.fullDayHours}
                      weeks={weeks}
                      offset={offset}
                      onDelete={this.consultantRemoved}
                      sumbitStaffing={this.sumbitStaffing}
                      setFocus={
                        this.state.focusConsultantId === consultant.consultantId
                      }
                    />
                  ))}
                  {!this.state.showAddConsultantRow ? null : (
                    <AddConsultantRow
                      engagement={engagement}
                      departments={departments}
                      newConsultantSelected={this.saveConsultantToProject}
                      weeks={weeks}
                    />
                  )}
                  <SumRow>
                    <ConsultantName>Sum</ConsultantName>
                    <td />
                    {engagementWeekSum.map((sum, i) => (
                      <WeekData key={i}>{format(sum)}</WeekData>
                    ))}
                  </SumRow>
                </Table>
              </SelectableGroup>

              <AddNewProjectButton
                hidden={this.state.showAddConsultantRow}
                onClick={this.toggleAddConsultant}
              >
                <AddIconStyled />
                Legg til konsulent
              </AddNewProjectButton>
              <Button
                style={{
                  display: this.state.showAddConsultantRow
                    ? "inline-block"
                    : "none",
                  marginLeft: "10px"
                }}
                onClick={this.toggleAddConsultant}
              >
                Avbryt
              </Button>
            </div>
          </Container>
        </div>
      </div>
    );
  }

  getWeekSum(consultants) {
    return consultants
      .map(consultant => consultant.staffing)
      .reduce((result, currentValue) => {
        for (var i = 0; currentValue.length > i; i++) {
          result[i] += parseFloat(currentValue[i].hours, 10);
        }
        return result;
      }, Array(consultants[0].staffing.length).fill(0));
  }

  sumbitStaffing(hours, consultantId, engagementId, yearWeek) {
    console.log("JJJJ", consultantId);

    fetch("/staffings", {
      method: "POST",
      body: JSON.stringify({
        hours: hours,
        consultantId: consultantId,
        engagementId: engagementId,
        yearWeek: yearWeek
      })
    });
  }

  /*setStaffing(hours, consultantIndex, weekIndex) {
    /*console.log("tst");
    let engagement = this.state.engagement;
    console.log(engagement);
    let consultantIdToYpdate =
      engagement.consultants[consultantIndex].consultantId;
    let yearWeekToUpdate =
      engagement.consultants[consultantIndex].staffing[weekIndex].yearWeek;
    engagement.consultants[consultantIndex].staffing[weekIndex].hours = hours;
    this.setState({ engagement: engagement });
    this.sumbitStaffing(
      hours,
      consultantIdToYpdate,
      this.state.engagement.engagementId,
      yearWeekToUpdate
    );
  }*/

  consultantRemoved(consultantID, engagementId) {
    let consultans = this.state.engagement.consultants.filter(
      cons => cons.engagementId !== engagementId
    );
    let engagement = this.state.engagement;
    engagement.consultants = consultans;
    this.setState({ engagement: engagement }, this.fetchData());
  }

  handleDragSelection(selectedKeys) {
    if (!selectedKeys || !selectedKeys[0]) return;

    let firstConsultantIndex = selectedKeys[0].consultantIndex;
    let firstWeekIndex = selectedKeys[0].weekIndex;
    let hours = this.state.engagement.consultants[firstConsultantIndex]
      .staffing[firstWeekIndex].hours;

    for (let i = 1; i < selectedKeys.length; i++) {
      this.setStaffing(
        hours,
        selectedKeys[i].consultantIndex,
        selectedKeys[i].weekIndex
      );
    }
    this.updateWeekSum();
  }

  fetchData() {
    this.setState({ waitingForData: true });
    const id = this.props.match.params.id;

    fetch(
      `/engagements/${id}/offset/${this.props.match.params.offset}/weeks/${this.props.match.params.weeks}`
    )
      .catch(reason => {
        console.log("Request resulted in an error", reason);
      })
      .then(response => {
        const yearWeeks = response.emptyStaffing.map(week => week.yearWeek);
        const engagementWeekSum = this.getWeekSum(response.consultants);
        this.setState({
          engagement: response,
          yearWeeks: yearWeeks,
          engagementWeekSum: engagementWeekSum,
          fullDayHours: response.settings.fullDayHours,
          waitingForData: false
        });
      });
  }

  saveEngagement() {
    if (this.state.valueChanged) {
      fetch("/engagements/" + this.state.engagement.engagementId, {
        method: "PUT",
        body: JSON.stringify({
          name: this.state.engagement.engagementName,
          status: this.state.engagement.engagementStatus,
          partner: this.state.engagement.engagementPartner,
          projectManager: this.state.engagement.engagementProjectManager
        })
      }).then(
        response => {
          this.setState({ valueChanged: false });
        },
        reason => console.log("Something went wrong during the request", reason)
      );
    }
  }

  saveConsultantToProject(newConsultantId) {
    fetch("/staffings", {
      method: "POST",
      body: JSON.stringify({
        consultantId: newConsultantId,
        engagementId: this.state.engagement.engagementId,
        yearWeek: getOffsetYearWeek(this.props.match.params.offset),
        hours: 0
      })
    }).then(
      response => {
        this.setState(
          { focusConsultantId: newConsultantId, showAddConsultantRow: false },
          this.fetchData()
        );
      },
      reason => {
        console.log(
          "Something went wrong while performing the request",
          reason
        );
      }
    );
  }
}

class AddConsultantRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchConsultantName: "",
      newConsultant: {},
      consultants: [],
      engagement: this.props.engagement,
      departments: this.props.departments
    };
  }

  componentDidMount() {
    this.fetchConsultants();
  }

  purgeConsultants(consultants) {
    return consultants.filter(consultant => {
      return !this.state.engagement.consultants.find(
        existingConsultant =>
          existingConsultant.consultantId === consultant.consultantId
      );
    });
  }

  render() {
    const { consultants } = this.state;
    return (
      <StyledNewConsultantRow>
        <ConsultantName>
          <Autocomplete
            inputProps={{
              placeholder: "Konsulent",
              className: "new-project-input"
            }}
            items={consultants}
            shouldItemRender={(item, value) =>
              item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            getItemValue={item => item.name}
            selectOnBlur={true}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.consultantId}
                style={{
                  background: isHighlighted ? "lightgray" : "white",
                  padding: "0.5rem"
                }}
              >
                {item.name}
              </div>
            )}
            value={this.state.searchConsultantName}
            onChange={e =>
              this.setState({ searchConsultantName: e.target.value })
            }
            onSelect={(value, item) => {
              this.setState({ searchConsultantName: value });
              this.props.newConsultantSelected(item.consultantId);
            }}
          />
        </ConsultantName>
        <td />
        <td colSpan={this.props.weeks} />
        <td />
      </StyledNewConsultantRow>
    );
  }

  fetchConsultants() {
    fetch("/consultants")
      .catch(reason => {
        console.log("Reqeust resulted in an error", reason);
      })
      .then(response => {
        const consultants = this.purgeConsultants(response);
        this.setState({
          consultants: consultants
        });
      });
  }
}

export default Project;
