import { getQueryVariable } from "./Param";
import { setToken } from "./Auth";

const tokenAccessName = process.env.REACT_APP_TOKEN_ACCESS;

export default async function(location) {
  const frame = createIframe(location);
  await new Promise(res => frame.addEventListener("load", res));
  try {
    if (!frame.contentWindow || !frame.contentWindow.location) {
      throw new Error("Content Window not set");
    }

    const loc = frame.contentWindow.location;
    const locHome = document.location;

    if (loc.host === locHome.host) {
      // Refresh works. Set new token from `loc`
      const token = getQueryToken(loc.hash);
      setToken(token);
      return;
    }
  } finally {
    frame.remove();
  }
}

function createIframe(url) {
  const iframe = document.createElement("iframe");
  iframe.src = url;
  iframe.classList.add("hiddenIframe");
  document.body.appendChild(iframe);
  return iframe;
}

export function getQueryToken(input) {
  return getQueryVariable(
    input,
    tokenAccessName ? tokenAccessName : "access_token"
  );
}
