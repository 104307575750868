import React from "react";
import moment from "moment";
import styled from "styled-components";
import { WeekContainer, Weeknum } from "./Atoms";

const Day = styled.button`
  width: 2.4rem;
  font-size: 1.2rem;
  line-height: 1.6;
  margin: 0.2rem;
  padding: 0.2rem;
  text-align: center;
  cursor: ${props => (props.holiday ? "auto" : "pointer")};
  border: 0;
  border-radius: 0.2rem;

  &:disabled {
    opacity: 0.7;
    cursor: auto;
  }

  &:hover {
    background: var(--theme-active-indicator);
  }

  &:hover {
    background: ${props =>
      props.vacation ? "var(--calendar-selected-day)" : ""};
  }

  &:hover {
    background: ${props => (props.holiday ? "none" : "")};
  }
  &:hover {
    background: ${props => (props.past ? "none" : "")};
  }

  background: ${props =>
    props.vacation ? "var(--calendar-selected-day)" : ""};

  color: ${props => (props.holiday ? "red" : "")};

  color: ${props => (props.past ? "var(--non-prioritizedt-text-color)" : "")};

  visibility: ${props => (props.outOfMonth ? "hidden" : "")};

  color: ${props => (props.vacation ? "var(--color-white)" : "")};
`;

const Checkbox = styled.input`
  appearance: none;
  border: 1px solid black;
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 1rem;
  position: relative;
  top: 0.7rem;
  width: 1rem;

  visibility: ${props => (props.outOfMonth ? "hidden" : "")};

  &:checked {
    background-color: var(--color-rose);
    border: 1px solid var(--color-rose);
  }

  &:disabled {
    cursor: auto;
    opacity: 0.4;
  }

  &:not(:disabled):hover {
    background-color: var(--color-rose);
  }
`;

const CheckboxWrapper = styled.div`
  height: 2rem;
  width: 2rem;
`;

class Week extends React.Component {
  toggleVacation(vacation) {
    let vacationDays = this.props.vacationDays;
    const currentweek = moment(
      "" + this.props.year + this.props.weekNumber,
      "YYYYWW"
    );
    let weekVacationDays = vacationDays.filter(vac =>
      moment(vac, "DDMMYYYY").isSame(currentweek, "W")
    );
    if (vacationDays.includes(vacation)) {
      this.props.removeVacation([vacation], weekVacationDays.length - 1);
    } else {
      this.props.addVacation([vacation], weekVacationDays.length + 1);
    }
  }

  toggleWeekVacation() {
    let firstDay = moment()
      .year(this.props.year)
      .isoWeek(this.props.weekNumber)
      .isoWeekday(1);

    let vacations = [];
    for (let i = 0; i < 5; i++) {
      if (
        !this.props.holidays.includes(firstDay.format("DDMMYYYY")) &&
        this.props.month === firstDay.month()
      )
        vacations.push(firstDay.format("DDMMYYYY"));
      firstDay.add(1, "days");
    }

    let vacationDays = this.props.vacationDays;

    const vacsAreActive = vacations.every(vacation =>
      vacationDays.includes(vacation)
    );

    if (vacsAreActive) {
      this.props.removeVacation(vacations, 0);
    } else {
      this.props.addVacation(vacations, vacations.length);
    }
  }

  render() {
    let m = moment()
      .year(this.props.year)
      .isoWeek(this.props.weekNumber)
      .isoWeekday(1);

    let f = moment()
      .year(this.props.year)
      .isoWeek(this.props.weekNumber)
      .isoWeekday(5);

    const weekOutOfMonth =
      m.month() !== this.props.month && f.month() !== this.props.month;

    let holidays = this.props.holidays;
    let vacationDays = this.props.vacationDays;
    let days = [];

    let vacations = [];

    let firstDay = moment()
      .year(this.props.year)
      .isoWeek(this.props.weekNumber)
      .isoWeekday(1);

    for (let i = 0; i < 5; i++) {
      if (this.props.month === firstDay.month())
        vacations.push(firstDay.format("DDMMYYYY"));
      firstDay.add(1, "days");
    }

    let allVacationsAreHoliday = vacations.every(vac => holidays.includes(vac));

    let checked = vacations.every(
      vac =>
        vacationDays.includes(vac) ||
        (!allVacationsAreHoliday && holidays.includes(vac))
    );

    let past = false;

    for (let i = 0; i < 5; i++) {
      let holiday = false;
      if (holidays.includes(m.format("DDMMYYYY"))) holiday = true;
      let vacation = false;
      if (vacationDays.includes(m.format("DDMMYYYY"))) vacation = true;
      past = false;
      if (m.diff(moment()) <= 0) past = true;

      days.push(
        <Day
          key={i}
          holiday={holiday}
          past={past}
          type="button"
          disabled={past}
          vacation={vacation}
          onClick={this.toggleVacation.bind(this, m.format("DDMMYYYY"))}
          outOfMonth={m.month() !== this.props.month}
        >
          {m.format("D")}
        </Day>
      );
      m.add(1, "days");
    }

    return (
      <WeekContainer>
        <CheckboxWrapper>
          <Checkbox
            type="checkbox"
            onChange={this.toggleWeekVacation.bind(this)}
            checked={checked}
            disabled={past}
            outOfMonth={weekOutOfMonth}
          />
        </CheckboxWrapper>
        <Weeknum disabled={past} outOfMonth={weekOutOfMonth}>
          {this.props.weekNumber}{" "}
        </Weeknum>
        {days}
      </WeekContainer>
    );
  }
}

export default Week;
