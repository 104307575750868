import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import fetch from "./shared/Fetch";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";
import { ReactComponent as DeleteIcon } from "./svgs/DeleteIcon.svg";
import { ReactComponent as AddIcon } from "./svgs/AddIcon.svg";
import { ReactComponent as PlusIcon } from "./svgs/PlusIcon.svg";
import { ReactComponent as RemoveIcon } from "./svgs/RemoveConsultantIcon.svg";
import { ReactComponent as ArrowDownIcon } from "./svgs/ArrowDownIcon.svg";

const GroupsContainer = styled.div`
  margin-bottom: -10rem; //
  display: grid;
  grid-template-columns: 30% 3rem 35% 20%;
  grid-template-rows: 120px max-content 1fr;

  padding: 1rem;
  color: var(--color-grey);
  padding-top: 4%;
  height: 90vh;
  overflow: auto;
  & ::-webkit-scrollbar {
    width: 5px;
  }

  & ::-webkit-scrollbar-track {
    background: transparent;
  }

  & ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  & ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
`;

const GroupListContainer = styled.div`
  grid-column: 1;
  grid-row: 3;
  display: grid;
  grid-template-rows: max-content max-content max-content;
  gap: 0.3rem;
  padding-left: 9rem;
`;

const GroupList = styled.ul`
  list-style: none;
  padding-bottom: 1rem;
`;

const Button = styled.button`
  background: transparent;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
  &:focus {
    outline: none;
  }
`;

const GroupButton = styled(Button)`
  padding: 0.5rem 0;
  color: var(--group-grey);
  font-weight: bold;
  font-size: 1.4rem;
  padding-bottom: 0.1rem;
  border-bottom: 2px solid transparent;
  border-color: ${props =>
    props.selected ? "var(--theme-active-indicator)" : "transparent"};
  & span {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const GroupsTitle = styled.p`
  grid-row: 2;
  grid-column: 1;
  font-size: 1rem;
  color: var(--color-lightergrey);
  font-weight: bold;
  padding-left: 9rem;
  margin: 0;
  align-self: center;
`;

const GroupSectionContainer = styled.div`
  grid-column: 2/-1;
  grid-row: 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content 1fr;
  margin-top: 2rem;
`;

const InputContainer = styled.div`
  grid-column: 3;
  grid-row: 1;
  display: flex;
  flex-direction: column;

  & span {
    font-size: 1.2rem;
    margin: 1.2rem 0;
  }
`;

const GroupInput = styled.input`
  padding: 1rem;
  font-size: 2rem;
  border: 1px solid #b7b7b7;
  border-radius: 0.5rem;
  &:focus {
    border: 4px var(--theme-active-indicator) solid;
    outline: none;
  }
`;

const ButtonsContainer = styled.div`
  grid-column: 3;
  grid-row: 2;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const DeleteGroupButton = styled(Button)`
  align-self: right;
  color: var(--button-abort-color);
  width: max-content;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  margin: 0;
  &:hover {
    text-decoration: underline;
  }
`;

const ConsultantList = styled.ul`
  list-style: none;

  background: ${props => (props.isempty ? " #F4F4F4" : "none")};
  display: ${props => (props.isempty ? "grid" : "block")};
  align-items: ${props => (props.isempty ? "center" : "start")};
  padding: ${props => (props.isempty ? "1rem" : "0")};
  margin-left: ${props => (props.isempty ? "40px" : "0")};
  border-radius: 1rem;
  min-height: 40vh;
  & p {
    text-align: ${props => (props.isempty ? "center" : "start")};
    font-size: 0.9rem;
    color: #3a3a3a;
  }
`;

const ConsultantButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: ${props => (props.isExistingMember ? "1.3rem" : "1rem")};
  display: grid;
  align-items: center;
  color: ${props => (props.isExistingMember ? "var(--color-grey)" : "#494949")};
  padding-bottom: ${props => (props.isExistingMember ? "0.6rem" : "0.3rem")};
  grid-template-columns: 30px 1fr;
  gap: 10px;
  &:hover {
    color: ${props =>
      props.isExistingMember
        ? "var(--button-abort-color)"
        : "var(--button-secondary-color)"};
    & span {
      text-decoration: unset;
      visibility: visible;
    }
  }

  &:focus {
    outline: none;
  }

  & span {
    text-decoration: unset;
    visibility: hidden;
  }
`;

const AddGroupButton = styled.button`
  font-weight: bold;
  padding: 0.6rem 1.2rem;
  display: flex;
  align-items: center;
  border-radius: 0.3rem;
  border: 1px solid gray;
  cursor: pointer;
  background: var(--button-background-color);
  color: var(--color-grey);
  width: max-content;
  &:hover {
    border-color: var(--button-secondary-color);
    & * {
      fill: var(--button-secondary-color);
    }
  }
  &:active {
    background: var(--button-secondary-color);
    color: white;
    & * {
      fill: var(--button-background-color);
    }
  }
  &:focus {
    outline-color: var(--button-secondary-color);
  }
`;

const ListContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
`;

const UlContainer = styled.div`
  max-height: 50vh;
  overflow-y: scroll;
`;

const ListTitle = styled.p`
  font-size: 1.1rem;
  color: var(--color-grey);
  font-weight: bold;
  transform: translateX(40px);
  margin-bottom: 2rem;
`;

const DeleteIconStyled = styled(DeleteIcon)`
  margin-right: 0.3rem;
  height: 1.1rem;
  width: 1.1rem;
  padding-top: 1px;
  & * {
    fill: var(--button-abort-color);
  }
`;

const AddIconStyled = styled(AddIcon)`
  margin-right: 1rem;
  height: 1.3rem;
  width: 1.3rem;
  & * {
    fill: var(--color-lightergrey);
  }
`;

const AddMemberIconStyled = styled(PlusIcon)`
  height: 0.7rem;
  width: 0.7rem;
  & * {
    fill: var(--button-secondary-color);
  }
`;

const RemoveMemberIconStyled = styled(RemoveIcon)`
  height: 0.8rem;
  width: 0.8rem;
  & * {
    fill: var(--button-abort-color);
  }
`;

const ArrowDown = styled(ArrowDownIcon)`
  margin-top: 2rem;
  height: 1.3rem;
  width: 1.3rem;
  align-self: left;
  transform: translateX(100px);
  opacity: ${props => (props.visible === "true" ? "1" : "0")};
  & * {
    fill: var(--color-grey);
  }
`;

const Groups = props => {
  const allFetchedConsultants = useRef([]);
  const [waitingForData, setWaitingForData] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [inputFieldValue, setInputFieldValue] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [notMemberList, setNotMemberList] = useState([]);

  const groupNameRef = useRef(null);
  const focusInputField = () => {
    groupNameRef.current.focus();
  };

  useEffect(() => {
    if (
      selectedGroup &&
      selectedGroup.name &&
      inputFieldValue !== selectedGroup.name
    ) {
      setInputFieldValue(selectedGroup.name);
    }
    filterConsultants(selectedGroup);
  }, [selectedGroup, selectedGroup.members, waitingForData, groups]);

  useEffect(() => {
    fetchGroupsAndConsultants();
  }, []);

  const fetchGroupsAndConsultants = async () => {
    setWaitingForData(true);
    try {
      const response = await fetch(`/groups`);
      const mapped = response.map((group, index) => {
        return { ...group, members: [] };
      });
      setGroups(mapped);
      fetchAllConsultants(mapped);
    } catch (err) {
      console.log("Request resulted in an error", err);
    }
  };

  const fetchAllConsultants = async groups => {
    try {
      const response = await fetch(`/consultants/`);
      allFetchedConsultants.current = response.sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });
      if (groups && groups.length > 0) {
        const mappedGroups = groups.map(group => ({
          ...group,
          members: response
            .filter(consultant =>
              consultant.groups.find(g => g.id === group.id)
            )
            .sort((a, b) => {
              return a.name < b.name ? -1 : 1;
            })
        }));
        setGroups(mappedGroups);
        if (!selectedGroup || !selectedGroup.name) {
          setSelectedGroup(mappedGroups[0]);
        }
      }
      setWaitingForData(false);
    } catch (err) {
      console.log("Request resulted in an error", err);
    }
  };

  const filterConsultants = () => {
    if (selectedGroup && selectedGroup.members) {
      const filteredConsultants = allFetchedConsultants.current
        .filter(
          consultant =>
            selectedGroup.members.findIndex(
              member => member.consultantId === consultant.consultantId
            ) < 0
        )
        .sort((a, b) => {
          return a.name < b.name ? -1 : 1;
        });
      setNotMemberList(filteredConsultants);
      setMemberList(
        selectedGroup.members.sort((a, b) => {
          return a.name < b.name ? -1 : 1;
        })
      );
    } else {
      setNotMemberList(allFetchedConsultants.current);
      setMemberList([]);
    }
  };

  const removeConsultantGroupConnection = consultantId => {
    if (!consultantId || !selectedGroup.id) {
      return;
    }
    const storedGroup = groups.find(group => group.id === selectedGroup.id);

    const newMembers = storedGroup.members
      .filter(consultant => consultant.consultantId !== consultantId)
      .sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });

    const updatedGroups = groups.map(group =>
      group.id === storedGroup.id ? { ...group, members: newMembers } : group
    );

    storedGroup.members = newMembers;
    setGroups(updatedGroups);
    setSelectedGroup(storedGroup);

    fetch(
      `/consultants/${consultantId}/group
    `,
      {
        method: "DELETE",
        body: JSON.stringify({
          groupId: selectedGroup.id
        })
      }
    );
  };

  const postConsultantGroupConnection = consultantId => {
    const storedGroup = groups.find(group => group.id === selectedGroup.id);

    const addedConsultant = allFetchedConsultants.current.find(
      consultant => consultant.consultantId === consultantId
    );

    storedGroup.members.push(addedConsultant);
    const newMembers = storedGroup.members.sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });
    const updatedGroups = groups.map(group =>
      group.id === storedGroup.id ? { ...group, members: newMembers } : group
    );

    setGroups(updatedGroups);
    setSelectedGroup(storedGroup);

    if (!consultantId || !selectedGroup.id) {
      return;
    }
    fetch(
      `/consultants/${consultantId}/group
    `,
      {
        method: "POST",
        body: JSON.stringify({
          groupId: selectedGroup.id
        })
      }
    );
  };

  const postGroupName = () => {
    if (!inputFieldValue || (inputFieldValue && inputFieldValue.length === 0)) {
      setInputFieldValue(
        selectedGroup && selectedGroup.name ? selectedGroup.name : ""
      );
      return;
    }
    if (selectedGroup.id) {
      updateGroupName();
    } else {
      addGroup();
    }
  };

  const updateGroupName = () => {
    fetch(`/groups/${selectedGroup.id}`, {
      method: "PUT",
      body: JSON.stringify({
        name: inputFieldValue
      })
    }).then(
      response => {
        const newGroup = {
          ...selectedGroup,
          name: inputFieldValue
        };
        setSelectedGroup(newGroup);
        const updatedGroups = groups.map(group => {
          return group.id === newGroup.id
            ? { ...group, name: inputFieldValue }
            : group;
        });
        setGroups(updatedGroups);
      },
      reason =>
        console.log("Something went wrong during updating group name", reason)
    );
  };

  const addGroup = () => {
    fetch(`/groups`, {
      method: "POST",
      body: JSON.stringify({
        name: inputFieldValue
      })
    }).then(
      response => {
        const newGroup = {
          id: response.generatedId,
          name: inputFieldValue,
          members: []
        };
        groups.push(newGroup);

        setSelectedGroup(newGroup);
        setGroups(groups);
      },
      reason =>
        console.log("Something went wrong during updating group name", reason)
    );
  };

  const deleteGroup = () => {
    if (
      window.confirm(
        "Er du sikker på at du vil slette gruppe (" + selectedGroup.name + ")"
      )
    ) {
      const groupId = selectedGroup.id;
      const newGroups = groups.filter(group => group.id !== groupId);
      setGroups(newGroups);
      const newGroup = newGroups[0] || {};
      setSelectedGroup(newGroup);

      fetch(`/groups/${groupId}`, {
        method: "DELETE"
      }).then(
        response => {},
        reason =>
          console.log("Something went wrong during updating group name", reason)
      );
    }
  };

  const handleGroupNameKeyDown = e => {
    if (e.key === "Enter") {
      postGroupName();
    }
  };

  return (
    <div className="module">
      <AppHeading
        departments={props.match.params.departments}
        offset={0}
        weeks={8}
        availableOnly={props.match.params.availableOnly}
        active={"groups"}
      />
      <Spinner show={waitingForData} />
      <GroupsContainer>
        <GroupsTitle>Grupper</GroupsTitle>
        <GroupListContainer>
          <GroupList>
            {groups.map((group, i) => (
              <li key={group.name + "" + i}>
                <GroupButton
                  onClick={() => setSelectedGroup(group)}
                  selected={selectedGroup.id === group.id}
                >
                  <span>{group.name}</span>
                </GroupButton>
              </li>
            ))}
          </GroupList>
          <AddGroupButton
            onClick={() => {
              setSelectedGroup({});
              setInputFieldValue("");
              focusInputField();
            }}
          >
            <AddIconStyled /> Ny gruppe
          </AddGroupButton>
        </GroupListContainer>

        <InputContainer>
          <span>Gruppenavn</span>
          <GroupInput
            ref={groupNameRef}
            value={inputFieldValue}
            onChange={event => setInputFieldValue(event.target.value)}
            onKeyDown={handleGroupNameKeyDown}
            onBlur={() => postGroupName()}
          />
        </InputContainer>
        <ButtonsContainer>
          <DeleteGroupButton onClick={() => deleteGroup()}>
            <DeleteIconStyled />
            Slett gruppe
          </DeleteGroupButton>
        </ButtonsContainer>
        {notMemberList.length > 0 || memberList.length > 0 ? (
          <GroupSectionContainer>
            <ListContainer>
              <ListTitle>
                {`${memberList && memberList.length} ${
                  (memberList && memberList.length === 1) ||
                  (selectedGroup && !selectedGroup.name)
                    ? "medlem"
                    : "medlemmer"
                } `}
              </ListTitle>
              <UlContainer>
                <ConsultantList isempty={memberList && memberList.length === 0}>
                  {memberList && memberList.length === 0 && (
                    <p>
                      Gruppen har ingen medlemmer enda. Legg medlemmer til fra
                      listen til høyre.
                    </p>
                  )}

                  {memberList &&
                    memberList.map((consultant, i) => (
                      <li key={consultant.id + "" + i}>
                        <ConsultantButton
                          isExistingMember={true}
                          onClick={() =>
                            removeConsultantGroupConnection(
                              consultant.consultantId
                            )
                          }
                        >
                          <span>
                            <RemoveMemberIconStyled />
                          </span>
                          {consultant.name}
                        </ConsultantButton>
                      </li>
                    ))}
                </ConsultantList>
              </UlContainer>
              <ArrowDown visible={(memberList.length > 12).toString()} />
            </ListContainer>
            <ListContainer>
              <ListTitle isRegularMember={true}>Legg til flere</ListTitle>
              <UlContainer>
                <ConsultantList isempty={notMemberList.length === 0}>
                  {notMemberList.length === 0 && (
                    <p>Alle ansatte er med i gruppen.</p>
                  )}
                  {notMemberList.map((consultant, i) => (
                    <li key={consultant.consultantId + "" + i}>
                      <ConsultantButton
                        onClick={() =>
                          postConsultantGroupConnection(consultant.consultantId)
                        }
                      >
                        <span>
                          <AddMemberIconStyled />
                        </span>
                        {consultant.name}
                      </ConsultantButton>
                    </li>
                  ))}
                </ConsultantList>
              </UlContainer>
              <ArrowDown
                visible={(notMemberList.length > 19).toString()}
                title="Scroll"
              />
            </ListContainer>
          </GroupSectionContainer>
        ) : (
          <></>
        )}
      </GroupsContainer>
    </div>
  );
};

export default Groups;
