import React from "react";
import fetch from "./shared/Fetch";

import Error from "./Error";

class RootRedirect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { departments: [], isError: false };
  }

  componentDidMount() {
    this.fetchDepartments();
  }

  render() {
    const { isError } = this.state;

    if (isError) {
      return <Error text="Fikk ikke hentet avdelinger"></Error>;
    }

    return <div className="module"></div>;
  }

  fetchDepartments() {
    fetch("/departments")
      .then(departments => {
        window.location.replace(
          `/${departments.map(d => d.id).join(",")}/all/consultants/8/0/false`
        );
      })
      .catch(err => {
        this.setState({ errorMessage: err.message, isError: true });
        console.error("Something went wrong while fetching departments", err);
      });
  }
}

export default RootRedirect;
