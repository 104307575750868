import React from "react";
import { NavLink } from "react-router-dom";
import OrgPicker from "./OrgPicker";
import fetch from "../shared/Fetch";

const logo = process.env.REACT_APP_LOGO_URL;
const showLogo = Boolean(logo);

class AppHeading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false
    };
  }

  async fetchCurrentUser() {
    const user = await fetch(`/user/current`);
    if (user.admin) this.setState({ isAdmin: true });
  }

  componentDidMount() {
    this.fetchCurrentUser();
  }

  render() {
    let departments = this.props.departments;
    let weeks = this.props.weeks;
    let offset = this.props.offset;
    let hideItems = this.props.hideItems;
    let groups = this.props.groups;
    let activePage = this.props.active;
    let availableOnly = this.props.availableOnly;
    groups = groups ? groups : "all";

    let withData = inner =>
      `/${departments}/${groups}/${inner}/${weeks}/${offset}/${availableOnly}`;
    return (
      <header className="hero">
        <h1 className="hero__title">
          <NavLink
            className={
              "hero__menuItem " + (activePage === "consultants" ? "active" : "")
            }
            to={withData("consultants")}
          >
            Bemanning
          </NavLink>
        </h1>
        {!hideItems ? (
          <nav className="hero__menu">
            <ul className="hero__menuItems">
              <li>
                <NavLink
                  className={
                    "hero__menuItem " +
                    (activePage === "customers" ? "active" : "")
                  }
                  to={withData("customers")}
                >
                  Kunder
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={
                    "hero__menuItem " +
                    (activePage === "report" || activePage === "where"
                      ? "active"
                      : "")
                  }
                  to={withData("report")}
                >
                  Rapporter
                </NavLink>
              </li>
              {this.state.isAdmin && (
                <li>
                  <NavLink
                    className={
                      "hero__menuItem " +
                      (activePage === "reconciliation" ? "active" : "")
                    }
                    to={withData("reconciliation")}
                  >
                    Avstemming
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  className={
                    "hero__menuItem " +
                    (activePage === "budget" ? "active" : "")
                  }
                  to={withData("budget")}
                >
                  Budsjett
                </NavLink>
              </li>
              {this.state.isAdmin && (
                <li>
                  <NavLink
                    className={
                      "hero__menuItem " +
                      (activePage === "administer" ? "active" : "")
                    }
                    to={withData("administer")}
                  >
                    Administrere
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  className={
                    "hero__menuItem " +
                    (activePage === "groups" ? "active" : "")
                  }
                  to={withData("groups")}
                >
                  Grupper
                </NavLink>
              </li>
            </ul>
          </nav>
        ) : null}

        <OrgPicker></OrgPicker>

        {showLogo ? <img src={logo} alt="logo" className="logo" /> : null}
      </header>
    );
  }
}

export default AppHeading;
