import { getApiUrl } from "./Settings";
import { removeToken, getToken } from "./Auth";
import refreshToken from "./RefreshToken";

let unauthedCallback = function() {};

let baseUrl;

export default async function request(uri, opt = {}) {
  async function doRequestInner() {
    const headers = new Headers();
    const token = opt.token ? opt.token : getToken();
    headers.set("Accept", "application/json");
    headers.set("Content-Type", "application/json");
    if (token) {
      headers.set("Authorization", "Bearer " + token);
    }

    const options = {
      headers,
      mode: "cors",
      ...opt
    };
    if (!baseUrl) {
      baseUrl = await getApiUrl();
    }
    const result = await fetch(baseUrl + uri, options);
    const data = await result.json();

    if (result.status === 401) {
      return expiredOrDenied(data, doRequestInner);
    }
    return data;
  }

  return doRequestInner();
}

function expiredOrDenied(data, retry) {
  removeToken();

  if (!data.expired) {
    return onFail();
  }

  return refreshToken(data.location).then(retry, onFail);

  function onFail() {
    unauthedCallback(data);
    return data;
  }
}

export function set401Action(fn) {
  unauthedCallback = fn;
}
