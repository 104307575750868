import React from "react";
import fetch from "./shared/Fetch";
import Table from "./sharedComponents/Table";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";
import {
  StaffingHoursTableCell,
  Button,
  SubmitButton,
  AddNewButton,
  ToggleInactiveButton
} from "./sharedComponents/Atoms";
import { ReactComponent as AddIcon } from "./svgs/AddIcon.svg";

const AddCustomer = styled.div`
  padding: 1.5rem;
  border: 1px solid gray;
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContainerHeader = styled.div`
  padding-top: 2rem;
  padding-bottom: 4rem;
  display: grid;
  grid-template-rows: 1fr max-content;
`;

const CustomerRow = styled.tr`
  &:nth-child(2n) {
    background: var(--table-band-background);
  }
  &:hover {
    background: var(--table-hover-background);
  }
`;

const CustomerName = styled.td`
  padding: 0.5rem;
  width: 15%;
`;

const ErrorMessage = styled.td`
  color: red;
  padding: 0.5rem;
`;

const CustomerMetaDataInput = styled.input`
  border: 0;
  padding: 0.5rem;
  outline: none;
  background: #eee;
  text-align: left;
  margin-left: 5px;

  @media (max-width: 750px) {
    width: 100%;
    font-size: 0.7rem;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
  > button {
    margin: 0.5rem;
  }
`;

const AddCustomerButton = styled(AddNewButton)`
  display: ${props => (props.hidden ? "none" : "flex")};
`;

const StyledAddIcon = styled(AddIcon)`
  margin-right: 0.5rem;
  height: 1.3rem;
  width: 1.3rem;
  & * {
    fill: var(--color-grey);
  }
`;

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      showInactiveCustomers: false,
      yearWeeks: [],
      departments: this.props.match.params.departments,
      newCustomer: {
        name: ""
      },
      showNewCustomerRow: false,
      waitingForData: true
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleNewCustomer = this.toggleNewCustomer.bind(this);
    this.saveNewCustomer = this.saveNewCustomer.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.offset !== prevProps.match.params.offset ||
      this.props.match.params.weeks !== prevProps.match.params.weeks
    ) {
      this.fetchData();
    }
  }

  handleInputChange(e) {
    const { newCustomer } = this.state;
    const property = e.target.name;
    newCustomer[property] = e.target.value;
    this.setState({ newCustomer: newCustomer });
  }

  toggleNewCustomer() {
    this.setState({
      showNewCustomerRow: !this.state.showNewCustomerRow,
      newCustomer: { name: "" }
    });
  }

  toggleActive(event) {
    this.setState({ showInactiveCustomers: event.target.checked });
  }

  render() {
    const {
      yearWeeks,
      customers,
      departments,
      newCustomer,
      showInactiveCustomers
    } = this.state;
    let filteredCustomers = customers;
    if (!showInactiveCustomers)
      filteredCustomers = customers.filter(customer =>
        customer.staffing.some(ws => ws.offeredHours > 0 || ws.orderedHours > 0)
      );

    return (
      <div className="module">
        <AppHeading
          departments={departments}
          offset={this.props.match.params.offset}
          weeks={this.props.match.params.weeks}
          availableOnly={this.props.match.params.availableOnly}
          active={"customers"}
        >
          {" "}
        </AppHeading>
        <form className="customers content">
          <ContainerHeader>
            <h3>Kunder</h3>
            <ToggleInactiveButton
              showInactive={this.state.showInactiveCustomers}
              toggle={this.toggleActive}
            />
          </ContainerHeader>

          <Spinner show={this.state.waitingForData} />
          <div style={{ marginTop: "3rem" }}>
            <Table
              leadingHeaders={[""]}
              yearWeeks={yearWeeks}
              params={this.props.match.params}
              inner={"customers"}
            >
              {filteredCustomers.map((customer, i) => (
                <CustomerRow key={i}>
                  <CustomerName>
                    <Link
                      to={
                        "/" +
                        departments +
                        "/" +
                        this.props.match.params.groups +
                        "/customer/" +
                        customer.customerId +
                        "/" +
                        this.props.match.params.weeks +
                        "/" +
                        this.props.match.params.offset +
                        "/" +
                        this.props.match.params.availableOnly
                      }
                    >
                      {customer.customerName}
                    </Link>
                  </CustomerName>
                  {customer.staffing.map((staffing, j) => (
                    <StaffingHoursTableCell
                      key={j}
                      ordered={staffing.orderedHours}
                      offered={staffing.offeredHours}
                      showoverbooked={true}
                    />
                  ))}
                </CustomerRow>
              ))}
            </Table>
          </div>
          {this.state.showNewCustomerRow ? (
            <AddCustomer>
              <table className="add-customer">
                <tbody>
                  <tr>
                    <td></td>
                    <ErrorMessage>{this.state.errorMessage}</ErrorMessage>
                  </tr>
                  <tr>
                    <td>Kundenavn:</td>
                    <td>
                      <CustomerMetaDataInput
                        name="name"
                        onChange={this.handleInputChange}
                        value={newCustomer.name}
                        autoFocus
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <ButtonContainer>
                <Button
                  style={{
                    display: this.state.showNewCustomerRow
                      ? "inline-block"
                      : "none"
                  }}
                  onClick={this.toggleNewCustomer}
                  type="button"
                >
                  Avbryt
                </Button>
                <SubmitButton
                  style={{
                    display: this.state.showNewCustomerRow
                      ? "inline-block"
                      : "none"
                  }}
                  onClick={this.saveNewCustomer}
                  type="submit"
                >
                  Lagre
                </SubmitButton>
              </ButtonContainer>
            </AddCustomer>
          ) : null}
          <AddCustomerButton
            hidden={this.state.showNewCustomerRow}
            onClick={this.toggleNewCustomer}
            type="button"
          >
            <StyledAddIcon />
            Ny kunde
          </AddCustomerButton>
        </form>
      </div>
    );
  }

  fetchData() {
    this.setState({ waitingForData: true });
    this.fetchCustomers();
  }

  fetchCustomers() {
    fetch(
      `/staffings/customers?offset=${this.props.match.params.offset}&weeks=${this.props.match.params.weeks}`
    )
      .then(response => {
        const yearWeeks =
          response.length > 0
            ? response[0].staffing.map(week => week.yearWeek)
            : [];

        this.setState({
          yearWeeks: yearWeeks,
          customers: response,
          waitingForData: false
        });
      })
      .catch(reason =>
        console.log("Someting went wrong while fetching customers", reason)
      );
  }

  saveNewCustomer(e) {
    e.preventDefault();

    const exists = this.state.customers.find(
      ({ customerName }) => customerName === this.state.newCustomer.name
    );
    if (exists) {
      this.setState({
        errorMessage: this.state.newCustomer.name + " finnes allerede."
      });
      return;
    }

    fetch("/customers/", {
      method: "POST",
      body: JSON.stringify({
        name: this.state.newCustomer.name
      })
    }).then(
      response => {
        this.setState({ showNewCustomerRow: false }, this.fetchCustomers);
      },
      reason => console.log("Something went wrong during the request", reason)
    );
  }
}

export default Customers;
