import React from "react";
import styled from "styled-components";
import TableHeading from "./TableHeading";
import { Link } from "react-router-dom";

const TableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: white;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  margin: auto;
  width: 100%;
`;

const OptionsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  margin: 1rem 2rem;
  align-items: flex-end;
  width: 100%;
  @media (max-width: 900px) {
    visibility: hidden;
  }
`;

const WeeksNav = styled.div`
  display: flex;
  flex-direction: row;
`;

const WeeksOptions = styled.div`
  margin-bottom: 0.5rem;
  cursor: pointer;
  @media (max-width: 900px) {
    visibility: hidden;
  }
`;

const WeekLink = styled(Link)`
  margin: 0.3rem;
`;

const Navigation = styled.div`
  font-size: 1.5rem;
  cursor: pointer;
  border-bottom: none;

  @media (max-width: 750px) {
    font-size: 1rem;
  }
`;

function getWeekPathPath(params, inner, weeks) {
  return `/${params.departments}/${params.groups}/${inner}/${weeks}/${params.offset}/${params.availableOnly}`;
}

function getOffsetPath(params, inner, direction) {
  let offset = 0;
  if (direction) {
    offset =
      parseInt(params.offset, 10) + Math.round((direction * params.weeks) / 2);
  }
  return `/${params.departments}/${params.groups}/${inner}/${params.weeks}/${offset}/${params.availableOnly}`;
}

export default function Table({
  leadingHeaders,
  yearWeeks,
  children,
  inner,
  hideitems,
  params
}) {
  return (
    <TableContainer>
      <OptionsContainer>
        <WeeksOptions>
          <WeekLink
            to={getWeekPathPath(params, inner, 8)}
            used={params.weeks === "8" ? 1 : 0}
            className={params.weeks === "8" ? "active" : ""}
          >
            8 uker
          </WeekLink>
          <WeekLink
            to={getWeekPathPath(params, inner, 12)}
            used={params.weeks === "12" ? 1 : 0}
            className={params.weeks === "12" ? "active" : ""}
          >
            12 uker
          </WeekLink>
          <WeekLink
            to={getWeekPathPath(params, inner, 26)}
            used={params.weeks === "26" ? 1 : 0}
            className={params.weeks === "26" ? "active" : ""}
          >
            26 uker
          </WeekLink>
        </WeeksOptions>
        <WeeksNav>
          <Navigation key="previous-links">
            <Link
              style={{ border: "none" }}
              to={getOffsetPath(params, inner, -1)}
            >
              <span>←</span>
            </Link>
          </Navigation>

          <WeekLink
            to={getOffsetPath(params, inner)}
            used={params.weeks === "0" ? 1 : 0}
            className={props => (props.used ? "active" : "")}
          >
            Nå
          </WeekLink>
          <Navigation key="next-links">
            <Link
              style={{ border: "none" }}
              to={getOffsetPath(params, inner, 1)}
            >
              <span>→</span>
            </Link>
          </Navigation>
        </WeeksNav>
      </OptionsContainer>

      <StyledTable>
        <TableHeading
          leadingHeaders={leadingHeaders}
          yearWeeks={yearWeeks}
          hideItems={hideitems}
        />
        <tbody>{children}</tbody>
      </StyledTable>
    </TableContainer>
  );
}
