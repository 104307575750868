import React from "react";
import moment from "moment";
import Week from "./Week";
import styled from "styled-components";
import { WeekContainer } from "./Atoms";

const MonthContainer = styled.div`
  margin: 2rem;
`;

const MonthName = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  margin-left: 0.8rem;
  text-align: center;
`;

const DayName = styled.div`
  font-weight: 600;
  width: 2.8rem;
  font-size: 0.8rem;
  line-height: 1.6;
  color: var(--table-hover-background);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  align-items: center;
`;

class Month extends React.Component {
  render() {
    let m = moment()
      .year(this.props.year)
      .month(this.props.number)
      .date(1);
    let monthname = m.format("MMMM Y");
    if (m.isoWeekday() > 5) m.add(7, "days");

    let weeks = [];
    for (let i = 0; i < 5; i++) {
      weeks.push(
        <Week
          key={i}
          year={m.year()}
          holidays={this.props.holidays}
          vacationDays={this.props.vacationDays}
          month={this.props.number}
          addVacation={this.props.addVacation}
          removeVacation={this.props.removeVacation}
          weekNumber={m.isoWeek()}
        />
      );
      m.add(7, "days");
    }

    return (
      <MonthContainer>
        <MonthName>{monthname}</MonthName>
        <WeekContainer>
          <DayName></DayName>
          <DayName></DayName>
          <DayName>M</DayName>
          <DayName>T</DayName>
          <DayName>O</DayName>
          <DayName>T</DayName>
          <DayName>F</DayName>
        </WeekContainer>
        {weeks}
      </MonthContainer>
    );
  }
}

export default Month;
