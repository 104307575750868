import React from "react";
import styled from "styled-components";

import fetch from "./shared/Fetch";
import { Button } from "./sharedComponents/Atoms";
import { getQueryToken } from "./shared/RefreshToken";

const Container = styled.div`
  margin: auto;
  min-width: 300px;
  width: 90%;
  max-width: 600px;
  min-height: 40%;
  padding: 2rem;
  border: solid 10px var(--neutral-color-accent);
`;

const ErrorMessage = styled.div`
  color: red;
`;

const LoginButton = styled(Button)`
  font-size: 2rem;
`;

function getToken(str) {
  return getQueryToken(str);
}

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      errorMessage: ""
    };
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    console.log("Received token: " + window.location.hash);
    if (window.location.hash) {
      const token = getToken(window.location.hash);
      this.verifyToken(token);
    }
  }

  verifyToken(token) {
    fetch("/login", { token }).then(response => {
      if (response.token) {
        this.props.onLoggedIn(response.token);
        return;
      }

      if (response.error && response.error.name === "EmailDoesNotExists") {
        document.location.hash = "";
        this.setState({
          errorMessage:
            "Bruker med epostadresse " +
            response.error.erroneousArgument +
            " er ikke registrert som konsulent."
        });
        return;
      }

      if (response.error) {
        this.setState({ errorMessage: response.error.message });
        return;
      }

      this.setState({ errorMessage: "Ukjent feil: " + response });
    });
  }

  onChange(field) {
    return e =>
      this.setState({
        [field]: e.currentTarget.value
      });
  }

  login(e) {
    e.preventDefault();
    fetch("/login", { mode: "cors" })
      .then(response => {
        if (response.location) {
          window.location.replace(response.location);
        } else if (response.token) {
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return (
      <Container>
        <form>
          <h1>Logg inn på Bemanning</h1>
          <p>
            Du må være logget inn for å få tilgang til denne tjenesten. Logg inn
            via SSO ved å klikke på knappen under
          </p>

          <ErrorMessage>{this.state.errorMessage}</ErrorMessage>

          <LoginButton type="submit" onClick={this.login}>
            Autoriser applikasjon
          </LoginButton>
        </form>
      </Container>
    );
  }
}
