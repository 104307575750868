import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as CheckIcon } from "../svgs/CheckIcon.svg";

const DropDownContainer = styled.div`
  width: 70%;
  position: absolute;
  display: grid;
  gap: 5rem;
  z-index: 2;
  background: #fcfcfc;
  border: 1px solid var(--color-grey);
`;

const ClosingButton = styled.div`
  z-index: 1;
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;

const ItemList = styled.div`
  padding: 0.5rem 0;
  display: grid;
  grid-auto-flow: row;
  gap: 0.1rem;
`;

const Item = styled.label`
  font-weight: ${props => (props.selected ? "bold" : "normal")};
  display: grid;
  align-items: center;
  grid-template-columns: 90% 10%;
  padding: 0.5rem 2rem;
  justify-content: space-between;
  cursor: pointer;
  color: var(--color-grey);

  &:hover {
    color: var(--filter-button-hovered-color);
    background: var(--group-hover-color);
  }

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & svg {
    margin-left: 1rem;
  }
`;

const CheckIconStyled = styled(CheckIcon)`
  width: 1rem;
  height: 1rem;
  & * {
    fill: var(--color-grey);
  }
`;

const StyledLink = styled(Link)`
  background: none;
  text-decoration: underline;
  border-top: 1px solid var(--color-lightergrey);
  padding: 1rem 0;
  color: var(--color-lightergrey);
  text-align: left;
  padding-left: 2rem;
  &:hover {
    color: var(--theme-link-color);

    & * {
      fill: var(--theme-link-color);
    }
  }
`;

const GroupsDropDown = ({
  groups,
  selectedGroups,
  onGroupSelect,
  onGroupUnselect,
  linkToNewGroup,
  onCloseDropdown
}) => {
  const isGroupSelected = groupId => {
    return selectedGroups && !!selectedGroups.find(g => g.id === groupId);
  };
  const handleClick = group => {
    if (isGroupSelected(group.id)) {
      onGroupUnselect(group.id);
    } else {
      onGroupSelect(group.id);
    }
  };
  return (
    <div>
      <ClosingButton onClick={() => onCloseDropdown()} />
      <DropDownContainer>
        <ItemList>
          {groups.map(group => (
            <Item
              onClick={() => handleClick(group)}
              selected={isGroupSelected(group.id)}
              key={group.id}
            >
              <span>{group.name}</span>
              {isGroupSelected(group.id) && <CheckIconStyled />}
            </Item>
          ))}
          <StyledLink to={linkToNewGroup ? linkToNewGroup : "/"}>
            Administrer grupper
          </StyledLink>
        </ItemList>
      </DropDownContainer>
    </div>
  );
};

export default GroupsDropDown;
