import React, { useEffect, useState } from "react";
import fetch from "./shared/Fetch";
import { format } from "./shared/NumberUtil";
import AppHeading from "./sharedComponents/AppHeading";
import Spinner from "./sharedComponents/Spinner";
import styled from "styled-components";
import FilteringBar from "./sharedComponents/FilteringBar";
import { SumData, WeekData } from "./Consultants";
import Table from "./sharedComponents/Table";
import { Link, NavLink } from "react-router-dom";
import FileUpload from "./sharedComponents/FileUpload";

const ReconContainer = styled.div`
  padding: 0 10rem 5rem;
`;

const FilteringBarContainer = styled.div`
  margin-bottom: -2rem;
`;

const ConsultantName = styled.td`
  padding: 0.5rem;
  width: 15%;

  @media (max-width: 900px) {
    width: 30%;
  }

  @media (max-width: 750px) {
    padding: 0;
    font-size: 0.8rem;
  }
`;

const ConsultantRow = styled.tr`
  &:nth-child(2n) {
    background: var(--table-band-background);
  }
  &:hover {
    background: var(--table-hover-background);
  }
`;

const SumRow = styled.tr`
  font-weight: bold;
`;

const Actuals = styled.div`
  color: ${props => (props.warning ? "red" : "DodgerBlue")};
`;

const Staffed = styled.div`
  color: black;
`;

const BarContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const submitActualStaffing = async rows => {
  const response = await fetch("/actualstaffings", {
    method: "POST",
    body: JSON.stringify({
      rows: rows
    })
  });
  return response;
};

const ReconCell = ({ week }) => {
  const actuals = week.actuals ? week.actuals : 0;
  const staffed = week.staffed ? week.staffed : 0;
  let warning = true;
  if (staffed === 0 || actuals / staffed > 0.9) warning = false;

  return (
    <WeekData
      title={"Bemannet: " + format(staffed) + " - Levert: " + format(actuals)}
    >
      <Staffed>{format(staffed)}</Staffed>
      <Actuals warning={warning}>{format(actuals)}</Actuals>
    </WeekData>
  );
};

const ReconLine = ({ cons }) => {
  const weekCells = [];
  for (const [key, value] of Object.entries(cons.weeks)) {
    weekCells.push(<ReconCell key={key} week={value} />);
  }

  const path = window.location.pathname;
  const pattern = /\/(.+?)\/(.+?)\/(.+?)\/(.+?)\/(.+?)\/(.+)/g;

  const params = [...path.matchAll(pattern)][0];
  const deps = params[1];
  const groups = params[2];
  const inner = "consultant";
  const weeks = params[4];
  const offset = params[5];
  const availableOnly = params[6];

  const to = `/${deps}/${groups}/${inner}/${cons.id}/${weeks}/${offset}/${availableOnly}`;
  return (
    <ConsultantRow>
      <ConsultantName>
        <Link to={to}>{cons.name}</Link>{" "}
      </ConsultantName>
      {weekCells}
    </ConsultantRow>
  );
};

const Container = styled.nav`
  padding: 2rem 0;
  margin-bottom: 3rem;
`;

export const PageHeading = ({
  departments,
  weeks,
  offset,
  groups,
  availableOnly
}) => {
  let withData = inner =>
    `/${departments}/${groups}/${inner}/${weeks}/${offset}/${availableOnly}`;

  return (
    <Container>
      <ul className="hero__subMenuItems">
        <li>
          <NavLink
            className={"hero__submenuItem"}
            to={withData("reconciliation")}
          >
            Avstemming
          </NavLink>
        </li>
        <li>
          <NavLink className={"hero__submenuItem"} to={withData("reconreport")}>
            Rapport
          </NavLink>
        </li>
      </ul>
    </Container>
  );
};

const Reconciliation = props => {
  const [data, setData] = useState([]);
  const [waitingForData, setWaitingForData] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();

  const weeks = props.match.params.weeks;
  const offset = props.match.params.offset;
  const departments = props.match.params.departments;
  const groups = props.match.params.groups;
  const availableOnly = props.match.params.availableOnly;

  const uploadExcelRows = async rows => {
    setWaitingForData(true);

    const res = await submitActualStaffing(rows);
    console.log(res);
    setLastUpdated(new Date());
    setWaitingForData(false);

    if (res.status === "ok") {
      setSuccessMessage(`Status: OK. ${res.count} ble importert.`);
    } else {
      if (res.type === "noMatch") {
        setError({
          updateErrors: res.errors,
          message: `Status: Delivs OK. ${res.count} ble importert.  Fant ikke match på følgende navn. Sjekk at de ligger registrert korrekt i systemet`
        });
      }
      if (res.type === "noCompleteWeek") {
        setError({
          updateErrors: [],
          message:
            "FEIL: Utvalget representer ikke én fullstendig uke. Ingen ble importert"
        });
      }
      if (res.type === "missformated") {
        setError({
          updateErrors: [],
          message: `FEIL: Importfilen er feilformatert. ${res.count} ble importert. `
        });
      }
    }
  };

  const fetchData = async () => {
    setWaitingForData(true);
    const response = await fetch(
      `/reconciliation/offset/${offset}/weeks/${weeks}`
    );
    await setData(Object.values(response));
    setWaitingForData(false);
  };

  useEffect(() => {
    fetchData();
  }, [weeks, offset, lastUpdated]);

  const departmentsArray = departments === "all" ? [] : departments.split(",");
  const groupsArray = groups === "all" ? [] : groups.split(",");
  const filteredData = data
    .filter(element => {
      return departmentsArray.some(op => {
        return op === element.departmentId;
      });
    })
    .filter(element => {
      if (groupsArray.length === 0) return true;
      return element.groups.some(g => groupsArray.some(a => a === g.id));
    })
    .sort((a, b) => {
      if (a.name > b.name) return 1;
      return -1;
    });

  const sums = [];
  for (const row of filteredData) {
    let i = 0;
    for (const w of Object.values(row.weeks)) {
      if (!sums[i]) {
        sums[i] = { actuals: 0, staffed: 0 };
      }
      sums[i].actuals += w.actuals ? parseFloat(w.actuals) : 0;
      sums[i].staffed += w.staffed ? parseFloat(w.staffed) : 0;
      i++;
    }
    //console.log(sums[6].staffed);
  }
  const consultantRow = filteredData.map(cons => {
    return <ReconLine key={cons.id} cons={cons} />;
  });

  const yearWeeks = [];
  if (data.length > 0) {
    for (const v of Object.keys(data[0].weeks)) {
      yearWeeks.push(v);
    }
  }

  return (
    <div className="module">
      <AppHeading
        departments={departments}
        offset={offset}
        weeks={weeks}
        groups={groups}
        availableOnly={availableOnly}
        active={"reconciliation"}
      />
      <ReconContainer>
        <PageHeading
          departments={departments}
          offset={offset}
          weeks={weeks}
          groups={groups}
          availableOnly={availableOnly}
        />
        <FileUpload
          handleRows={uploadExcelRows}
          error={error}
          successMessage={successMessage}
        >
          Last opp timeføring
        </FileUpload>
        <Spinner show={waitingForData} />
        <h3 className="page-heading">Avstemming</h3>
        <BarContainer>
          <FilteringBarContainer>
            <FilteringBar
              selectedDepartments={departmentsArray}
              selectedGroups={groupsArray}
            />
          </FilteringBarContainer>
        </BarContainer>

        <Table
          inner="reconciliation"
          params={{
            departments: departments,
            groups: groups,
            offset: offset,
            weeks: weeks,
            availableOnly: availableOnly
          }}
          yearWeeks={yearWeeks}
          leadingHeaders={["Antall: " + filteredData.length]}
        >
          {consultantRow}
          <SumRow className="line">
            <ConsultantName>Sum bemannet</ConsultantName>
            {sums.map((sum, i) => (
              <SumData key={i}>{format(sum.staffed)}</SumData>
            ))}
          </SumRow>
          <SumRow>
            <ConsultantName>Sum levert</ConsultantName>
            {sums.map((sum, i) => (
              <SumData key={i}>{format(sum.actuals)}</SumData>
            ))}
          </SumRow>
          <SumRow>
            <ConsultantName>Leveringgrad</ConsultantName>
            {sums.map((sum, i) => (
              <SumData key={i}>
                {format((sum.actuals / sum.staffed) * 100)}%
              </SumData>
            ))}
          </SumRow>
        </Table>
      </ReconContainer>
    </div>
  );
};

export default Reconciliation;
