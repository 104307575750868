import React, { useState } from "react";
import readXlsxFile from "read-excel-file";
import styled from "styled-components";

const UploadContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const UploadButton = styled.input`
  display: none;
`;

const FeedbackField = styled.div`
  border: solid 2px var(--graph-primary-color);
  padding: 0.3rem;
`;
const ErrorNameUL = styled.ul`
  margin-left: 2rem;
`;
const UploadLabel = styled.label`
  display: ${props => (props.selected ? "none" : "block")};
  background: var(--button-background-color);
  border-radius: 3px;
  color: var(--button-primary-color);
  padding: 0.5rem 0.7rem;
  border: 1px solid transparent;
  border-color: var(--filter-button-selected);
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 0.4rem;

  &:hover {
    border-color: var(--button-secondary-color);
    color: var(--button-primary-color);
    & * {
      fill: var(--button-secondary-color);
    }
  }
  &:active {
    background: var(--button-secondary-color);
    color: white;
    & * {
      fill: var(--button-background-color);
    }
  }
  &:focus {
    outline-color: var(--button-secondary-color);
  }
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseBtn = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: underline;
`;

const FileUpload = ({ children, handleRows, error, successMessage }) => {
  const [buttonPressed, setButtonPressed] = useState(false);
  const [localErrror, setLocalError] = useState();

  const onFileChange = async e => {
    console.log(handleRows);
    if (e.target.files) {
      try {
        const rows = await readXlsxFile(e.target.files[0]);
        handleRows(rows);
      } catch (e) {
        setLocalError(
          "Kan ikke lese excelfilen. Den virker å være feilformatert"
        );
      }
    }
  };

  const onCloseBtnClicked = () => {
    setButtonPressed(false);
  };

  const showFeedback = (error || successMessage) && buttonPressed;
  return (
    <UploadContainer>
      <UploadLabel
        selected={buttonPressed}
        onClick={() => setButtonPressed(true)}
      >
        <UploadButton type="file" onChange={onFileChange} />
        {children}
      </UploadLabel>
      {localErrror && (
        <FeedbackField>
          <p>{localErrror} </p>
        </FeedbackField>
      )}
      {showFeedback && (
        <FeedbackField>
          {error && (
            <>
              <p>{error.message} </p>
              <ErrorNameUL>
                {error.updateErrors.map((name, i) => (
                  <li key={i}>{name}</li>
                ))}
              </ErrorNameUL>
            </>
          )}
          {successMessage && <p>{successMessage}</p>}
          <CloseContainer>
            <CloseBtn onClick={onCloseBtnClicked}>Lukk</CloseBtn>
          </CloseContainer>
        </FeedbackField>
      )}
    </UploadContainer>
  );
};

export default FileUpload;
