import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Container = styled.nav`
  padding: 2rem 0;
  margin-bottom: 3rem;
`;

class ReportHeading extends React.Component {
  render() {
    let departments = this.props.departments;
    let weeks = this.props.weeks;
    let offset = this.props.offset;
    let groups = this.props.groups;
    let availableOnly = this.props.availableOnly;
    groups = groups ? groups : "all";

    let withData = inner =>
      `/${departments}/${groups}/${inner}/${weeks}/${offset}/${availableOnly}`;
    return (
      <Container>
        <ul className="hero__subMenuItems">
          <li>
            <NavLink className={"hero__submenuItem"} to={withData("report")}>
              Rapport
            </NavLink>
          </li>
          <li>
            <NavLink className={"hero__submenuItem"} to={withData("where")}>
              Hvor
            </NavLink>
          </li>
        </ul>
      </Container>
    );
  }
}

export default ReportHeading;
