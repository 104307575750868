import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import styled from "styled-components";
import fetch from "./shared/Fetch";
import Month from "./sharedComponents/Month";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";

import { Heading, SubmitButton } from "./sharedComponents/Atoms";

const CalForm = styled.form`
  clear: both;
  margin: auto;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Counts = styled.ul`
  margin-bottom: 2rem;
  padding: var(--smallPadding);
  list-style-type: none;
  font-size: 1rem;
`;

const CurrentYear = styled.span`
  margin: 0.3rem;
  font-size: 2rem;
  float: right;
`;

const YearPicker = styled.button`
  cursor: pointer;
  margin: 0.3rem;
  font-size: 2rem;
  border: none;
  float: right;
`;

const Years = styled.div`
  margin-top: 1rem;
  padding: var(--smallPadding);
`;

const VacationDaysToUse = styled.li`
  color: ${props => (props.warning ? "red" : "inherit")};
`;

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consultant: { consultantName: "" },
      counter: 0,
      enabled: false,
      departments: this.props.match.params.departments,
      groups: this.props.match.params.groups,
      holidays: [],
      spentVacationDays: [],
      yearlyVacationDays: 0,
      additionalVacationDays: 0,
      vacationDays: [],
      year: moment().year()
    };
    // This binding is necessary to make `this` work in the callback
    this.fetchConsultant = this.fetchConsultant.bind(this);
    this.fetchHolidays = this.fetchHolidays.bind(this);
    this.removeVacation = this.removeVacation.bind(this);
    this.addVacation = this.addVacation.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  removeVacation(vacations, numberOfVacationInWeek) {
    let vacationDays = this.state.vacationDays;
    for (let vacation of vacations) {
      const dt = moment(vacation, "DDMMYYYY");
      vacationDays = vacationDays.filter(vac => vac !== vacation);
      fetch("/vacation", {
        method: "DELETE",
        body: JSON.stringify({
          date: dt.format("DD-MM-YYYY"),
          consultantId: this.state.consultant.consultantId,
          staffingDays: numberOfVacationInWeek
        })
      });
    }
    var counter = this.state.counter - 1;
    var enabled = true;
    if (counter === 0) {
      enabled = false;
    }
    this.setState({
      vacationDays: vacationDays,
      enabled: enabled,
      counter: counter
    });
  }

  addVacation(vacations, numberOfVacationInWeek) {
    let vacationDays = this.state.vacationDays;
    for (let vacation of vacations) {
      if (
        this.state.holidays.includes(vacation) ||
        vacationDays.includes(vacation)
      )
        continue;

      const dt = moment(vacation, "DDMMYYYY");
      if (dt < moment()) continue;
      vacationDays.push(vacation);
      fetch("/vacation", {
        method: "POST",
        body: JSON.stringify({
          date: dt.format("DD-MM-YYYY"),
          consultantId: this.state.consultant.consultantId,
          staffingDays: numberOfVacationInWeek
        })
      });
    }
    var counter = this.state.counter + 1;
    var enabled = true;
    if (counter === 0) {
      enabled = false;
    }
    this.setState({
      vacationDays: vacationDays,
      enabled: enabled,
      counter: counter
    });
  }

  sumbitStaffing(consultantId, date) {}

  render() {
    const {
      consultant,
      departments,
      enabled,
      holidays,
      spentVacationDays,
      vacationDays,
      year,
      groups
    } = this.state;
    const id = this.state.consultant.consultantId;
    const offset = this.props.match.params.offset;
    const weeks = this.props.match.params.weeks;
    const availableOnly = this.props.match.params.availableOnly;
    const to = `/${departments}/${groups}/consultant/${id}/${weeks}/${offset}/${availableOnly}`;
    const to2 = `/${departments}/${groups}/holiday/${id}/${weeks}/${offset}/${availableOnly}`;

    let forThisYear = vac => vac.substring(4) === year.toString();
    let vacationDaysCurrentYear = vacationDays.filter(forThisYear);
    let spentVacationDaysCurrentYear = spentVacationDays.filter(forThisYear);

    let months = [];
    for (let i = 0; i < 12; i++) {
      months.push(
        <Month
          key={i}
          holidays={holidays}
          vacationDays={vacationDays}
          spentVacationDays={spentVacationDays}
          year={year}
          removeVacation={this.removeVacation}
          addVacation={this.addVacation}
          number={i}
        />
      );
    }
    const vacationDaysToUse =
      this.state.yearlyVacationDays +
      this.state.additionalVacationDays -
      vacationDaysCurrentYear.length;

    return (
      <div className="module">
        <AppHeading
          departments={departments}
          offset={offset}
          weeks={weeks}
          groups={groups}
          availableOnly={this.props.match.params.availableOnly}
          active={"consultants"}
        />
        <div className="consultant content">
          <Content>
            <Heading>
              <h3>
                Ferieoversikt - <Link to={to}>{consultant.consultantName}</Link>
              </h3>
            </Heading>
            <Years>
              <YearPicker
                type="button"
                onClick={() =>
                  this.setState({ year: this.state.year * 1.0 + 1 })
                }
              >
                →
              </YearPicker>
              <CurrentYear>{year}</CurrentYear>
              <YearPicker
                type="button"
                onClick={() =>
                  this.setState({ year: this.state.year * 1.0 - 1 })
                }
              >
                ←
              </YearPicker>
            </Years>
          </Content>
          <Counts>
            <li>Årlig antall feriedager: {this.state.yearlyVacationDays}</li>
            <li>
              Antall overført fra i fjor: {this.state.additionalVacationDays}
            </li>
            <li>
              Planlagte feriedager:{" "}
              {vacationDaysCurrentYear.length -
                spentVacationDaysCurrentYear.length}
            </li>
            <li>Brukte feriedager: {spentVacationDaysCurrentYear.length}</li>
            <VacationDaysToUse warning={vacationDaysToUse < 0}>
              Gjenstående dager å planlegge: {vacationDaysToUse}
            </VacationDaysToUse>
          </Counts>
        </div>
        <CalForm className="consultant__container">
          <Spinner show={this.state.waitingForData} />
          {months}
        </CalForm>
        <Link to={to2}>
          <SubmitButton disabled={!enabled} className="next button">
            Neste →
          </SubmitButton>
        </Link>
      </div>
    );
  }

  async fetchData() {
    this.setState({ waitingForData: true });
    try {
      await Promise.all([this.fetchHolidays(), this.fetchConsultant()]);
    } finally {
      this.setState({ waitingForData: false });
    }
  }

  async fetchHolidays() {
    try {
      const response = await fetch(`/holidays/${this.state.year}`);
      const holidays = response.map(day => moment(day.date).format("DDMMYYYY"));
      this.setState({ holidays: holidays });
    } catch (err) {
      console.log("Request resulted in an error", err);
    }
  }

  async fetchConsultant() {
    const id = this.props.match.params.id;
    try {
      const response = await fetch(
        `/vacation/consultants/${id}/${this.state.year}`
      );
      let vacationDays = [];
      let spentVacationDays = [];
      if (response.vacations) {
        for (let vac of response.vacations) {
          if (vac) {
            let m = moment(vac, "DD-MM-YYYY");
            if (m.diff(moment()) <= 0) {
              spentVacationDays.push(
                moment(vac, "DD-MM-YYYY").format("DDMMYYYY")
              );
            }
            vacationDays.push(moment(vac, "DD-MM-YYYY").format("DDMMYYYY"));
          }
        }
      }

      this.setState({
        consultant: response,
        vacationDays: vacationDays,
        spentVacationDays: spentVacationDays,
        yearlyVacationDays: response.yearlyVacationDays,
        additionalVacationDays: response.additionalVacationDays,
        waitingForData: false
      });
    } catch (err) {
      console.log("Request resulted in an error", err);
    }
  }
}

export default Calendar;
