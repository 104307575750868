import React from "react";
import styled from "styled-components";

const List = styled.ul`
  list-style-type: none;
`;

const Pill = styled.span`
  display: inline-block;
  border-radius: 50%;
  padding: 0.5rem;
`;

const AvailablePill = styled(Pill)`
  background-color: var(--table-available);
`;

const OfferedColorPill = styled(Pill)`
  background-color: var(--table-offered);
`;

const OfferedPill = styled(Pill)`
  color: var(--non-prioritizedt-text-color);
  padding: 0.2rem;
`;

const OverbookingPill = styled(Pill)`
  background-color: var(--table-overbooked);
`;

const HolidayPill = styled.div`
  border-bottom: 11px solid var(--table-vacation);
  border-left: 11px solid transparent;
  height: 0;
  display: inline-block;
  padding-left: 0.15rem;
  width: 0;
`;

export default function() {
  return (
    <List className="table-helpbox-list">
      <li>
        <OfferedColorPill /> Tilbud
      </li>
      <li>
        <AvailablePill /> Ledig tid
      </li>
      <li>
        <OverbookingPill /> Overbooking
      </li>
      <li>
        <HolidayPill /> Ferie
      </li>
      <li style={{ marginTop: "0" }}>
        <OfferedPill>n</OfferedPill> Tentativ tid
      </li>
      <li className="holiday" style={{ marginTop: "0" }}>
        Helligdag
      </li>
    </List>
  );
}
