import React from "react";
import styled from "styled-components";
import {
  splitYearWeek,
  getFirstDayOfWeek,
  getCurrentWeek
} from "../shared/TimeUtil.js";

const LeadingHeading = styled.th`
  text-align: left;
  padding: 0.3rem;

  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

const FirstDate = styled.span`
  font-size: 0.8rem;
  font-weight: 200;
  margin: 0 1px;
  color: var(--color-lightgrey);
  @media (max-width: 900px) {
    display: none;
  }
`;

const ConsultantDataHeading = styled.th`
  > span:first-child {
    font-weight: 400;
  }
  text-align: right;
  @media (max-width: 750px) {
    font-size: 0.5rem;
  }
`;

const WeekAndDate = styled.div`
  text-align: center;
  display: inline-block;
`;

export default function TableHeading({ leadingHeaders, yearWeeks }) {
  let tds = [];
  const currentWeek = getCurrentWeek();

  leadingHeaders.forEach(function(leadingHeader, index) {
    tds.push(
      <LeadingHeading className="fixedHeading" key={index * -1 - 6}>
        {leadingHeader}
      </LeadingHeading>
    );
  });
  yearWeeks.map((yearWeek, i) => {
    const yearAndWeek = splitYearWeek(yearWeek);
    return tds.push(
      <ConsultantDataHeading key={i} className="fixedHeading">
        <WeekAndDate>
          <span
            className={currentWeek === yearAndWeek.week ? "currentWeek" : null}
          >
            {yearAndWeek.week}
          </span>
          <br />
          <FirstDate>{getFirstDayOfWeek(yearWeek)}</FirstDate>{" "}
        </WeekAndDate>
      </ConsultantDataHeading>
    );
  });

  return (
    <thead>
      <tr className="fixedHeading">{tds}</tr>
    </thead>
  );
}
