import React from "react";
import { BrowserRouter as HashRouter, Route, Redirect } from "react-router-dom";
import Consultant from "./Consultant";
import Consultants from "./Consultants";
import Customer from "./Customer";
import Customers from "./Customers";
import Holiday from "./Holiday";
import Popup from "./sharedComponents/Popup";
import RootRedirect from "./RootRedirect";
import Project from "./Project";
import Budget from "./Budget";
import Report from "./Report";
import Reconciliation from "./Reconciliation";
import Where from "./Where";
import Spinner from "./sharedComponents/Spinner";
import Administer from "./Administer";
import { setToken } from "./shared/Auth";
import fetch from "./shared/Fetch";
import { set401Action } from "./shared/Fetch";
import Login from "./Login";
import Error from "./Error";
import Calendar from "./Calendar";
import Groups from "./Groups";
import ReconciliationReport from "./ReconciliationReport";

// import "./Theme.Variant.css";

const theme = process.env.REACT_APP_THEME === "variant" ? "Variant" : "Pwc";
import(`./Theme.${theme}.css`);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <Error error={this.state.error} />;
    }

    return this.props.children;
  }
}

function isTokenRequest() {
  return document.location.href.includes("_token");
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: true,
      isLoading: true
    };

    this.onLoggedIn = this.onLoggedIn.bind(this);
  }

  componentDidMount() {
    set401Action(() =>
      this.setState({
        isLoggedIn: false,
        isLoading: false
      })
    );
    this.checkLoggedIn();
  }

  onLoggedIn(token) {
    setToken(token);
    document.location.hash = "";
    this.setState({
      shouldRedirect: true,
      isLoading: false
    });
  }

  checkLoggedIn() {
    // If not callback respons from auth server, check if we have
    // valid token. If not, set 401Action is triggered and isLoggedIn will be false
    if (!isTokenRequest()) {
      fetch("/ping").then(response => {
        // Check if is part of oauth flow. If it has location (redirect), don't set as logged in
        if (!response.location) {
          this.setState({ isLoggedIn: true, isLoading: false });
        }
      });
    }
  }

  render() {
    const { isLoggedIn: authed, isLoading } = this.state;
    if (isLoading && !isTokenRequest()) {
      return <Spinner show={true} />;
    }

    let wrap = i => `/:departments/:groups/${i}/:weeks/:offset/:availableOnly`;

    return (
      <HashRouter>
        <div className="App">
          <ErrorBoundary>
            <main className="main">
              <PrivateRoute
                authed={authed}
                exact
                path="/"
                component={RootRedirect}
              />
              <LoginRoute
                authed={authed}
                path="/login"
                onLoggedIn={this.onLoggedIn}
              />
              <PrivateRoute
                authed={authed}
                path={wrap("consultants")}
                component={Consultants}
              />
              <PrivateRoute
                authed={authed}
                path={wrap("consultant/:id")}
                component={Consultant}
              />
              <PrivateRoute
                authed={authed}
                path={wrap("calendar/:id")}
                component={Calendar}
              />
              <PrivateRoute
                authed={authed}
                path={wrap("holiday/:id")}
                component={Holiday}
              />
              <PrivateRoute
                authed={authed}
                path={wrap("popup/:id")}
                component={Popup}
              />
              <PrivateRoute
                authed={authed}
                path={wrap("customer/:id")}
                component={Customer}
              />
              <PrivateRoute
                authed={authed}
                path={wrap("customers")}
                component={Customers}
              />
              <PrivateRoute
                authed={authed}
                path={wrap("where")}
                component={Where}
              />
              <PrivateRoute
                authed={authed}
                path={wrap("report")}
                component={Report}
              />
              <PrivateRoute
                authed={authed}
                path={wrap("reconreport")}
                component={ReconciliationReport}
              />
              <PrivateRoute
                authed={authed}
                path={wrap("reconciliation")}
                component={Reconciliation}
              />
              <PrivateRoute
                authed={authed}
                path={wrap("project/:id")}
                component={Project}
              />
              <PrivateRoute
                authed={authed}
                path={wrap("budget")}
                component={Budget}
              />
              <PrivateRoute
                authed={authed}
                path={wrap("administer")}
                component={Administer}
              />
              <PrivateRoute
                authed={authed}
                path={wrap("groups")}
                component={Groups}
              />
            </main>
          </ErrorBoundary>
        </div>
      </HashRouter>
    );
  }
}

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        return authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }}
    />
  );
}

function LoginRoute({ authed, ...rest }) {
  const showLogin = !authed || isTokenRequest();
  return (
    <Route
      {...rest}
      render={props => {
        return showLogin ? (
          <Login {...rest} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      }}
    />
  );
}

export default App;
