import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { format, formatNoFraction } from "./shared/NumberUtil";
import fetch from "./shared/Fetch";
import Table from "./sharedComponents/Table";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";
import ColorCoding from "./sharedComponents/TableColorCodingHelp";
import { OfferData } from "./sharedComponents/Atoms";
import FilteringBar from "./sharedComponents/FilteringBar";
import { consultantUrl } from "./shared/Url";
import { getFirstDateOfWeek } from "./shared/TimeUtil";
import { ReactComponent as csvIcon } from "./svgs/CsvIcon.svg";
import { CSVLink } from "react-csv";

export const WeekData = styled.td`
  padding: 0.2rem;
  white-space: nowrap;
  text-align: right;
  visibility: ${props => (props.quited ? "hidden" : "visible")};
  background-color: ${props =>
    props.available ? "var(--table-available)" : ""};
  background-color: ${props =>
    props.overBooked ? "var(--table-overbooked)" : ""};

  --accent: ${props =>
    props.vacation ? "var(--table-vacation)" : "transparent"};

  color: ${props => (props.overBooked ? "var(--color-white)" : "")};
  color: ${props => (props.holidays ? "var(--table-holiday)" : "")};

  &:after {
    border-bottom: 15px solid var(--accent);
    border-left: 15px solid transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 0;
    right: 0;
    width: 0;
    position: absolute;
  }

  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

export const SumData = styled.td`
  white-space: nowrap;
  text-align: right;
  position: relative;

  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;
const StyledCheckbox = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.2rem;
  background-color: var(--table-hover-background);
  margin-right: 0.5rem;
  display: inline-block;
  transition: all 150ms;
`;

const Icon = styled.svg`
  visibility: hidden;
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const CSVIconStyled = styled(csvIcon)`
  margin-right: 0.3rem;
  margin-top: 1.3rem;
  height: 3rem;
  width: 3rem;
  padding-top: 1px;
  & * {
    fill: var(--button-abort-color);
  }
`;

const AvailableCheckbox = styled.label`
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: max-content;

  > input {
    visibility: hidden;
    height: 1px;
    width: 1px;
    position: absolute;
  }

  > input:checked + div {
    background: var(--theme-active-indicator);

    > svg {
      visibility: visible;
    }
  }

  > input:checked ~ span {
    font-weight: 500;
  }
`;
const ContainerHeader = styled.div`
  padding-top: 2rem;
  padding-bottom: 4rem;
  display: grid;
  grid-template-rows: 1fr max-content;
`;
const AvailableOnly = styled.input``;

const CheckboxLabel = styled.span`
  font-weight: 100;
  @media (max-width: 750px) {
    display: none;
  }
`;

const ConsultantName = styled.td`
  padding: 0.5rem;
  width: 15%;

  @media (max-width: 900px) {
    width: 30%;
  }

  @media (max-width: 750px) {
    padding: 0;
    font-size: 0.8rem;
  }
`;

const StyledConsultantRow = styled.tr`
  &:nth-child(2n) {
    background: var(--table-band-background);
  }
  &:hover {
    background: var(--table-hover-background);
  }
`;

const SumRow = styled.tr`
  font-weight: bold;
`;

const TableContainer = styled.div``;

const FilteringBarContainer = styled.div`
  margin-bottom: -2rem;
`;

function ToggleAvailityButton({ hideFullyBooked, toggle }) {
  const text = "Vis kun konsulenter med ledig tid";
  return (
    <AvailableCheckbox htmlFor="showOnlyAvailableConsultants">
      <AvailableOnly
        name="showOnlyAvailableConsultants"
        id="showOnlyAvailableConsultants"
        checked={hideFullyBooked}
        onChange={toggle}
        type="checkbox"
      />
      <StyledCheckbox>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      <CheckboxLabel>{text}</CheckboxLabel>
    </AvailableCheckbox>
  );
}

class Consultants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consultants: [],
      billRates: [],
      departments: this.props.match.params.departments.split(","),
      hideFullyBooked: this.props.match.params.availableOnly === "true",
      offeredWeekSum: [],
      orderedWeekSum: [],
      budgetWeekSum: [],
      waitingForData: true,
      yearWeeks: [],
      groups: this.props.match.params.groups.split(","),
      filterString: ""
    };
    this.toggleFullyBooked = this.toggleFullyBooked.bind(this);
  }
  componentDidMount() {
    this.fetchData();
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.offset !== prevProps.match.params.offset ||
      this.props.match.params.weeks !== prevProps.match.params.weeks ||
      this.props.match.params.departments !==
        prevProps.match.params.departments ||
      this.props.match.params.groups !== prevProps.match.params.groups
    ) {
      this.fetchData();
    }
  }

  toggleFullyBooked(event) {
    const params = this.props.match.params;
    this.props.history.push(
      `/${params.departments}/${params.groups}/consultants/${params.weeks}/${params.offset}/${event.target.checked}`
    );
    this.setState({ hideFullyBooked: event.target.checked }, this.fetchData);
  }

  handleKeyDown = event => {
    if (event.keyCode === 27) this.setState({ filterString: "" });
    if (event.keyCode === 8)
      this.setState({ filterString: this.state.filterString.slice(0, -1) });
    else if (event.key.length > 1) {
    } else this.setState({ filterString: this.state.filterString + event.key });
  };

  render() {
    const {
      consultants,
      billRates,
      orderedWeekSum,
      offeredWeekSum,
      budgetWeekSum,
      yearWeeks,
      filterString
    } = this.state;
    console.log(filterString);

    const filteredConsultants = consultants.filter(consultant =>
      consultant.name.toUpperCase().match(filterString.toUpperCase())
    );
    const {
      offset = 0,
      weeks = 8,
      groups,
      departments
    } = this.props.match.params;

    const groupsArray = groups === "all" ? [] : groups.split(",");
    const departmentsArray =
      departments === "all" ? [] : departments.split(",");

    let filter = "";
    if (filterString.length > 0) {
      filter = " (" + filterString + ")";
    }

    const csvData = [];
    const csvHeader = [];
    csvHeader.push({ label: "Konsulent", key: "name" });
    csvHeader.push({ label: "Avdeling", key: "department" });
    if (filteredConsultants.length > 0) {
      for (const s of filteredConsultants[0].staffing) {
        csvHeader.push({
          label: s.yearWeek,
          key: s.yearWeek.toString()
        });
      }
    }
    for (const d of filteredConsultants) {
      const row = {};
      row.name = d.name;
      row.department = d.departmentId;
      for (const st of d.staffing) {
        row[st.yearWeek] = st.orderedHours;
      }
      csvData.push(row);
    }

    return (
      <div className="module">
        <AppHeading
          departments={departmentsArray}
          offset={offset}
          weeks={weeks}
          groups={groups}
          availableOnly={this.state.hideFullyBooked}
          active={"consultants"}
        />
        <div className="content consultants">
          <div className="consultants__container">
            <Spinner show={this.state.waitingForData} />
            <ContainerHeader>
              <h3>Konsulenter</h3>
              <ToggleAvailityButton
                hideFullyBooked={this.state.hideFullyBooked}
                toggle={this.toggleFullyBooked}
              />
            </ContainerHeader>
            <FilteringBarContainer>
              <FilteringBar
                selectedDepartments={departmentsArray}
                selectedGroups={groupsArray}
              />
            </FilteringBarContainer>
            <CSVLink
              filename={"Konsulenter.csv"}
              data={csvData}
              headers={csvHeader}
              separator={";"}
            >
              <CSVIconStyled title="Last ned CSV-fil" />
            </CSVLink>

            <TableContainer>
              <Table
                leadingHeaders={[
                  "Antall: " + filteredConsultants.length + filter
                ]}
                yearWeeks={yearWeeks}
                params={this.props.match.params}
                inner="consultants"
              >
                {filteredConsultants.map((consultant, i) => (
                  <ConsultantRow
                    key={i}
                    consultant={consultant}
                    params={this.props.match.params}
                  />
                ))}
                <SumRow className="line">
                  <ConsultantName>Sum bemanning</ConsultantName>

                  {orderedWeekSum.map((sum, i) => (
                    <SumData key={i}>{format(sum)}</SumData>
                  ))}
                </SumRow>
                <SumRow>
                  <ConsultantName>Sum tilbud og bemanning</ConsultantName>
                  {offeredWeekSum.map((sum, i) => (
                    <SumData key={i}>{format(sum)}</SumData>
                  ))}
                </SumRow>
                <SumRow>
                  <ConsultantName>Budsjett</ConsultantName>
                  {budgetWeekSum.map((budget, i) => (
                    <SumData key={i}>{format(budget)}</SumData>
                  ))}
                </SumRow>
                <SumRow>
                  <ConsultantName>Faktureringsgrad</ConsultantName>
                  {billRates.map((rate, i) => (
                    <SumData key={i}>
                      {formatNoFraction(rate.billableRate * 100)} %
                    </SumData>
                  ))}
                </SumRow>
              </Table>
            </TableContainer>
          </div>
          <ColorCoding />
        </div>
      </div>
    );
  }

  fetchDepartments() {
    this.setState({ waitingForData: true });
    fetch("/departments")
      .then(departments => {
        const departmentsIds = departments.map(d => d.id);
        this.setState({ departments: departmentsIds, isError: false });
        this.fetchData();
      })
      .catch(err => {
        this.setState({ errorMessage: err.message, isError: true });
        console.error("Something went wrong while fetching departments", err);
      });
  }

  fetchData() {
    const { hideFullyBooked } = this.state;
    let offset = this.props.match.params.offset;
    if (!offset) offset = 0;

    let weeks = this.props.match.params.weeks;
    if (!weeks) weeks = 8;

    let departmentsString = this.props.match.params.departments;
    let groupsString = this.props.match.params.groups;

    fetch(
      `/consultants/offset/${offset}/weeks/${weeks}?departments=${departmentsString}&groups=${
        groupsString === "all" ? "" : groupsString
      }` + (hideFullyBooked ? "&hideFullyBooked=1" : "")
    )
      .then(response => {
        const yearWeeks =
          response.consultants.length > 0
            ? response.consultants[0].staffing.map(week => week.yearWeek)
            : [];

        const offeredWeekSum = getWeekSum(
          response.consultants,
          ["offeredHours", "orderedHours"],
          this.props.match.params.weeks
        );
        const orderedWeekSum = getWeekSum(
          response.consultants,
          ["orderedHours"],
          this.props.match.params.weeks
        );
        this.setState({
          consultants: response.consultants,
          billRates: response.billRates,
          yearWeeks: yearWeeks,
          offeredWeekSum: offeredWeekSum,
          orderedWeekSum: orderedWeekSum,
          budgetWeekSum: response.budget,
          waitingForData: false
        });
      })
      .catch(reason => {
        console.log("Reqeust resulted in an error", reason);
      });
  }
}

function getWeekSum(consultants, properties, weeks) {
  return consultants
    .map(consultant => consultant.staffing)
    .reduce(
      (result, currentValue) => {
        for (var i = 0; currentValue.length > i; i++) {
          for (var j = 0; j < properties.length; j++) {
            if (isNaN(result[i])) result[i] = 0;
            result[i] += parseFloat(currentValue[i][properties[j]], 10);
          }
        }
        return result;
      },
      [0]
    );
}

function ConsultantRow({ consultant, params }) {
  const id = consultant.consultantId;
  const weeks = params.weeks;
  const to = consultantUrl(params, consultant.consultantId);

  return (
    <StyledConsultantRow key={id}>
      <ConsultantName key={id}>
        <Link to={to}>{consultant.name}</Link>{" "}
      </ConsultantName>
      {consultant.staffing.map((week, i) => {
        if (week.info) {
          return (
            <WeekData
              key={i}
              available={week.bookedHours < parseFloat(week.fullWeekHours, 0)}
              overBooked={week.overbooked}
              holidays={week.fullWeekHours < consultant.fullWeekHours}
              vacation={week.vacation}
              quited={
                consultant.endDate &&
                new Date(consultant.endDate) < getFirstDateOfWeek(week.yearWeek)
              }
              className="tooltip"
            >
              <OfferData
                hidden={weeks > 12 || week.offeredHours === 0}
                overBooked={week.overbooked}
              >
                {format(week.offeredHours)}
              </OfferData>
              <br />
              {format(week.orderedHours)}

              <div key={i + "container"} className="tooltip-container">
                <div key={i + "grid"} className="tooltip-grid">
                  {week.info.map((info, index) => {
                    if (info.hours > 0) {
                      return (
                        <div
                          key={index}
                          className="tooltip-row"
                          style={{
                            order:
                              info.status.toLowerCase() === "tilbud"
                                ? "3"
                                : info.engagementName.toLowerCase() === "ferie"
                                ? "2"
                                : ""
                          }}
                        >
                          <div className={"tooltip-hours"}>{info.hours}</div>
                          <div
                            className={"tooltip-name"}
                            style={{
                              borderColor:
                                info.status.toLowerCase() === "tilbud"
                                  ? "var(--table-offered)"
                                  : info.engagementName.toLowerCase() ===
                                    "ferie"
                                  ? "var(--table-vacation)"
                                  : ""
                            }}
                          >
                            {info.status.toLowerCase() === "internt"
                              ? info.engagementName
                              : info.customerName}
                          </div>
                        </div>
                      );
                    } else return null;
                  })}
                </div>
              </div>
            </WeekData>
          );
        }
        return (
          <WeekData
            key={i}
            available={week.bookedHours < parseFloat(week.fullWeekHours, 0)}
            overBooked={week.overbooked}
            holidays={week.fullWeekHours < consultant.fullWeekHours}
            vacation={week.vacation}
            quited={
              consultant.endDate &&
              new Date(consultant.endDate) < getFirstDateOfWeek(week.yearWeek)
            }
          >
            <OfferData
              hidden={weeks > 12 || week.offeredHours === 0}
              overBooked={week.overbooked}
            >
              {format(week.offeredHours)}
            </OfferData>
            <br />
            {format(week.orderedHours)}
          </WeekData>
        );
      })}
    </StyledConsultantRow>
  );
}
export default Consultants;
