import React, { useEffect, useState } from "react";
import styled from "styled-components";
import fetch from "../shared/Fetch";

const SelectOrg = styled.div`
  position: absolute;
  right: 15rem;
  padding-top: 0.7rem;
  font-size: 1.3rem;
`;

const SelectButton = styled.div`
  cursor: pointer;
  display: ${props => (props.open ? "block" : "none")};
`;

const Picker = styled.ul`
  display: ${props => (props.open ? "block" : "none")};
`;

const OrgItem = styled.li`
  cursor: pointer;
  list-style-type: none;
  paddin: 0;
  margin: 0;
`;

const OrgPicker = () => {
  const [open, setOpen] = useState(false);
  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
    const fetchOrgs = async () => {
      const orgs = await fetch("/organizations");
      setOrgs(orgs);
    };
    fetchOrgs();
  }, []);

  const onClick = () => {
    setOpen(!open);
  };

  const setOrg = org => {
    const updateOrg = async () => {
      await fetch("/organizations/", {
        method: "POST",
        body: JSON.stringify({
          organization: org
        })
      });

      window.location.href = window.location.origin;
    };
    updateOrg();
  };

  if (orgs.length === 0) return null;

  const orgLIs = orgs.map(org => {
    return (
      <OrgItem key={org.organization} onClick={() => setOrg(org.organization)}>
        {org.organizationName}
      </OrgItem>
    );
  });

  const selOrg = orgs.find(org => org.selected);
  const selectedOrg = selOrg
    ? orgs.find(org => org.selected).organizationName
    : "Velg org";

  return (
    <SelectOrg>
      <SelectButton open={!open} onClick={onClick}>
        {selectedOrg}
      </SelectButton>
      <Picker open={open}>{orgLIs}</Picker>
    </SelectOrg>
  );
};
export default OrgPicker;
