import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { createSelectable } from "react-selectable";
import fetch from "../shared/Fetch";
import imgUrl from "../trash.svg";
import { getFirstDateOfWeek } from "../shared/TimeUtil";

const StyledStaffingRow = styled.tr`
  > td input {
    text-align: right;
    height: 1.5rem;
  }
  &:nth-child(2n) {
    background: var(--table-band-background);
  }
  &:hover {
    background: var(--table-hover-background);
  }
`;

const VacationRow = styled.td`
  padding-right: 0.9rem;
  font-size: 0.8rem;
  text-align: right;
  > label {
  }
`;

const LeadingValue = styled.td`
  padding: 0.3rem;
  width: 5%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;

  @media (max-width: 900px) {
    width: 20%;
  }

  @media (max-width: 750px) {
    padding: 0;
    a {
      font-size: 0.7rem;
    }
  }
`;

const WeekDataStyled = styled.td`
  white-space: nowrap;
  text-align: right;
  position: relative;

  --accent: ${props =>
    props.vacation ? "var(--table-vacation)" : "transparent"};

  &:after {
    border-bottom: 15px solid var(--accent, transparent);
    border-left: 15px solid transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    right: 0;
    width: 0;
  }

  &:hover {
    outline: 1px solid var(--color-black);
  }

  @media (max-width: 750px) {
    input {
      font-size: 0.7rem;
    }
  }
`;

const IconImage = styled.td`
  text-align: center;
  img {
    width: 0.8rem;
    cursor: pointer;
  }
`;

class WeekData extends React.Component {
  render() {
    return (
      <WeekDataStyled vacation={this.props.vacation}>
        <input
          type="number"
          onBlur={this.props.onBlur}
          data-yearweek={this.props.yearweek}
          value={this.props.value}
          name={this.props.yearWeek}
          autoFocus={this.props.setFocus}
          onChange={this.props.onChange}
          className="weekData__input"
          min="0"
          step={this.props.fullDayHours}
        />
      </WeekDataStyled>
    );
  }
}

const SelectableWeekData = createSelectable(WeekData);

var vacWeeks = [];

class StaffingRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueUpdated: false,
      departments: this.props.departments,
      groups: this.props.groups,
      data: this.props.data
    };
    this.updateStaffing = this.updateStaffing.bind(this);
    this.deleteStaffing = this.deleteStaffing.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.data) {
      return {
        ...state,
        data: props.data
      };
    }
    return null;
  }

  updateStaffing(e) {
    if (this.state.valueUpdated) {
      this.props.sumbitStaffing(
        e.target.value,
        this.props.consultantId,
        this.props.engagementId,
        e.target.dataset.yearweek
      );
      this.setState({ valueUpdated: false });
      this.props.updateWeekSum();
    }
  }

  updateInputValue(index, e) {
    const data = this.state.data;
    data.staffing[index].hours = e.target.value;
    this.setState({ data: data, valueUpdated: true });
  }

  deleteStaffing() {
    fetch("/staffings", {
      method: "DELETE",
      body: JSON.stringify({
        consultantId: this.props.consultantId,
        engagementId: this.props.engagementId
      })
    }).then(
      response => {
        this.props.onDelete(this.props.consultantId, this.props.engagementId);
      },
      reason => {
        console.log(
          "Something went wrong while performing the request",
          reason
        );
      }
    );
  }

  render() {
    const { departments, groups } = this.state;
    const endDate = this.props.endDate;

    return (
      <StyledStaffingRow
        className={
          this.props.engagementStatus.toLowerCase() === "tilbud"
            ? "engagementRow__offerRow"
            : this.props.vacation
            ? "engagementRow_vacation"
            : ""
        }
      >
        {this.props.leadingValues.map((leadingValue, i) => (
          <LeadingValue key={i}>
            <Link
              to={
                "/" +
                departments +
                "/" +
                groups +
                "/" +
                leadingValue.link +
                "/" +
                this.props.weeks +
                "/" +
                this.props.offset +
                "/" +
                this.props.availableOnly
              }
            >
              {leadingValue.value}
            </Link>
          </LeadingValue>
        ))}
        <IconImage>
          {this.props.vacation ? null : (
            <img src={imgUrl} alt="Slett" onClick={this.deleteStaffing} />
          )}
        </IconImage>

        {this.state.data.staffing.map((week, i) => {
          if (this.props.holiday) {
            if (this.props.vacation && week.hours > 0) {
              vacWeeks.push(week.yearWeek);
              return (
                <SelectableWeekData
                  key={i}
                  selectableKey={{
                    engagementIndex: this.props.engagementIndex,
                    consultantIndex: this.props.consultantIndex,
                    weekIndex: i
                  }}
                  onBlur={this.updateStaffing}
                  yearweek={week.yearWeek}
                  value={week.hours}
                  setFocus={this.props.setFocus && i === 0}
                  onChange={this.updateInputValue.bind(this, i)}
                  holiday={this.props.holiday}
                  engagementId={this.props.engagementId}
                />
              );
            } else if (!this.props.vacation) {
              for (let j = 0; j < vacWeeks.length; j++) {
                if (week.yearWeek === vacWeeks[j]) {
                  return (
                    <SelectableWeekData
                      key={i}
                      selectableKey={{
                        engagementIndex: this.props.engagementIndex,
                        consultantIndex: this.props.consultantIndex,
                        weekIndex: i
                      }}
                      quited={
                        endDate &&
                        new Date(endDate) < getFirstDateOfWeek(week.yearWeek)
                      }
                      onBlur={this.updateStaffing}
                      fullDayHours={this.props.fullDayHours}
                      yearweek={week.yearWeek}
                      value={week.hours}
                      setFocus={this.props.setFocus && i === 0}
                      onChange={this.updateInputValue.bind(this, i)}
                      holiday={this.props.holiday}
                      engagementId={this.props.engagementId}
                    />
                  );
                }
              }
            }
            return null;
          } else if (this.props.vacation) {
            return (
              <VacationRow key={i}>
                <label>{week.hours}</label>
              </VacationRow>
            );
          }
          return (
            <SelectableWeekData
              key={i}
              selectableKey={{
                engagementIndex: this.props.engagementIndex,
                consultantIndex: this.props.consultantIndex,
                weekIndex: i
              }}
              onBlur={this.updateStaffing}
              yearweek={week.yearWeek}
              value={week.hours}
              fullDayHours={this.props.fullDayHours}
              setFocus={this.props.setFocus && i === 0}
              onChange={this.updateInputValue.bind(this, i)}
              holiday={this.props.holiday}
              engagementId={this.props.engagementId}
            />
          );
        })}
      </StyledStaffingRow>
    );
  }
}

export default StaffingRow;
