const nf = new Intl.NumberFormat("no-BM", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
});

export function format(num) {
  return nf.format(num);
}

const noFracton = new Intl.NumberFormat("no-BM", { maximumFractionDigits: 0 });

export function formatNoFraction(num) {
  return noFracton.format(num);
}
