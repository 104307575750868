import React from "react";
import styled from "styled-components";
import fetch from "./shared/Fetch";
import { getCurrentBudgetYear } from "./shared/TimeUtil";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";
import { AddNewButton } from "./sharedComponents/Atoms";
import { ReactComponent as CloseIcon } from "./svgs/CloseIcon.svg";
import { ReactComponent as AddIcon } from "./svgs/AddIcon.svg";

const ReportContainer = styled.div`
  padding: 5rem 10rem;
`;

const BudgetTable = styled.table`
  margin-top: 5rem;
`;

const StyledBudgetRow = styled.tr`
  &:nth-child(2n) {
    background: var(--table-band-background);
  }
  &:hover {
    background: var(--table-hover-background);
  }
`;

const StyledBudgetData = styled.td`
  white-space: nowrap;
  text-align: center;
  padding: 0.5rem;
`;

const BudgetDataInput = styled.input`
  width: 100%;
  text-align: center;
  font-size: 1rem;
  background: transparent;
  border: 0;
`;

const DepartmentsContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  gap: 1.5rem;
`;

const SectionTitle = styled.span`
  font-size: 1.2rem;
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  gap: 0.5rem;
`;

const DepartmentButton = styled.button`
  background: ${props =>
    props.selected
      ? "var(--filter-button-selected)"
      : "var(--button-background-color)"};
  border-radius: 5rem;
  color: ${props =>
    props.selected ? "white" : "var(--filter-button-selected)"};
  padding: 0.5rem 0.7rem;
  border: 1px solid transparent;
  border-color: ${props =>
    props.selected ? "transparent" : "var(--filter-button-selected)"};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;
  font-weight: ${props => (props.selected ? "bold" : "normal")};
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${props =>
      props.selected ? "white" : "var(--filter-button-hovered-color)"};
    border-color: ${props =>
      props.selected ? "transparent" : "var(--filter-button-hovered-color)"};
  }
  & svg:first-child {
    fill: red;
    margin-right: 0.5rem;
    display: ${props => (props.selected ? "grid" : "none")};
  }
`;

const AddIconStyled = styled(AddIcon)`
  margin-right: 0.5rem;
  height: 1.3rem;
  width: 1.3rem;
  & * {
    fill: var(--button-primary-color);
  }
`;

class Budget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      budget: [],
      department: "",
      departments: []
    };
    this.addNewBudgetYear = this.addNewBudgetYear.bind(this);
  }

  async componentDidMount() {
    this.fetchDepartments();
  }

  render() {
    const { budget, department, departments } = this.state;
    return (
      <div className="module">
        <AppHeading
          departments={departments.map(d => d.id)}
          offset={this.props.match.params.offset}
          weeks={this.props.match.params.weeks}
          groups={this.props.match.params.groups}
          availableOnly={this.props.match.params.availableOnly}
          active={"budget"}
        />
        <ReportContainer>
          <DepartmentsContainer>
            <SectionTitle>Velg avdeling</SectionTitle>
            <ButtonsContainer>
              {departments &&
                departments.map((d, i) => (
                  <DepartmentButton
                    key={d.id + "" + i}
                    onClick={() =>
                      this.setState({ budget: [], department: d.id }, () => {
                        this.fetchBudget();
                      })
                    }
                    selected={this.state.department === d.id}
                  >
                    <CloseIcon />
                    <span>
                      {" "}
                      {d.name}
                      {this.state.department.id}
                    </span>
                  </DepartmentButton>
                ))}
            </ButtonsContainer>
          </DepartmentsContainer>

          <form>
            <Spinner show={this.state.waitingForData} />
            <BudgetTable>
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }}>FY</th>
                  <th>Juli</th>
                  <th>August</th>
                  <th>September</th>
                  <th>Oktober</th>
                  <th>November</th>
                  <th>Desember</th>
                  <th>Januar</th>
                  <th>Februar</th>
                  <th>Mars</th>
                  <th>April</th>
                  <th>Mai</th>
                  <th>Juni</th>
                </tr>
              </thead>
              <tbody>
                {budget.map((b, i) => {
                  return (
                    <BudgetRow
                      key={department + b.year}
                      budgetYear={b}
                      department={department}
                    />
                  );
                })}
              </tbody>
            </BudgetTable>

            <AddNewButton type="submit" onClick={this.addNewBudgetYear}>
              <AddIconStyled />
              Nytt budsjettår
            </AddNewButton>
          </form>
        </ReportContainer>
      </div>
    );
  }

  addNewBudgetYear(e) {
    e.preventDefault();

    const newYear =
      this.state.budget.length > 0
        ? this.state.budget[this.state.budget.length - 1].year // Juli i inneværende år, regnes som neste år.
        : getCurrentBudgetYear();
    fetch("/budget/", {
      method: "POST",
      body: JSON.stringify({
        departmentId: this.state.department,
        yearMonth: newYear + "07",
        value: 0
      })
    }).then(
      response => {
        this.fetchBudget();
      },
      reason => console.log("Something went wrong during the request", reason)
    );
  }

  fetchBudget() {
    this.setState({ waitingForData: true });
    fetch("/budget?departments=" + this.state.department)
      .catch(reason => {
        console.log("Someting went wrong during the request", reason);
      })
      .then(response => {
        this.setState({ budget: response, waitingForData: false });
      });
  }

  fetchDepartments() {
    this.setState({ waitingForData: true });

    fetch("/departments")
      .catch(reason =>
        console.log("Something went wrong while fetching departments")
      )
      .then(response => {
        this.setState(
          {
            departments: response,
            department: response[0].id
          },
          () => this.fetchBudget()
        );
      });
  }
}

class BudgetRow extends React.Component {
  render() {
    const { budgetYear } = this.props;
    return (
      <StyledBudgetRow>
        <td style={{ fontWeight: "bold", fontSize: "0.7rem" }}>
          {budgetYear.year}
        </td>
        {budgetYear.months.map((month, i) => (
          <BudgetData
            key={this.props.department + month.yearMonth}
            budgetMonth={month}
            department={this.props.department}
          />
        ))}
      </StyledBudgetRow>
    );
  }
}

class BudgetData extends React.Component {
  constructor(props) {
    super(props);
    this.saveBudgetEntry = this.saveBudgetEntry.bind(this);
  }

  render() {
    return (
      <StyledBudgetData style={{ textAlign: "center" }}>
        <BudgetDataInput
          defaultValue={this.props.budgetMonth.value}
          onBlur={this.saveBudgetEntry}
        />
      </StyledBudgetData>
    );
  }

  saveBudgetEntry(e) {
    if (e.currentTarget.value !== this.props.budgetMonth.value) {
      fetch("/budget/", {
        method: "POST",
        body: JSON.stringify({
          departmentId: this.props.department,
          yearMonth: this.props.budgetMonth.yearMonth,
          value: e.currentTarget.value
        })
      }).catch(reason =>
        console.log("Something went wrong during the request", reason)
      );
    }
  }
}

export default Budget;
