import moment from "moment";
import "moment/locale/nb";

const getCurrentWeek = () => ("" + moment().isoWeek()).padStart(2, "0");
const getCurrentYearWeek = () => new Date().getUTCFullYear() + getCurrentWeek();
const getDateFromYearMonth = yearMonth => moment(yearMonth, "YYYYMM").toDate();

const getOffsetYearWeek = offset => {
  let date = moment().add(offset, "weeks");
  let offsetYear = date.toDate().getUTCFullYear();
  let offsetYearWeek = offsetYear + ("" + date.isoWeek()).padStart(2, "0");
  return offsetYearWeek;
};

const getYearWeekFromDateString = dateString => {
  let date = moment(dateString, "DD.MM.YYYY");
  return date.format("GGGGWW");
};

const getCurrentYear = () => new Date().getFullYear();

const getCurrentBudgetYear = () =>
  new moment().add(-6, "months").format("GGGG");

const getMomentDate = (date, pattern) =>
  date ? moment(date, pattern) : moment();

const formatDate = (date, pattern) => moment(date).format(pattern);

const splitYearWeek = yearWeek => {
  return {
    year: yearWeek.substr(0, 4),
    week: yearWeek.substr(4, 2)
  };
};

const getFirstDayOfWeek = yearWeek => {
  let ret = moment(getFirstDateOfWeek(yearWeek)).format("DD.MM");
  return ret;
};

const getFirstDateOfWeek = yearWeek => {
  moment.locale("nb");
  let ret = moment(yearWeek, "GGGGWW")
    .weekday(0)
    .toDate();
  return ret;
};

const getLastDateOfWeek = yearWeek => {
  moment.locale("nb");
  let ret = moment(yearWeek, "GGGGWW")
    .weekday(6)
    .toDate();
  return ret;
};

const getNextMonthsBasedOnYearMonth = (yearMonth, numberOfMonths) => {
  moment.locale("nb");
  const months = [];
  let month = moment(yearMonth + "01", "YYYYMMDD");
  for (var i = 0; i < numberOfMonths; i++) {
    months.push(month.format("MMM YY"));
    month.add(1, "months");
  }
  return months;
};

const getLast13Months = () => {
  const months = [];
  for (let i = 0; i < 13; i++) {
    const m = moment()
      .add(-12 + i, "months")
      .format("MMM YY");
    months.push(m);
  }
  return months;
};

export {
  getCurrentWeek,
  getCurrentYearWeek,
  getOffsetYearWeek,
  getCurrentYear,
  getCurrentBudgetYear,
  getMomentDate,
  formatDate,
  splitYearWeek,
  getFirstDayOfWeek,
  getFirstDateOfWeek,
  getLastDateOfWeek,
  getDateFromYearMonth,
  getNextMonthsBasedOnYearMonth,
  getYearWeekFromDateString,
  getLast13Months
};
