import React, { useEffect, useState } from "react";
import fetch from "./shared/Fetch";
import { format } from "./shared/NumberUtil";
import AppHeading from "./sharedComponents/AppHeading";
import ReportHeading from "./sharedComponents/ReportHeading";
import styled from "styled-components";

const ReportContainer = styled.div`
  padding: 0 10rem 5rem;
`;

const DeleteAddButton = styled.button`
  padding: 0 5px;
  margin: 3px 1rem;
  cursor: pointer;
`;

const AddColMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
`;

const Where = props => {
  const groundColumns = [
    { column: "CustomerName", title: "Kunde" },
    { column: "Name", title: "Konsulent" },
    { column: "Email", title: "E-postadresse" },
    { column: "Company", title: "By" },
    { column: "hours", title: "Timer", numeric: true, manditory: true }
  ];
  const [data, setData] = useState([]);
  const [sort, setSort] = useState({ column: "Company", desc: false });
  const [columns, setColums] = useState([
    { column: "CustomerName", title: "Kunde" },
    { column: "Name", title: "Konsulent" },
    { column: "hours", title: "Timer", numeric: true, manditory: true }
  ]);

  const fetchData = async () => {
    const response = await fetch("/where");
    setData(response);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleSort = column => {
    if (column === sort.column) setSort({ column: column, desc: !sort.desc });
    else setSort({ column: column, desc: false });
  };

  const removeColumn = column => {
    const newColumns = columns.filter(col => col.column !== column);
    setColums(newColumns);
  };

  const addColumn = column => {
    const newColumns = columns.concat([column]);
    setColums(newColumns);
  };

  const redusedDataDic = data.reduce((acc, d) => {
    let key = "";
    for (const c of columns) {
      if (!c.manditory) key = key + d[c.column];
    }
    if (!acc[key]) acc[key] = {};

    for (const c of columns) {
      if (c.manditory) {
        if (!acc[key][c.column]) acc[key][c.column] = 0;
        acc[key][c.column] += parseFloat(d[c.column]);
      } else {
        acc[key][c.column] = d[c.column];
      }
    }
    return acc;
  }, {});

  const redusedData = Object.values(redusedDataDic);
  const sortedData = redusedData.sort((a, b) => {
    if (!sort.column) return 0;
    let ret = b[sort.column] > a[sort.column] ? -1 : 1;
    if (sort.desc) ret = ret * -1;
    return ret;
  });

  const rows = sortedData.map((d, index) => {
    const cols = columns.map((c, i) => {
      if (c.numeric)
        return (
          <td key={i} className="data numeric">
            {format(d[c.column])}
          </td>
        );
      return (
        <td key={i} className="data">
          {d[c.column]}
        </td>
      );
    });
    return <tr key={index}>{cols}</tr>;
  });

  const headings = columns.map((c, index) => {
    let deleteBtn = "";
    if (!c.manditory) {
      deleteBtn = (
        <DeleteAddButton onClick={() => removeColumn(c.column)}>
          ✗
        </DeleteAddButton>
      );
    }

    if (c.numeric)
      return (
        <th
          key={index}
          onClick={() => toggleSort(c.column)}
          className="header numeric"
        >
          {c.title}
          {deleteBtn}
        </th>
      );
    return (
      <th key={index} onClick={() => toggleSort(c.column)} className="header">
        {c.title}
        {deleteBtn}
      </th>
    );
  });

  const availableCols = groundColumns
    .filter(gc => {
      return !columns.map(c => c.column).includes(gc.column);
    })
    .map(c => (
      <DeleteAddButton key={c.column} onClick={() => addColumn(c)}>
        {c.title}
      </DeleteAddButton>
    ));

  const weeks = props.match.params.weeks;
  const offset = props.match.params.offset;
  const departments = props.match.params.departments;
  const groups = props.match.params.groups;
  const availableOnly = props.match.params.availableOnly;

  return (
    <div className="module">
      <AppHeading
        departments={departments}
        offset={offset}
        weeks={weeks}
        groups={groups}
        availableOnly={availableOnly}
        active={"where"}
      />
      <ReportContainer>
        <ReportHeading
          departments={departments}
          offset={offset}
          weeks={weeks}
          groups={groups}
          availableOnly={availableOnly}
        />

        <h3 className="page-heading">Hvor jobber alle?</h3>
        <table>
          <thead>
            <tr>
              {headings}
              <th>
                <DeleteAddButton>+</DeleteAddButton>
                <AddColMenu>{availableCols}</AddColMenu>
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </ReportContainer>
    </div>
  );
};

export default Where;
