import React from "react";
import styled from "styled-components";

const PulsingDot = styled.div`
  left: 50%;
  top: 6rem;
  height: 10rem;
  width: 10rem;
  position: absolute;
  z-index: 1000;

  div {
    top: 0;
    left: 0;
    position: absolute;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 2px solid var(--theme-active-indicator);
    animation: pulse 0.3s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0);
      opacity: 0.1;
    }
    40% {
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
`;

class WeekData extends React.Component {
  render() {
    if (!this.props.show) return null;
    return (
      <PulsingDot show={this.props.show}>
        <div />
      </PulsingDot>
    );
  }
}

export default WeekData;
