import React from 'react';
import image from './favicon.png';
import { Link } from "react-router-dom";


class Popup extends React.Component {
    render() {
    return (
      <div className='popup'>
        <div className='popupInner'>
          <div className="popupText">
            <span>{this.props.text}</span><br />
            <span>{this.props.text2}</span><br /><br />
            <Link to={this.props.link} style={{ border: "none" }}><img src={image} alt="Logo" height="80" width="80" style={{ borderRadius: "200px" }}/></Link>
          </div>
          <Link to={this.props.link} className="closer">X</Link>
        </div>
      </div>
    );
  }
}

export default Popup;
