export function getQueryVariable(str, variable) {
  var query = str.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return "";
}

export function to(inner, departments, groups, weeks, offset, availableOnly) {
  return `/${departments}/${groups}/${inner}/${weeks}/${offset}/${availableOnly}`;
}
