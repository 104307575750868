import React from "react";
import { format } from "../shared/NumberUtil";
import styled from "styled-components";

export const SubmitButton = styled.button`
  background: var(--button-background-color);
  border: 1px solid var(--button-primary-color);
  border-radius: 0.2rem;
  color: var(--button-primary-color);
  cursor: pointer;
  font-size: 1.2rem;
  margin-top: 1.5rem;
  padding: var(--buttonPadding);
  text-decoration: none;
  transition: box-shadow 200ms ease-in;
  &:hover {
    border-color: var(--button-secondary-color);
    color: var(--button-secondary-color);
  }
  &:active {
    background: var(--button-secondary-color);
    color: white;
  }
  &:focus {
    outline-color: var(--button-secondary-color);
  }
`;

export const Button = styled.button`
  border: 1px solid var(--button-primary-color);
  border-radius: 0.2rem;
  color: var(--button-primary-color);
  background: var(--button-background-color);
  cursor: pointer;
  font-size: 1.2rem;
  margin-top: 1.5rem;
  padding: var(--buttonPadding);
  text-decoration: none;
  transition: box-shadow 200ms ease-in;

  &:hover {
    color: var(--button-hover-color);
    border-color: var(--button-hover-color);
  }
  &:active {
    color: white;
    border-color: var(--button-hover-color);
    background-color: var(--button-hover-color);
  }
  &:focus {
    outline-color: var(--button-hover-color);
  }
`;

export const ButtonUnmargined = styled(Button)`
  margin-top: 0;
`;

export const AddNewButton = styled(SubmitButton)`
  font-weight: bold;
  display: flex;
  align-items: center;
  border: 1px solid var(--button-primary-color);
  background: transparent;
  width: max-content;
  font-size: 0.9rem;
  &:hover {
    border-color: var(--button-secondary-color);
    color: var(--button-primary-color);
    & * {
      fill: var(--button-secondary-color);
    }
  }
  &:active {
    background: var(--button-secondary-color);
    color: white;
    & * {
      fill: var(--button-background-color);
    }
  }
  &:focus {
    outline-color: var(--button-secondary-color);
  }
`;

export const Heading = styled.div`
  font-size: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 750px) {
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

export const EditableHeading = styled.input`
  border: 0;
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  outline: none;
`;

export const WeekData = styled.td`
  padding: 0 0.2rem;
  white-space: nowrap;
  text-align: right;
  position: relative;

  background-color: ${props =>
    props.available ? "var(--table-available)" : ""};
  background-color: ${props =>
    props.overBooked ? "var(--table-overbooked)" : ""};

  visibility: ${props => (props.showOverBooked ? "" : "hidden")};

  --accent: ${props =>
    props.vacation ? "var(--table-vacation)" : "transparent"};

  color: ${props => (props.overBooked ? "var(--color-white)" : "")};
  color: ${props => (props.holidays ? "var(--table-holiday)" : "")};

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-bottom: 15px solid var(--accent, transparent);
    border-left: 15px solid transparent;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

export const OfferData = styled.span`
  font-size: 0.8rem;
  color: var(--non-prioritizedt-text-color);

  display: ${props => (props.hidden ? "none" : "")};
  color: ${props => (props.overBooked ? "var(--color-white)" : "")};

  @media (max-width: 900px) {
    display: none;
  }
`;

export const WeekContainer = styled.div`
  display: flex;
`;

export const Weeknum = styled.button`
  border: 0;
  font-size: 1rem;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  margin: 0.3rem;

  opacity: ${props => (props.past ? "0.7" : "0.8")};

  visibility: ${props => (props.outOfMonth ? "hidden" : "")};
`;

export function StaffingHoursTableCell({
  ordered,
  offered,
  overbooked,
  available,
  holiday,
  showoverbooked,
  calculation,
  vacation
}) {
  if (typeof offered === "undefined") {
    if (calculation) {
      if (ordered > 0) {
        return (
          <WeekData
            overBooked={overbooked}
            showOverBooked={showoverbooked}
            available={available}
            holidays={holiday}
            vacation={vacation}
            style={{ color: "red" }}
          >
            +{format(ordered)}
          </WeekData>
        );
      }
      return (
        <WeekData
          overBooked={overbooked}
          showOverBooked={showoverbooked}
          available={available}
          holidays={holiday}
          vacation={vacation}
          style={{ color: "red" }}
        >
          {format(ordered)}
        </WeekData>
      );
    }
    return (
      <WeekData
        overBooked={overbooked}
        showOverBooked={showoverbooked}
        available={available}
        holidays={holiday}
        vacation={vacation}
      >
        {format(ordered)}
      </WeekData>
    );
  }

  return (
    <WeekData showOverBooked={showoverbooked}>
      <OfferData>{format(offered)}</OfferData>
      <br />
      {format(ordered)}
    </WeekData>
  );
}

const InactiveCheckbox = styled.label`
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: max-content;

  > input {
    visibility: hidden;
    height: 1px;
    width: 1px;
    position: absolute;
  }

  > input:checked + div {
    background: var(--theme-active-indicator);

    > svg {
      visibility: visible;
    }
  }

  > input:checked ~ span {
    font-weight: 500;
  }
`;

const ActiveOnly = styled.input``;

const CheckboxLabel = styled.span`
  font-weight: 100;
  @media (max-width: 750px) {
    display: none;
  }
`;

const Icon = styled.svg`
  visibility: hidden;
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const StyledCheckbox = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.2rem;
  background-color: var(--table-hover-background);
  margin-right: 0.5rem;
  display: inline-block;
  transition: all 150ms;
`;

export function ToggleInactiveButton({
  showInactive = false,
  toggle,
  text = "Vis også innaktive kunder"
}) {
  return (
    <InactiveCheckbox htmlFor="showOnlyActiveCustomers">
      <ActiveOnly
        name="showOnlyActiveCustomers"
        id="showOnlyActiveCustomers"
        checked={showInactive}
        onChange={toggle}
        type="checkbox"
      />
      <StyledCheckbox>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      <CheckboxLabel>{text}</CheckboxLabel>
    </InactiveCheckbox>
  );
}
