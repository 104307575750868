import React from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import { Link } from "react-router-dom";
import fetch from "./shared/Fetch";
import {
  formatDate,
  getDateFromYearMonth,
  getNextMonthsBasedOnYearMonth
} from "./shared/TimeUtil";
import { Bar } from "react-chartjs-2";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";
import FilteringBar from "./sharedComponents/FilteringBar";
import { StaffingHoursTableCell } from "./sharedComponents/Atoms";
import { format } from "./shared/NumberUtil";
import { consultantUrl, engagementUrl, customerUrl } from "./shared/Url";
import { ReactComponent as csvIcon } from "./svgs/CsvIcon.svg";
import ReportHeading from "./sharedComponents/ReportHeading";

const ReportContainer = styled.div`
  padding: 0 10rem 5rem;
`;
const ConstEngagementGroup = styled.div`
  border: 1px solid var(--filter-button-selected);
  border-radius: 1rem;
  display: inline-block;
  overflow: hidden;
`;

const CSVIconStyled = styled(csvIcon)`
  margin-right: 0.3rem;
  height: 3rem;
  width: 3rem;
  padding-top: 1px;
  & * {
    fill: var(--button-abort-color);
  }
`;

const ConstEngagementGroupOuter = styled.div`
  margin: 2rem;
  text-align: center;
`;

const ConstEngagementRadio = styled.label`
  input {
    width: 0px;
    opacity: 0;
  }

  span {
    padding: var(--buttonPadding);
    transition: all 200ms ease-in;
    background: var(--color-white);
    display: inline-block;
    font-size: 1rem;
  }

  input:checked + span {
    color: var(--color-white);
    background: var(--filter-button-selected);
  }
`;

const GraphContainer = styled.div`
  height: 300px;
`;

const LeadingHeading = styled.th`
  padding: 0.5rem;
  text-align: left;
`;

const Month = styled.th`
  text-align: right;
`;

const MonthData = styled.td`
  text-align: right;
  white-space: nowrap;
`;

const ReportTable = styled.table`
  border-collapse: collapse;
  margin-top: 30px;
`;

const StyledNameColumn = styled.td`
  text-align: left;
  padding: 0.5rem;
`;

const StyledReportRow = styled.tr`
  &:nth-child(2n) {
    background: var(--table-band-background);
  }
  &:hover {
    background: var(--table-hover-background);
  }
`;

const SumRow = styled.tr`
  font-weight: bold;
`;

const MonthsNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Navigation = styled.div`
  font-size: 1.2rem;
  cursor: pointer;
  border-bottom: none;
  padding: 0.2rem;

  @media (max-width: 750px) {
    font-size: 1rem;
  }
`;

function ToggleButton({ children, checked, onChange }) {
  return (
    <ConstEngagementRadio>
      <input
        type="radio"
        name="constengagement"
        checked={checked}
        onChange={onChange}
      />
      <span>{children}</span>
    </ConstEngagementRadio>
  );
}

function ToggleBox({ view, showConsultant, showEngagement, showCustomer }) {
  return (
    <ConstEngagementGroupOuter>
      <ConstEngagementGroup>
        <ToggleButton checked={view === "Consultant"} onChange={showConsultant}>
          Konsulent
        </ToggleButton>
        <ToggleButton checked={view === "Engagement"} onChange={showEngagement}>
          Prosjekt
        </ToggleButton>
        <ToggleButton checked={view === "Customer"} onChange={showCustomer}>
          Kunde
        </ToggleButton>
      </ConstEngagementGroup>
    </ConstEngagementGroupOuter>
  );
}

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      department: this.props.match.params.department,
      report: { consultants: [], budget: [] },
      data: [],
      reportOrderedSum: [],
      reportOfferedSum: [],
      reportOfferedAndOrderedSum: [],
      reportOfferedAndOrderedBudgetSum: [],
      reportOrderedBudgetSum: [],
      waitingForData: true,
      budget: [],
      months: [],
      reportHeading: "Konsulent",
      monthOffset: 0,
      reportView: "Consultant"
    };

    this.showEngagement = this.showEngagement.bind(this);
    this.showConsultant = this.showConsultant.bind(this);
    this.showCustomer = this.showCustomer.bind(this);
    this.setOffsetBack = this.setOffsetBack.bind(this);
    this.setOffsetForward = this.setOffsetForward.bind(this);
    this.setOffsetNow = this.setOffsetNow.bind(this);
  }

  componentDidMount() {
    this.fetchReport();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.offset !== prevProps.match.params.offset ||
      this.props.match.params.weeks !== prevProps.match.params.weeks ||
      this.props.match.params.departments !==
        prevProps.match.params.departments ||
      this.props.match.params.groups !== prevProps.match.params.groups
    ) {
      this.fetchReport();
    }
  }

  showEngagement() {
    let engagements = this.state.report.engagements;
    this.setState({
      reportHeading: "Prosjekt",
      data: engagements,
      reportView: "Engagement"
    });
  }

  showCustomer() {
    let customers = this.state.report.customers;
    this.setState({
      reportHeading: "Kunde",
      data: customers,
      reportView: "Customer"
    });
  }

  showConsultant() {
    let consultants = this.state.report.consultants;
    this.setState({
      reportHeading: "Konsulent",
      data: consultants,
      reportView: "Consultant"
    });
  }

  async setOffsetBack() {
    let monthOffset = this.state.monthOffset - 3;
    this.setState({ monthOffset: monthOffset }, this.fetchReport);
  }
  async setOffsetForward() {
    let monthOffset = this.state.monthOffset + 3;
    this.setState({ monthOffset: monthOffset }, this.fetchReport);
  }
  async setOffsetNow() {
    this.setState({ monthOffset: 0 }, this.fetchReport);
  }

  render() {
    const {
      data,
      reportOrderedSum,
      reportOfferedSum,
      reportOfferedAndOrderedSum,
      reportOfferedAndOrderedBudgetSum,
      reportOrderedBudgetSum,
      months,
      budget,
      reportHeading
    } = this.state;

    const params = this.props.match.params;
    const { departments, offset, weeks, groups } = this.props.match.params;

    const departmentsArray = departments.split(",");
    const groupsArray = groups.split(",");
    const linkUrl = entry => {
      if (entry.consultantId) {
        return consultantUrl(params, entry.consultantId);
      }
      if (entry.engagementId) {
        return engagementUrl(params, entry.engagementId);
      }

      if (entry.customerId) {
        return customerUrl(params, entry.customerId);
      }

      return `#`;
    };

    const csvData = [];
    const csvHeader = [];
    csvHeader.push({ label: reportHeading, key: "name" });
    if (data.length > 0) {
      for (const s of data[0].staffing) {
        csvHeader.push({
          label: formatDate(getDateFromYearMonth(s.yearMonth), "MMM YYYY"),
          key: s.yearMonth.toString()
        });
      }
    }
    for (const d of data) {
      const row = {};
      row.name = d.name;
      for (const st of d.staffing) {
        row[st.yearMonth] = st.orderedHours;
      }
      csvData.push(row);
    }

    return (
      <div className="module">
        <AppHeading
          departments={departments}
          offset={offset}
          weeks={weeks}
          groups={groups}
          availableOnly={this.props.match.params.availableOnly}
          active={"report"}
        />
        <ReportContainer>
          <ReportHeading
            departments={departments}
            offset={offset}
            weeks={weeks}
            groups={groups}
            availableOnly={this.props.match.params.availableOnly}
          />

          <FilteringBar
            setWaitingForData={bool => this.setState({ waitingForData: bool })}
            selectedDepartments={departmentsArray}
            selectedGroups={groupsArray}
          />
          <MonthsNav>
            <Navigation key="previous-links" onClick={this.setOffsetBack}>
              <span>←</span>
            </Navigation>
            <Navigation key="now-links" onClick={this.setOffsetNow}>
              <span>Nå</span>
            </Navigation>
            <Navigation key="next-links" onClick={this.setOffsetForward}>
              <span>→</span>
            </Navigation>
          </MonthsNav>
          <Spinner show={this.state.waitingForData} />
          <ReportGraph
            months={months}
            budget={budget}
            offered={reportOfferedSum}
            ordered={reportOrderedSum}
            style={{ marginTop: "2rem" }}
          />

          <ToggleBox
            view={this.state.reportView}
            showConsultant={this.showConsultant}
            showEngagement={this.showEngagement}
            showCustomer={this.showCustomer}
          />
          <CSVLink
            filename={reportHeading + ".csv"}
            data={csvData}
            headers={csvHeader}
            separator={";"}
          >
            <CSVIconStyled title="Last ned CSV-fil" />
          </CSVLink>
          <ReportTable>
            <thead>
              <tr>
                <LeadingHeading>{reportHeading}</LeadingHeading>
                {months.map((month, i) => (
                  <Month key={i}>{month}</Month>
                ))}
                <Month>Sum</Month>
              </tr>
            </thead>
            <tbody>
              {data.map((entry, i) => (
                <StyledReportRow key={i}>
                  <StyledNameColumn>
                    <Link to={linkUrl(entry)}>{entry.name}</Link>
                  </StyledNameColumn>
                  {entry.staffing.map((month, j) => (
                    <StaffingHoursTableCell
                      key={j}
                      ordered={month.orderedHours}
                      offered={month.offeredHours}
                      showoverbooked={true}
                    />
                  ))}

                  <StaffingHoursTableCell
                    ordered={sumField("orderedHours", entry.staffing)}
                    offered={sumField("offeredHours", entry.staffing)}
                    showoverbooked={true}
                  />
                </StyledReportRow>
              ))}

              <SumRow className="line">
                <StyledNameColumn>Sum ordrereserve</StyledNameColumn>
                {reportOrderedSum.map((sum, i) => (
                  <MonthData key={i}>{format(sum)}</MonthData>
                ))}
                <MonthData>{sum(reportOrderedSum)}</MonthData>
              </SumRow>
              <SumRow>
                <StyledNameColumn>Ordrereserve mot budsjett</StyledNameColumn>
                {reportOrderedBudgetSum.map((sum, i) => (
                  <MonthData key={i}>{sum}%</MonthData>
                ))}
                <MonthData>
                  {toBudgetPercentage(reportOrderedSum, budget)}%
                </MonthData>
              </SumRow>
              <SumRow>
                <StyledNameColumn>
                  Sum ordre- og tilbudsreserve
                </StyledNameColumn>
                {reportOfferedAndOrderedSum.map((sum, i) => (
                  <MonthData key={i}>{format(sum)}</MonthData>
                ))}
                <MonthData>{sum(reportOfferedAndOrderedSum)}</MonthData>
              </SumRow>
              <SumRow>
                <StyledNameColumn>
                  Ordre- og tilbudsreserve mot budsjett
                </StyledNameColumn>
                {reportOfferedAndOrderedBudgetSum.map((sum, i) => (
                  <MonthData key={i}>{sum}%</MonthData>
                ))}
                <MonthData>
                  {toBudgetPercentage(reportOfferedAndOrderedSum, budget)}%
                </MonthData>
              </SumRow>
            </tbody>
          </ReportTable>
        </ReportContainer>
      </div>
    );
  }

  fetchReport() {
    this.setState({ waitingForData: true });
    const groups = this.props.match.params.groups;
    let groupsPath = "";
    if (groups && groups !== "all") {
      groupsPath = `&groups=${groups}`;
    }

    fetch(
      "/reports/forecast?departments=" +
        this.props.match.params.departments +
        "&offset=" +
        this.state.monthOffset +
        groupsPath
    )
      .then(response => {
        const reportOfferedAndOrderedSum = getReportSum(response.consultants, [
          "offeredHours",
          "orderedHours"
        ]);
        const reportOrderedSum = getReportSum(response.consultants, [
          "orderedHours"
        ]);
        const reportOfferedSum = getReportSum(response.consultants, [
          "offeredHours"
        ]);

        const reportOfferedAndOrderedBudgetSum = getReportBudgetSum(
          response.budget,
          reportOfferedAndOrderedSum
        );

        const reportOrderedBudgetSum = getReportBudgetSum(
          response.budget,
          reportOrderedSum
        );
        const months = getNextMonthsBasedOnYearMonth(
          response.budget[0].yearMonth,
          12
        );
        this.setState({
          months: months,
          report: response,
          budget: response.budget.map(budget => budget.value),
          reportOfferedAndOrderedSum: reportOfferedAndOrderedSum,
          reportOfferedSum: reportOfferedSum,
          reportOrderedSum: reportOrderedSum,
          reportOfferedAndOrderedBudgetSum: reportOfferedAndOrderedBudgetSum,
          reportOrderedBudgetSum: reportOrderedBudgetSum,
          data: response.consultants,
          waitingForData: false
        });
      })
      .catch(reason =>
        console.log("Something went wrong while fetching report")
      );
  }
}

class ReportGraph extends React.Component {
  render() {
    const { months, budget, ordered, offered } = this.props;
    const budgetColor = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--graph-main");
    const orderedColor = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--graph-primary-color");
    const offeredColor = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--graph-secondary-color");
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: "label"
      },
      elements: {
        line: {
          fill: false
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false
            },
            labels: months,
            stacked: true
          }
        ],
        yAxes: [
          {
            stacked: true,
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            gridLines: {
              display: false
            },
            labels: {
              show: true
            },
            ticks: {
              beginAtZero: false
            }
          }
        ]
      },
      animation: {
        duration: 0
      }
    };

    const data = {
      datasets: [
        {
          type: "line",
          label: "Budsjett",
          data: budget,
          fill: false,
          borderColor: budgetColor,
          backgroundColor: budgetColor,
          pointBorderColor: budgetColor,
          pointBackgroundColor: budgetColor,
          pointHoverBackgroundColor: budgetColor,
          pointHoverBorderColor: budgetColor,
          yAxisID: "y-axis-1"
        },
        {
          type: "bar",
          label: "Ordre",
          data: ordered,
          fill: false,
          backgroundColor: orderedColor,
          borderColor: orderedColor,
          hoverBackgroundColor: orderedColor,
          hoverBorderColor: orderedColor,
          yAxisID: "y-axis-1"
        },
        {
          type: "bar",
          label: "Tilbud",
          data: offered,
          fill: false,
          backgroundColor: offeredColor,
          borderColor: offeredColor,
          hoverBackgroundColor: offeredColor,
          hoverBorderColor: offeredColor,
          yAxisID: "y-axis-1"
        }
      ]
    };
    return (
      <GraphContainer>
        <Bar data={data} options={options} />
      </GraphContainer>
    );
  }
}

export default Report;

function getReportSum(consultants, properties) {
  return consultants
    .map(consultant => consultant.staffing)
    .reduce((result, currentValue) => {
      for (var i = 0; currentValue.length > i; i++) {
        for (var j = 0; j < properties.length; j++) {
          result[i] += parseInt(currentValue[i][properties[j]], 10);
        }
      }
      return result;
    }, Array(12).fill(0));
}

function getReportBudgetSum(budget, reportSum) {
  return reportSum.map((sum, i) => {
    return (
      ((sum === 0 ? 1 : sum) / (budget[i].value === 0 ? 1 : budget[i].value)) *
      100
    ).toFixed(0);
  });
}

function sumField(field, obj) {
  return obj.reduce((accum, curr) => accum + curr[field], 0);
}

function sum(obj) {
  return obj.reduce((accum, curr) => accum + curr, 0);
}

function toBudgetPercentage(factor, budget) {
  return Math.round((sum(factor) / sum(budget)) * 100);
}
