import React from "react";
import styled from "styled-components";
import fetch from "./shared/Fetch";
import { Link } from "react-router-dom";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";
import {
  EditableHeading,
  Button,
  SubmitButton,
  AddNewButton,
  ToggleInactiveButton
} from "./sharedComponents/Atoms";
import { ReactComponent as AddIcon } from "./svgs/AddIcon.svg";

const EngagementTable = styled.table`
  width: 100%;
`;

const EngagementNameColumn = styled.td`
  padding: 0.5rem;
  width: 25%;
`;

const EngagementMetaDataInput = styled.input`
  font-size: 1rem;
  border: 0;
  outline: none;
  background: transparent;
  text-align: left;
  width: 100%;

  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

const EngagementMetaDataColumn = styled.td`
  text-align: left;
`;

const StyledEngagementRow = styled.tr`
  text-align: left;

  &:nth-child(2n) {
    background: var(--table-band-background);
  }
  &:hover {
    background: var(--table-hover-background);
  }
  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

const LeadingHeading = styled.th`
  padding: 0.4rem;
  text-align: left;
  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

const TableHeading = styled.th`
  text-align: left;

  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
  width: max-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const HiddenSelect = styled.select`
  background: transparent;
  border: 0px;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }

  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;

const Container = styled.div`
  margin-top: 3rem;
`;

const AddNewProjectButton = styled(AddNewButton)`
  display: ${props => (props.hidden ? "none" : "flex")};
  margin: 0;
`;

const AddIconStyled = styled(AddIcon)`
  margin-right: 0.5rem;
  height: 1.3rem;
  width: 1.3rem;
  & * {
    fill: var(--button-primary-color);
  }
`;

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: { customerName: "", engagements: [] },
      valueChanged: false,
      showNewEngagementRow: false,
      waitingForData: true,
      newEngagement: {
        engagementName: "",
        status: "Tilbud",
        partner: "",
        projectManager: ""
      },
      departments: this.props.match.params.departments,
      groups: this.props.match.params.groups
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveCustomer = this.saveCustomer.bind(this);
    this.toggleNewEngagement = this.toggleNewEngagement.bind(this);
    this.saveNewEngagement = this.saveNewEngagement.bind(this);
  }

  componentDidMount() {
    this.fetchCustomer();
  }

  handleInputChange(e) {
    const customer = this.state.customer;
    const property = e.target.name;
    customer[property] = e.target.value;
    this.setState({
      customer: customer,
      valueChanged: true,
      showInactive: false
    });
  }

  toggleNewEngagement() {
    this.setState({ showNewEngagementRow: !this.state.showNewEngagementRow });
  }

  render() {
    const { customer, departments, groups } = this.state;
    let weeks = this.props.match.params.weeks;
    let offset = this.props.match.params.offset;
    const availableOnly = this.props.match.params.availableOnly;

    let filteredEngagement = customer.engagements;
    if (!this.state.showInactive) {
      filteredEngagement = customer.engagements.filter(
        engagement => engagement.status !== "Avsluttet"
      );
    }

    return (
      <div className="module">
        <AppHeading
          departments={departments}
          offset={offset}
          weeks={weeks}
          groups={groups}
          availableOnly={availableOnly}
          active={"customers"}
        />
        <div className="customer content">
          <EditableHeading
            onChange={this.handleInputChange}
            onBlur={this.saveCustomer}
            name="customerName"
            value={customer.customerName}
          />
          <form className="customer__container">
            <ToggleInactiveButton
              showInactive={this.state.showInactive}
              text="Vis også avsluttede prosjekt"
              toggle={() =>
                this.setState({ showInactive: !this.state.showInactive })
              }
            />
            <Spinner show={this.state.waitingForData} />
            <Container>
              <EngagementTable>
                <thead>
                  <tr>
                    <LeadingHeading>Engasjement</LeadingHeading>
                    <TableHeading>Status</TableHeading>
                    <TableHeading>Ansvarlig partner</TableHeading>
                    <TableHeading>Prosjektleder</TableHeading>
                  </tr>
                </thead>
                <tbody>
                  {filteredEngagement.map(engagement => (
                    <EngagementRow
                      key={engagement.engagementId}
                      engagement={engagement}
                      departments={departments}
                      groups={groups}
                      weeks={weeks}
                      offset={offset}
                      availableOnly={availableOnly}
                    />
                  ))}
                  {!this.state.showNewEngagementRow ? null : (
                    <EngagementRow
                      engagement={this.state.newEngagement}
                      saveEngagementToParentState={engagement =>
                        this.setState({ newEngagement: engagement })
                      }
                      isNewEngagement={true}
                    />
                  )}
                </tbody>
              </EngagementTable>
              <ButtonContainer>
                <AddNewProjectButton
                  hidden={this.state.showNewEngagementRow}
                  type="button"
                  onClick={this.toggleNewEngagement}
                >
                  <AddIconStyled />
                  Nytt prosjekt
                </AddNewProjectButton>
                <Button
                  style={{
                    display: this.state.showNewEngagementRow
                      ? "inline-block"
                      : "none"
                  }}
                  type="button"
                  onClick={this.toggleNewEngagement}
                >
                  Avbryt
                </Button>
                <SubmitButton
                  style={{
                    display: this.state.showNewEngagementRow
                      ? "inline-block"
                      : "none"
                  }}
                  type="submit"
                  onClick={this.saveNewEngagement}
                >
                  Lagre
                </SubmitButton>
              </ButtonContainer>
            </Container>
          </form>
        </div>
      </div>
    );
  }

  saveCustomer() {
    if (this.state.valueChanged) {
      fetch("/customers/" + this.state.customer.customerId, {
        method: "PUT",
        body: JSON.stringify({
          name: this.state.customer.customerName
        })
      }).then(
        response => {
          this.setState({ valueChanged: false });
        },
        reason => console.log("Something went wrong during the request", reason)
      );
    }
  }

  saveNewEngagement(e) {
    e.preventDefault();
    const { newEngagement } = this.state;
    fetch("/engagements", {
      method: "POST",
      body: JSON.stringify({
        customerId: this.state.customer.customerId,
        name: newEngagement.engagementName,
        status: newEngagement.status,
        partner: newEngagement.partner,
        projectManager: newEngagement.projectManager
      })
    }).then(
      response => {
        this.setState(
          {
            showNewEngagementRow: false,
            newEngagement: {
              engagementName: "",
              status: "Tilbud",
              partner: "",
              projectManager: ""
            }
          },
          this.fetchCustomer
        );
      },
      reason => console.log("Something went wrong during the request", reason)
    );
  }

  fetchCustomer() {
    this.setState({ waitingForData: true });
    fetch("/customers/" + this.props.match.params.id)
      .catch(reason => {
        console.log("Request resulted in an error", reason);
      })
      .then(response => {
        this.setState({ customer: response, waitingForData: false });
      });
  }
}

class EngagementRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      engagement: this.props.engagement,
      valueChange: false,
      isNewEngagement: this.props.isNewEngagement ? true : false,
      departments: this.props.departments,
      groups: this.props.groups,
      weeks: this.props.weeks,
      offset: this.props.offset,
      availableOnly: this.props.availableOnly
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveEngagement = this.saveEngagement.bind(this);
  }

  handleInputChange(e) {
    const engagement = this.state.engagement;
    const property = e.target.name;
    engagement[property] = e.target.value;
    if (e.target.type === "select-one") {
      this.setState({ engagement: engagement, valueChanged: true }, () => {
        this.saveEngagement();
      });
    } else {
      this.setState({ engagement: engagement, valueChanged: true });
    }
  }

  render() {
    const {
      engagement,
      departments,
      weeks,
      offset,
      groups,
      availableOnly
    } = this.state;
    return (
      <StyledEngagementRow>
        <EngagementNameColumn>
          {!this.state.isNewEngagement ? (
            <Link
              to={
                "/" +
                departments +
                "/" +
                groups +
                "/project/" +
                engagement.engagementId +
                "/" +
                weeks +
                "/" +
                offset +
                "/" +
                availableOnly
              }
            >
              {engagement.engagementName}
            </Link>
          ) : (
            <EngagementMetaDataInput
              style={{ textAlign: "left" }}
              placeholder="Prosjektnavn"
              onChange={this.handleInputChange}
              onBlur={this.saveEngagement}
              name="engagementName"
              value={
                engagement.engagmentName !== null
                  ? engagement.engagmentName
                  : ""
              }
              autoFocus
            />
          )}
        </EngagementNameColumn>
        <EngagementMetaDataColumn>
          <HiddenSelect
            value={engagement.status}
            onChange={this.handleInputChange}
            name="status"
          >
            <option>Tilbud</option>
            <option>Tapt</option>
            <option defaultValue>Ordre</option>
            <option>Avsluttet</option>
            <option>Internt</option>
          </HiddenSelect>
        </EngagementMetaDataColumn>
        <EngagementMetaDataColumn>
          <EngagementMetaDataInput
            placeholder="Ansvarlig partner"
            onChange={this.handleInputChange}
            onBlur={this.saveEngagement}
            name="partner"
            value={engagement.partner !== null ? engagement.partner : ""}
          />
        </EngagementMetaDataColumn>
        <EngagementMetaDataColumn>
          <EngagementMetaDataInput
            placeholder="Prosjektleder"
            onChange={this.handleInputChange}
            onBlur={this.saveEngagement}
            name="projectManager"
            value={
              engagement.projectManager !== null
                ? engagement.projectManager
                : ""
            }
          />
        </EngagementMetaDataColumn>
      </StyledEngagementRow>
    );
  }

  saveEngagement() {
    if (!this.state.isNewEngagement && this.state.valueChanged) {
      fetch("/engagements/" + this.state.engagement.engagementId, {
        method: "PUT",
        body: JSON.stringify({
          name: this.state.engagement.engagementName,
          status: this.state.engagement.status,
          partner: this.state.engagement.partner,
          projectManager: this.state.engagement.projectManager
        })
      }).then(
        response => {
          this.setState({ valueChanged: false });
        },
        reason => console.log("Something went wrong during the request", reason)
      );
    } else if (this.state.isNewEngagement) {
      this.props.saveEngagementToParentState(this.state.engagement);
    }
  }
}

export default Customer;
