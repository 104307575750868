import React, { useEffect, useState } from "react";
import fetch from "./shared/Fetch";
import { format } from "./shared/NumberUtil";
import AppHeading from "./sharedComponents/AppHeading";
import Spinner from "./sharedComponents/Spinner";
import styled from "styled-components";
import FilteringBar from "./sharedComponents/FilteringBar";
import { SumData, WeekData } from "./Consultants";
import { getLast13Months } from "./shared/TimeUtil";
import { PageHeading } from "./Reconciliation";

const ReconContainer = styled.div`
  padding: 0 10rem 5rem;
`;

const FilteringBarContainer = styled.div`
  margin-bottom: -2rem;
`;

const ConsultantName = styled.td`
  padding: 0.5rem;
  width: 15%;

  @media (max-width: 900px) {
    width: 30%;
  }

  @media (max-width: 750px) {
    padding: 0;
    font-size: 0.8rem;
  }
`;

const LeadingHeading = styled.th`
  padding: 0.5rem;
  text-align: left;
`;

const ReportTable = styled.table`
  border-collapse: collapse;
  margin-top: 30px;
`;

const ConsultantRow = styled.tr`
  &:nth-child(2n) {
    background: var(--table-band-background);
  }
  &:hover {
    background: var(--table-hover-background);
  }
`;

const SumRow = styled.tr`
  font-weight: bold;
`;

const Actuals = styled.div`
  color: ${props => (props.warning ? "red" : "DodgerBlue")};
`;

const Staffed = styled.div`
  color: black;
`;

const BarContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Month = styled.th`
  text-align: right;
`;

const ReconCell = ({ week }) => {
  const actuals = week.actuals ? week.actuals : 0;
  const staffed = week.staffed ? week.staffed : 0;
  let warning = true;
  if (staffed === 0 || actuals / staffed > 0.9) warning = false;

  return (
    <WeekData
      title={"Bemannet: " + format(staffed) + " - Levert: " + format(actuals)}
    >
      <Staffed>{format(staffed)}</Staffed>
      <Actuals warning={warning}>{format(actuals)}</Actuals>
    </WeekData>
  );
};

const ReconLine = ({ cons }) => {
  const weekCells = [];
  for (const [key, value] of Object.entries(cons.months)) {
    weekCells.push(<ReconCell key={key} week={value} />);
  }

  return (
    <ConsultantRow>
      <ConsultantName>{cons.name}</ConsultantName>
      {weekCells}
    </ConsultantRow>
  );
};

const ReconciliationReport = props => {
  const [data, setData] = useState([]);
  const [waitingForData, setWaitingForData] = useState(false);

  const weeks = props.match.params.weeks;
  const offset = props.match.params.offset;
  const departments = props.match.params.departments;
  const groups = props.match.params.groups;
  const availableOnly = props.match.params.availableOnly;

  const fetchData = async () => {
    setWaitingForData(true);
    const response = await fetch(`/reconciliation/report/`);
    await setData(Object.values(response.consultants));
    setWaitingForData(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const departmentsArray = departments === "all" ? [] : departments.split(",");
  const groupsArray = groups === "all" ? [] : groups.split(",");

  const filteredData = data
    .filter(element => {
      return departmentsArray.some(op => {
        return op === element.departmentId;
      });
    })
    .filter(element => {
      if (groupsArray.length === 0) return true;
      return element.groups.some(g => groupsArray.some(a => a === g.id));
    })
    .sort((a, b) => {
      if (a.name > b.name) return 1;
      return -1;
    });

  const sums = [];
  for (const row of filteredData) {
    let i = 0;
    for (const w of Object.values(row.months)) {
      if (!sums[i]) {
        sums[i] = { actuals: 0, staffed: 0 };
      }
      sums[i].actuals += w.actuals ? w.actuals : 0;
      sums[i].staffed += w.staffed ? w.staffed : 0;
      i++;
    }
  }
  const consultantRow = filteredData.map(cons => {
    return <ReconLine key={cons.id} cons={cons} />;
  });

  const yearMonths = getLast13Months();

  return (
    <div className="module">
      <AppHeading
        departments={departments}
        offset={offset}
        weeks={weeks}
        groups={groups}
        availableOnly={availableOnly}
        active={"reconciliation"}
      />
      <ReconContainer>
        <PageHeading
          departments={departments}
          offset={offset}
          weeks={weeks}
          groups={groups}
          availableOnly={availableOnly}
        />
        <Spinner show={waitingForData} />
        <h3 className="page-heading">Avstemmingsrapport</h3>
        <BarContainer>
          <FilteringBarContainer>
            <FilteringBar
              selectedDepartments={departmentsArray}
              selectedGroups={groupsArray}
            />
          </FilteringBarContainer>
        </BarContainer>
        {!waitingForData && (
        <ReportTable>
          <thead>
            <tr>
              <LeadingHeading>
                {["Antall: " + filteredData.length]}
              </LeadingHeading>
              {yearMonths.map((month, i) => (
                <Month key={i}>{month}</Month>
              ))}
            </tr>
          </thead>
          <tbody>
            {consultantRow}
            <SumRow className="line">
              <ConsultantName>Sum bemannet</ConsultantName>
              {sums.map((sum, i) => (
                <SumData key={i}>{format(sum.staffed)}</SumData>
              ))}
            </SumRow>
            <SumRow>
              <ConsultantName>Sum levert</ConsultantName>
              {sums.map((sum, i) => (
                <SumData key={i}>{format(sum.actuals)}</SumData>
              ))}
            </SumRow>
            <SumRow>
              <ConsultantName>Leveringgrad</ConsultantName>
              {sums.map((sum, i) => (
                <SumData key={i}>
                  {format((sum.actuals / sum.staffed) * 100)}%
                </SumData>
              ))}
            </SumRow>
          </tbody>
        </ReportTable>
        )}
      </ReconContainer>
    </div>
  );
};

export default ReconciliationReport;
