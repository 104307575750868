function getDepatements(params) {
  return params.departments.split(",");
}

function getGroups(params) {
  return params.groups.split(",");
}

function getsvailableOnly(params) {
  return params.availableOnly === "true";
}

export function consultantUrl(params, consultantId) {
  return `/${getDepatements(params)}/${getGroups(
    params
  )}/consultant/${consultantId}/${params.weeks}/${
    params.offset
  }/${getsvailableOnly(params)}`;
}

export function engagementUrl(params, engagementId) {
  return `/${getDepatements(params)}/${getGroups(
    params
  )}/project/${engagementId}/${params.weeks}/${
    params.offset
  }/${getsvailableOnly(params)}`;
}

export function customerUrl(params, customerId) {
  return `/${getDepatements(params)}/${getGroups(
    params
  )}/customer/${customerId}/${params.weeks}/${params.offset}/${getsvailableOnly(
    params
  )}`;
}
