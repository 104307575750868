import React from "react";
import fetch from "./shared/Fetch";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { getMomentDate, formatDate } from "./shared/TimeUtil";
import Spinner from "./sharedComponents/Spinner";
import AppHeading from "./sharedComponents/AppHeading";
import { Button, AddNewButton } from "./sharedComponents/Atoms";

import "react-datepicker/dist/react-datepicker.css";
import GroupsDropDown from "./sharedComponents/GroupsDropDown";
import { ReactComponent as AddIcon } from "./svgs/AddIcon.svg";
import { ReactComponent as BatchIcon } from "./svgs/batch.svg";
import { ReactComponent as ProjectIcon } from "./svgs/Project.svg";
import { ReactComponent as ArrowDownIcon } from "./svgs/ArrowDownIcon.svg";
import { CSVLink } from "react-csv";
import { ReactComponent as csvIcon } from "./svgs/CsvIcon.svg";
import FileUpload from "./sharedComponents/FileUpload";

const Container = styled.div`
  padding: 5rem 10rem;
  color: var(--color-grey);
  box-sizing: border-box;
`;

const CSVIconStyled = styled(csvIcon)`
  margin-right: 0.3rem;
  height: 3rem;
  width: 3rem;
  padding-top: 1px;
  & * {
    fill: var(--button-abort-color);
  }
`;

const ConsultantTable = styled.table`
  width: 100%;
  table-layout: fixed;
  & th {
    margin: 1rem;
  }
`;

const Th = styled.th`
  font-size: 1.1rem;
  padding-bottom: 2rem;
  text-align: left;
`;

const ConsultantRow = styled.tr`
  &:nth-child(2n) {
    background: var(--table-band-background);
  }
  &:hover {
    background: var(--table-hover-background);
  }
`;

const ConsultantData = styled.td`
  padding: 0.5rem 0;
  text-align: left;
  width: max-content;
`;

const HiddenSelect = styled.select`
  background: transparent;
  border: 0px;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::-ms-expand {
    display: none;
  }
`;

const ConsultantNameInput = styled.input`
  font-size: 1rem;
  border: 0;
  outline: none;
  background: transparent;
  text-align: left;
  width: 100%;
`;

const DropDownContainer = styled.div`
  position: relative;
`;

const GroupsLabel = styled.label`
  cursor: pointer;
  width: 100%;
  font-style: ${props => (props.empty ? "italic" : "normal")};
  color: ${props => (props.empty ? "gray" : "var(--color-grey)")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AddConsultantIcon = styled(AddIcon)`
  height: 1.1rem;
  width: 1.1rem;
  margin-right: 0.3rem;
  & * {
    fill: var(--color-grey);
  }
`;

const BatchImportConsultantIcon = styled(BatchIcon)`
  height: 1.1rem;
  width: 1.1rem;
  margin-right: 0.3rem;
  & * {
    fill: var(--color-grey);
  }
`;

const ProjectImportIcon = styled(ProjectIcon)`
  height: 1.1rem;
  width: 1.1rem;
  margin-right: 0.3rem;
  & * {
    fill: var(--color-grey);
  }
`;

const ArrowDownIconStyled = styled(ArrowDownIcon)`
  height: 8px;
  width: 12px;

  & * {
    fill: var(--color-grey);
  }
`;

const AddConsultatantDiv = styled.div`
  display: flex;
  flex-flow: column;
`;

const AddConsultantButton = styled(AddNewButton)`
  display: ${props => (props.showButton = true ? "flex" : "none")};
  align-items: center;
  background: transparent;
  font-weight: bold;
  cursor: pointer;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 1rem;
`;

const ButtonsContainer = styled.div`
  display: ${props => (props.showButtons ? "grid" : "none")};
  grid-template-columns: max-content max-content;
  gap: 1rem;
`;
const SaveButton = styled(Button)`
  &:hover {
    color: var(--button-secondary-color);
    border-color: var(--button-secondary-color);
  }
`;

const ErrorMessage = styled.div`
  position: fixed;
  color: red;
  width: 20rem;
  border: solid;
  padding: 1rem;
  background-color: white;
  margin-left: 30%;
`;

const SelectContainer = styled.div`
  width: ${props => (props.group ? (props.selected ? "70%" : "40%") : "35%")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: minmax(90%, max-content) 1fr;
  align-items: center;
  border: 1px solid transparent;
  border-color: ${props =>
    props.selected ? "var(--color-grey)" : "transparent"};
  padding: ${props => (props.selected ? "0.5rem 0.7rem " : "0")};
  border-bottom: none;
  cursor: ${props => (props.group ? "pointer" : "default")};

  & svg {
    ${props => (props.selected ? "transform: rotate(180deg);" : "")};
    justify-self: end;
    margin-left: 0.5rem;
  }
`;

class Administer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consultants: [],
      departments: [],
      groups: this.props.match.params.groups,
      startDate: getMomentDate(),
      showNewConsultantRow: false,
      isSuperAdmin: false,
      waitingForData: true,
      errorMessage: "",
      error: null,
      successMessage: null,
      newConsultant: {
        name: "",
        departmentId: "",
        endDate: null
      }
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNewConsultantDateChange = this.handleNewConsultantDateChange.bind(
      this
    );
    this.handleNewConsultantInputChange = this.handleNewConsultantInputChange.bind(
      this
    );
    this.toggleNewConsultant = this.toggleNewConsultant.bind(this);
    this.handleConsultantImport = this.handleConsultantImport.bind(this);
    this.handleProjectImport = this.handleProjectImport.bind(this);
  }

  componentDidMount() {
    this.fetchConsultants();
    this.fetchDepartments();
    this.fetchGroups();
    this.fetchCurrentUser();
  }

  async fetchCurrentUser() {
    const user = await fetch(`/user/current`);
    console.log(user);
    if (user.email === "oms@variant.no") this.setState({ isSuperAdmin: true });
  }

  handleInputChange(index, e) {
    const { consultants } = this.state;
    const property = e.target.name;

    const newConsultants = consultants.map(function(val, i) {
      if (i !== index) return val;
      return {
        ...val,
        [property]: e.target.value
      };
    });
    this.setState({ consultants: newConsultants });
    this.saveConsultant(newConsultants[index]);
  }

  removeConsultantGroupConnection(consultantId, groupId) {
    if (!consultantId || !groupId) {
      return;
    }
    fetch(
      `/consultants/${consultantId}/group
    `,
      {
        method: "DELETE",
        body: JSON.stringify({
          groupId: groupId
        })
      }
    ).then(
      response => {
        this.fetchConsultants();
      },
      reason =>
        console.log("Something went wrong during updating group name", reason)
    );
  }

  postConsultantGroupConnection(consultantId, groupId) {
    if (!consultantId || !groupId) {
      return;
    }
    fetch(
      `/consultants/${consultantId}/group
    `,
      {
        method: "POST",
        body: JSON.stringify({
          groupId: groupId
        })
      }
    ).then(
      response => {
        this.fetchConsultants();
      },
      reason =>
        console.log("Something went wrong during updating group name", reason)
    );
  }

  handleNewConsultantInputChange(e) {
    const { newConsultant } = this.state;
    const property = e.target.name;
    newConsultant[property] = e.target.value;
    this.setState({ newConsultant: newConsultant });
  }

  handleNewConsultantDateChange(date) {
    const { newConsultant } = this.state;
    newConsultant.endDate = date ? formatDate(date, "YYYYMMDD") : null;
    this.setState({ newConsultant: newConsultant });
  }

  handleDateChange(date, index) {
    const { consultants } = this.state;
    consultants[index].endDate = date ? formatDate(date, "YYYYMMDD") : null;
    this.setState({ consultants: consultants });
  }

  handleSaveConsultant(index) {
    const { consultants } = this.state;
    this.saveConsultant(consultants[index]);
  }

  toggleNewConsultant(e) {
    e.preventDefault();
    this.setState({ showNewConsultantRow: !this.state.showNewConsultantRow });
  }

  getAllGroupsString(consultant) {
    if (consultant.groups && consultant.groups.length > 0) {
      const groups = consultant.groups.map(g => g.name);

      return groups.length === 1
        ? groups[0]
        : `${groups[0]} (+${groups.length - 1})`;
    } else {
      return "Legg til grupper";
    }
  }

  async handleConsultantImport(rows) {
    this.setState({ waitingForData: true });
    const res = await fetch("/consultants/import", {
      method: "POST",
      body: JSON.stringify(rows)
    });
    const message = `Det ble importert ${res.success.length} konsulenter. ${res.error.length} konsulenter ble ikke importert`;
    if (res.error.length > 0) {
      this.setState({
        error: {
          updateErrors: res.error.map(e => e.name + ": " + e.reason),
          message
        }
      });
    } else {
      this.setState({
        successMessage: `Det ble importert ${res.success.length} konsulenter`
      });
    }
    this.setState({ waitingForData: false });
    this.fetchConsultants();
    console.log(res);
  }

  async handleProjectImport(rows) {
    console.log("Starter");
    this.setState({ waitingForData: true });
    const res = await fetch("/engagements/import", {
      method: "POST",
      body: JSON.stringify(rows)
    });
    const message = `Det ble importert ${res.success.length} prosjekter. ${res.error.length} prosjekter ble ikke importert`;
    if (res.error.length > 0) {
      this.setState({
        error: {
          updateErrors: res.error.map(e => e.name + ": " + e.reason),
          message
        }
      });
    } else {
      this.setState({
        successMessage: `Det ble importert ${res.success.length} prosjekter`
      });
    }
    this.setState({ waitingForData: false });
    console.log(res);
  }

  render() {
    const { consultants, departments, groups, newConsultant } = this.state;
    const depList = departments.map(department => {
      return (
        <option key={department.id} value={department.id}>
          {department.name}
        </option>
      );
    });

    const csvData = [];
    const csvHeader = [];
    csvHeader.push({ label: "Konsulent", key: "name" });
    csvHeader.push({ label: "Avdeling", key: "department" });
    csvHeader.push({ label: "Epost", key: "email" });
    csvHeader.push({ label: "Grupper", key: "groups" });
    csvHeader.push({ label: "Sluttdato", key: "endDate" });
    for (const d of consultants) {
      const row = {};
      row.name = d.name;
      row.department = d.departmentId;
      d.groups = "";
      if (row.groups) row.groups = d.groups.map(group => group.name).join(", ");
      row.endDate = d.endDate;
      row.email = d.email;
      csvData.push(row);
    }

    return (
      <div className="module">
        <AppHeading
          departments={this.props.match.params.departments}
          offset={this.props.match.params.offset}
          weeks={this.props.match.params.weeks}
          groups={this.props.match.params.groups}
          availableOnly={this.props.match.params.availableOnly}
          active={"administer"}
        >
          {" "}
        </AppHeading>
        <Container>
          <form className="administer-consultants">
            <Spinner show={this.state.waitingForData} />
            {this.state.errorMessage.length > 0 && (
              <ErrorMessage>{this.state.errorMessage}</ErrorMessage>
            )}
            <CSVLink
              filename={"Konsulenter.csv"}
              data={csvData}
              headers={csvHeader}
              separator={";"}
            >
              <CSVIconStyled title="Last ned CSV-fil" />
            </CSVLink>
            <AddConsultatantDiv>
              {this.state.isSuperAdmin && (
                <>
                  <FileUpload
                    type="button"
                    handleRows={this.handleProjectImport}
                    error={this.state.error}
                    successMessage={this.state.successMessage}
                  >
                    <ProjectImportIcon />
                    Batchimport av prosjekter
                  </FileUpload>
                  <FileUpload
                    type="button"
                    handleRows={this.handleConsultantImport}
                    error={this.state.error}
                    successMessage={this.state.successMessage}
                  >
                    <BatchImportConsultantIcon />
                    Batchimport av konsulenter
                  </FileUpload>
                </>
              )}
              <AddConsultantButton
                onClick={this.toggleNewConsultant}
                type="button"
                showButton={!this.state.showNewConsultantRow}
              >
                <AddConsultantIcon />
                Ny konsulent
              </AddConsultantButton>
            </AddConsultatantDiv>
            <ConsultantTable>
              <thead style={{ marginBottom: "1rem" }}>
                <tr>
                  <Th>NAVN</Th>
                  <Th>E-POST</Th>
                  <Th>AVDELING</Th>
                  <Th>GRUPPE</Th>
                  <Th>SLUTTDATO</Th>
                </tr>
              </thead>
              <tbody>
                {consultants.map((consultant, i) => (
                  <ConsultantRow key={consultant.consultantId}>
                    <ConsultantData>
                      <ConsultantNameInput
                        defaultValue={consultant.name}
                        name="name"
                        onBlur={this.handleInputChange.bind(this, i)}
                      />
                    </ConsultantData>
                    <ConsultantData>
                      <ConsultantNameInput
                        defaultValue={consultant.email}
                        name="email"
                        onBlur={this.handleInputChange.bind(this, i)}
                      />
                    </ConsultantData>
                    <ConsultantData>
                      <SelectContainer>
                        <HiddenSelect
                          name="departmentId"
                          onChange={this.handleInputChange.bind(this, i)}
                          value={consultant.departmentId}
                          style={{ cursor: "pointer" }}
                        >
                          {depList}
                        </HiddenSelect>
                        <ArrowDownIconStyled />
                      </SelectContainer>
                    </ConsultantData>
                    <ConsultantData>
                      <SelectContainer
                        selected={
                          this.state.dropDownIdIsOpen ===
                          consultant.consultantId
                        }
                        onClick={() =>
                          this.setState({
                            dropDownIdIsOpen:
                              this.state.dropDownIdIsOpen ===
                              consultant.consultantId
                                ? ""
                                : consultant.consultantId
                          })
                        }
                        group={true}
                      >
                        <GroupsLabel
                          empty={
                            !consultant.groups || consultant.groups.length === 0
                          }
                        >
                          {this.getAllGroupsString(consultant)}
                        </GroupsLabel>
                        <ArrowDownIconStyled />
                      </SelectContainer>
                      {this.state.dropDownIdIsOpen ===
                        consultant.consultantId && (
                        <DropDownContainer>
                          <GroupsDropDown
                            groups={groups}
                            selectedGroups={consultant.groups}
                            onGroupSelect={groupId =>
                              this.postConsultantGroupConnection(
                                consultant.consultantId,
                                groupId
                              )
                            }
                            linkToNewGroup={`/${this.props.match.params.departments}/all/groups/${this.props.match.params.weeks}/${this.props.match.params.offset}`}
                            onGroupUnselect={groupId =>
                              this.removeConsultantGroupConnection(
                                consultant.consultantId,
                                groupId
                              )
                            }
                            onCloseDropdown={() =>
                              this.setState({
                                dropDownIdIsOpen: ""
                              })
                            }
                          />
                        </DropDownContainer>
                      )}
                    </ConsultantData>
                    <ConsultantData>
                      <DatePicker
                        className="datepicker-input"
                        selected={
                          consultant.endDate
                            ? getMomentDate(consultant.endDate)
                            : null
                        }
                        onChange={date => this.handleDateChange(date, i)}
                        onBlur={this.handleSaveConsultant.bind(this, i)}
                        placeholderText="Klikk og velg en dato"
                      />
                    </ConsultantData>
                  </ConsultantRow>
                ))}
                {this.state.showNewConsultantRow ? (
                  <ConsultantRow key="new-consultant">
                    <ConsultantData>
                      <ConsultantNameInput
                        placeholder="Navn"
                        value={newConsultant.name}
                        name="name"
                        onChange={this.handleNewConsultantInputChange}
                        autoFocus
                      />
                    </ConsultantData>
                    <ConsultantData>
                      <ConsultantNameInput
                        placeholder="post@domain.com"
                        defaultValue={newConsultant.email}
                        name="email"
                        onChange={this.handleNewConsultantInputChange}
                      />
                    </ConsultantData>
                    <ConsultantData>
                      <HiddenSelect
                        name="departmentId"
                        onChange={this.handleNewConsultantInputChange}
                        defaultValue={this.state.department}
                      >
                        {departments.map(department => (
                          <option key={department.id} value={department.id}>
                            {department.name}
                          </option>
                        ))}
                      </HiddenSelect>
                    </ConsultantData>
                    <ConsultantData>(Lagre først...)</ConsultantData>
                    <ConsultantData>
                      <DatePicker
                        className="datepicker-input"
                        mul
                        selected={
                          newConsultant.endDate && newConsultant.endDate !== ""
                            ? getMomentDate(newConsultant.endDate, "YYYYMMDD")
                            : null
                        }
                        onChange={date =>
                          this.handleNewConsultantDateChange(date)
                        }
                        placeholderText="Klikk og velg en dato"
                      />
                    </ConsultantData>
                  </ConsultantRow>
                ) : null}
              </tbody>
            </ConsultantTable>
            {consultants.length > 15 && (
              <AddConsultatantDiv>
                <AddConsultantButton
                  onClick={this.toggleNewConsultant}
                  type="button"
                  showButton={!this.state.showNewConsultantRow}
                >
                  <AddConsultantIcon />
                  Ny konsulent
                </AddConsultantButton>
              </AddConsultatantDiv>
            )}
            <ButtonsContainer showButtons={this.state.showNewConsultantRow}>
              <Button onClick={this.toggleNewConsultant} type="button">
                Avbryt
              </Button>
              <SaveButton
                onClick={e => {
                  e.preventDefault();
                  this.saveConsultant(newConsultant);
                }}
                type="submit"
              >
                Lagre
              </SaveButton>
            </ButtonsContainer>
          </form>
        </Container>
      </div>
    );
  }

  saveConsultant(consultant) {
    const newConsultant = {
      consultantId: consultant.consultantId,
      name: consultant.name,
      email: consultant.email,
      departmentId:
        consultant.departmentId !== ""
          ? consultant.departmentId
          : this.state.departments[0].id,
      endDate: consultant.endDate
    };
    fetch("/consultants", {
      method: consultant.consultantId ? "PUT" : "POST",
      body: JSON.stringify(newConsultant)
    }).then(
      response => {
        if (response.error) {
          this.setState({ errorMessage: response.error });
        }
        if (response.status) {
          this.setState({
            newEngagementId: -1,
            showNewConsultantRow: false,
            errorMessage: ""
          });
        } else {
          this.setState(
            {
              newEngagementId: -1,
              showNewConsultantRow: false,
              consultants: this.state.consultants.concat({
                ...newConsultant,
                consultantId: response.generatedId
              })
            },
            this.setState({
              newConsultant: {
                name: "",
                departmentId: "",
                endDate: null
              }
            })
          );
        }
      },
      reason => {
        console.log(
          "Something went wrong while performing the request",
          reason
        );
      }
    );
  }

  fetchConsultants() {
    this.setState({ waitingForData: true });
    fetch("/consultants?departments=" + this.props.match.params.departments)
      .catch(reason => {
        console.log("Something went wrong while fetching Consultants");
      })
      .then(response => {
        this.setState({ consultants: response, waitingForData: false });
      });
  }

  fetchDepartments() {
    fetch("/departments")
      .catch(reason =>
        console.log("Something went wrong while fetching departments")
      )
      .then(response => {
        this.setState({
          departments: response
        });
      });
  }

  fetchGroups() {
    fetch("/groups")
      .catch(reason =>
        console.log("Something went wrong while fetching departments")
      )
      .then(response => {
        const sortedGroups = response.sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase()
            ? -1
            : a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : 0
        );
        this.setState({ groups: sortedGroups });
      });
  }
}

export default Administer;
