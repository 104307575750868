const tokenKey = "__bms";

export function isLoggedIn() {
  return localStorage.getItem(tokenKey) != null;
}

export function getToken() {
  return localStorage.getItem(tokenKey);
}

export function setToken(token) {
  return localStorage.setItem(tokenKey, token);
}

export function removeToken() {
  localStorage.removeItem(tokenKey);
}
