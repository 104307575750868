import React from "react";
import styled from "styled-components";
import moment from "moment";
import { SelectableGroup } from "react-selectable";
import { Link } from "react-router-dom";
import fetch from "./shared/Fetch";
import StaffingRow from "./sharedComponents/StaffingRow";
import Spinner from "./sharedComponents/Spinner";
import Popup from "./sharedComponents/Popup";
import AppHeading from "./sharedComponents/AppHeading";
import Table from "./sharedComponents/Table";
import {
  Heading,
  StaffingHoursTableCell,
  SubmitButton,
  Button
} from "./sharedComponents/Atoms";

const Container = styled.div`
  margin-left: 0rem;
`;

const HeadingContainer = styled.div``;

const Content = styled.div`
  margin-top: 5px;
  padding: 2rem;
`;

const InfoText = styled.p`
  font-size: 1.1rem;
`;

const List = styled.ul`
  list-style-type: none;
`;

const Pill = styled.span`
  border-radius: 50%;
  display: inline-block;
  padding: 0.5rem;
`;

const AvailablePill = styled(Pill)`
  background-color: var(--table-available);
`;

const VacationPill = styled(Pill)`
  background-color: var(--table-vacation);
`;

const OverbookingPill = styled(Pill)`
  background-color: var(--table-overbooked);
`;

const SumLeadingColumn = styled.td`
  padding: 5px;
  @media (max-width: 750px) {
    padding: 5px 0 5px 0;
    font-size: 0.6rem;
  }
`;

const SumRow = styled.tr`
  font-weight: bold;
  padding: 0;

  @media (max-width: 750px) {
    font-weight: normal;
    font-size: 0.7rem;
  }
`;

const ButtonContainer = styled.div`
  display: inline-block;
  > button {
    margin: 1rem;
  }
`;

class Holiday extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consultant: { engagements: [] },
      consultants: [],
      customers: [],
      departments: this.props.match.params.departments,
      groups: this.props.match.params.groups,
      enable: false,
      focusEngagementId: "",
      hideItems: false,
      futureVacationWeeks: [],
      holidays: [],
      orderedWeekSum: [],
      fullDayHours: 8.0,
      orderedAndInternalWeekSum: [],
      showPopup: false,
      spentVacationDays: [],
      vacationDays: [],
      vacationWeeks: [],
      futureVacationYearWeeks: [],
      value: "",
      waitingForData: true,
      year: moment().year(),
      yearWeeks: [],
      weeks: []
    };

    this.fetchHolidays = this.fetchHolidays.bind(this);
    this.fetchConsultants = this.fetchConsultants.bind(this);
    this.updateWeekSum = this.updateWeekSum.bind(this);
    this.handleDragSelection = this.handleDragSelection.bind(this);
    this.sumbitStaffing = this.sumbitStaffing.bind(this);
    this.engagementDeleted = this.engagementDeleted.bind(this);
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.offset !== prevProps.match.params.offset ||
      this.props.match.params.weeks !== prevProps.match.params.weeks
    ) {
      this.fetchData();
    }
  }

  setStaffing(hours, engagementIndex, weekIndex) {
    let consultant = this.state.consultant;
    let engagementIdToUpdate =
      consultant.engagements[engagementIndex].engagementId;
    let yearWeekToUpdate =
      consultant.engagements[engagementIndex].staffing[weekIndex].yearWeek;

    consultant.engagements[engagementIndex].staffing[weekIndex].hours = hours;
    this.setState({ consultant: consultant });
    this.sumbitStaffing(
      hours,
      this.state.consultant.consultantId,
      engagementIdToUpdate,
      yearWeekToUpdate
    );
  }

  handleDragSelection(selectedKeys) {
    if (!selectedKeys || !selectedKeys[0]) return;

    let firstEngagementIndex = selectedKeys[0].engagementIndex;
    let firstWeekIndex = selectedKeys[0].weekIndex;
    let hours = this.state.consultant.engagements[firstEngagementIndex]
      .staffing[firstWeekIndex].hours;

    for (let i = 1; i < selectedKeys.length; i++) {
      this.setStaffing(
        hours,
        selectedKeys[i].engagementIndex,
        selectedKeys[i].weekIndex
      );
    }
    this.updateWeekSum();
  }

  sumbitStaffing(hours, consultantId, engagementId, yearWeek) {
    fetch("/staffings", {
      method: "POST",
      body: JSON.stringify({
        hours: hours,
        consultantId: consultantId,
        engagementId: engagementId,
        yearWeek: yearWeek
      })
    });
  }

  render() {
    const {
      consultant,
      bookingStatus,
      departments,
      groups,
      orderedWeekSum,
      orderedAndInternalWeekSum,
      fullDayHours
    } = this.state;
    const id = this.props.match.params.id;
    const offset = this.props.match.params.offset;
    const weeks = this.props.match.params.weeks;
    const availableOnly = this.props.match.params.availableOnly;

    const to = `/${departments}/${groups}/consultant/${id}/${weeks}/${offset}/${availableOnly}`;
    const to2 = `/${departments}/${groups}/calendar/${id}/${weeks}/${offset}/${availableOnly}`;

    return (
      <div className="module">
        <AppHeading
          departments={departments}
          offset={offset}
          weeks={weeks}
          groups={groups}
          availableOnly={availableOnly}
          active={"consultants"}
        />
        <Container className="content">
          <HeadingContainer>
            <Heading>
              <h3>
                Sjekk bemanning -{" "}
                <Link to={to}>{consultant.consultantName}</Link>
              </h3>
              <InfoText>
                {this.state.value
                  ? this.state.value
                  : "Bemanningen din er riktig."}
              </InfoText>
            </Heading>
          </HeadingContainer>
          <Content>
            {
              <form>
                <Spinner show={this.state.waitingForData} />
                <SelectableGroup
                  preventDefault={false}
                  onSelection={this.handleDragSelection}
                >
                  <Table
                    leadingHeaders={["Kunde", "Prosjekt"]}
                    params={this.props.match.params}
                    inner={"holiday/" + id}
                    yearWeeks={this.state.yearWeeks}
                  >
                    {consultant.engagements.map((engagement, i) => {
                      if (
                        engagement.engagementStatus.toLowerCase() !== "tilbud"
                      ) {
                        return (
                          <StaffingRow
                            key={i}
                            engagementIndex={i}
                            leadingValues={[
                              {
                                link: "customer/" + engagement.customerId,
                                value: engagement.customerName
                              },
                              {
                                link: "project/" + engagement.engagementId,
                                value: engagement.engagementName
                              }
                            ]}
                            engagementStatus={engagement.engagementStatus}
                            data={engagement}
                            consultantId={consultant.consultantId}
                            engagementId={engagement.engagementId}
                            updateWeekSum={this.updateWeekSum}
                            departments={departments}
                            availableOnly={availableOnly}
                            fullDayHours={fullDayHours}
                            groups={groups}
                            weeks={this.props.match.params.weeks}
                            offset={this.props.match.params.offset}
                            sumbitStaffing={this.sumbitStaffing}
                            onDelete={this.engagementDeleted}
                            setFocus={
                              this.state.focusEngagementId ===
                              engagement.engagementId
                            }
                            vacation={engagement.vacation}
                          />
                        );
                      }
                      return null;
                    })}
                    <SumRow className="line">
                      <SumLeadingColumn>Avvik</SumLeadingColumn>
                      <td />
                      <td />
                      {orderedAndInternalWeekSum.map((sum, i) => {
                        var ordered = sum - 37.5;
                        if (ordered >= 0) {
                          return (
                            <StaffingHoursTableCell
                              key={i}
                              ordered={ordered}
                              calculation={true}
                              overbooked={bookingStatus[i].overbooked}
                              available={bookingStatus[i].available}
                              holiday={bookingStatus[i].holiday}
                              orderedAndInternalWeekSum={
                                orderedAndInternalWeekSum - 37.5
                              }
                              showoverbooked={
                                bookingStatus[i].overbooked ||
                                bookingStatus[i].available
                              }
                            />
                          );
                        } else {
                          return <td key={`empty ${i}`}></td>;
                        }
                      })}
                    </SumRow>
                    <SumRow>
                      <SumLeadingColumn>Bemanning</SumLeadingColumn>
                      <td />
                      <td />
                      {orderedWeekSum.map((sum, i) => {
                        return (
                          <StaffingHoursTableCell
                            key={i}
                            ordered={sum}
                            overbooked={bookingStatus[i].overbooked}
                            available={false}
                            holiday={bookingStatus[i].holiday}
                            showoverbooked={true}
                          />
                        );
                      })}
                    </SumRow>
                  </Table>
                </SelectableGroup>
              </form>
            }
            <List className="table-helpbox-list">
              <li>
                <VacationPill /> Ferie
              </li>
              <li>
                <AvailablePill /> Ledig tid
              </li>
              <li>
                <OverbookingPill /> Overbooking
              </li>
              <li className="holiday">Helligdag</li>
            </List>
            <ButtonContainer>
              <Link to={to2}>
                <Button>← Tilbake</Button>
              </Link>
              <SubmitButton onClick={this.togglePopup.bind(this)}>
                Fullfør registrering
              </SubmitButton>
            </ButtonContainer>
          </Content>
          {this.state.showPopup ? (
            <Popup
              link={to}
              text="Ferien din er registrert."
              text2="Ha en fin ferie!"
              closePopup={this.togglePopup.bind(this)}
            />
          ) : null}
        </Container>
      </div>
    );
  }

  getWeekSum(engagements, isOrdered = true, isInternal = false) {
    let numWeeks = parseInt(this.props.match.params.weeks, 10);
    if (engagements.length === 0) return Array(numWeeks).fill(0);
    return engagements
      .filter(
        engagement =>
          (isOrdered &&
            (engagement.engagementStatus.toLowerCase() === "ordre" ||
              engagement.engagementStatus.toLowerCase() === "ferdig")) ||
          (!isOrdered &&
            engagement.engagementStatus.toLowerCase() === "tilbud") ||
          engagement.engagementStatus.toLowerCase() === "ordre" ||
          engagement.engagementStatus.toLowerCase() === "ferdig" ||
          (isInternal &&
            engagement.engagementStatus.toLowerCase() === "internt")
      )
      .map(engagement => engagement.staffing)
      .reduce((result, currentValue) => {
        for (var i = 0; currentValue.length > i; i++) {
          result[i] += parseFloat(currentValue[i].hours, 10);
        }
        return result;
      }, Array(engagements[0].staffing.length).fill(0));
  }

  updateWeekSum() {
    let orderedAndInternalWeekSum = this.getWeekSum(
      this.state.consultant.engagements,
      true,
      true
    );
    this.setState({
      orderedWeekSum: this.getWeekSum(this.state.consultant.engagements, true),
      orderedAndInternalWeekSum: orderedAndInternalWeekSum,
      bookingStatus: this.calculateBookingStatus(
        orderedAndInternalWeekSum,
        this.state.consultant.emptyStaffing,
        this.state.consultant.disableWarnOnOverbooked
      )
    });
  }

  engagementDeleted(consultantId, engagementId) {
    let engagements = this.state.consultant.engagements.filter(
      eng => eng.engagementId !== engagementId
    );
    let consultant = this.state.consultant;
    consultant.engagements = engagements;
    this.setState({ consultant: consultant }, this.fetchConsultants());
  }

  async fetchData() {
    this.setState({ waitingForData: true });
    this.fetchConsultants();
    try {
      await Promise.all([this.fetchHolidays(), this.fetchConsultants()]);
    } finally {
      this.setState({ waitingForData: false });
    }
    if (this.state.customers.length === 0) {
      this.fetchCustomers();
    }
  }

  async fetchHolidays() {
    try {
      const response = await fetch(`/holidays/${this.state.year}`);
      const holidays = response.map(day => moment(day.date).format("DDMMYYYY"));

      this.setState({ holidays: holidays });
    } catch (err) {
      console.log("Request resulted in an error", err);
    }
  }

  async fetchVacation() {
    const id = this.props.match.params.id;
    try {
      const response = await fetch(
        `/vacation/consultants/${id}/${this.state.year}`
      );
      let vacationDays = [];
      let spentVacationDays = [];
      let vacationWeeks = [];
      let futureVacationYearWeeks = [];
      if (response.vacations) {
        for (let vac of response.vacations) {
          if (vac) {
            let m = moment(vac, "DD-MM-YYYY");
            if (m.diff(moment()) <= 0) {
              spentVacationDays.push(
                moment(vac, "DD-MM-YYYY").format("DDMMYYYY")
              );
            }
            vacationDays.push(moment(vac, "DD-MM-YYYY").format("DDMMYYYY"));
            vacationWeeks.push(moment(vac, "DD-MM-YYYY").format("W"));
            futureVacationYearWeeks.push(
              moment(vac, "DD-MM-YYYY").format("YYYYWW")
            );
          }
        }
      }

      vacationWeeks = Array.from(new Set(vacationWeeks)).sort();
      futureVacationYearWeeks = Array.from(
        new Set(futureVacationYearWeeks)
      ).sort();

      let futureVacationWeeks = [];

      for (let i = 0; i < vacationWeeks.length; i++) {
        if (vacationWeeks[i] >= moment.utc().format("W")) {
          futureVacationWeeks.push(vacationWeeks[i]);
        }
      }
      this.setState({
        vacationDays: vacationDays,
        spentVacationDays: spentVacationDays,
        vacationWeeks: vacationWeeks,
        futureVacationWeeks: futureVacationWeeks,
        futureVacationYearWeeks: futureVacationYearWeeks,
        waitingForData: false
      });
    } catch (err) {
      console.log("Request resulted in an error", err);
    }
  }

  fetchConsultants() {
    const id = this.props.match.params.id;
    const { department } = this.state;

    this.fetchVacation();

    fetch(
      `/consultants/${id}/offset/${this.props.match.params.offset}/weeks/${this.props.match.params.weeks}?departments=${department}`
    )
      .catch(reason => {
        console.log("Request resulted in an error", reason);
      })
      .then(response => {
        const yearWeeks = response.emptyStaffing.map(week => week.yearWeek);
        const orderedWeekSum = this.getWeekSum(response.engagements, true);
        const orderedAndInternalWeekSum = this.getWeekSum(
          response.engagements,
          true,
          true
        );
        const bookingStatus = this.calculateBookingStatus(
          orderedAndInternalWeekSum,
          response.emptyStaffing,
          response.disableWarnOnOverbooked
        );
        response.engagements
          .sort((a, b) => {
            let index =
              a.customerName.toLowerCase() < b.customerName.toLowerCase()
                ? 0
                : 1;

            if (b.engagementStatus === "Internt") {
              index = -1;
            }
            return index;
          })
          .reverse();

        this.setState({
          consultant: response,
          yearWeeks: yearWeeks,
          orderedWeekSum: orderedWeekSum,
          orderedAndInternalWeekSum: orderedAndInternalWeekSum,
          bookingStatus: bookingStatus,
          fullDayHours: response.settings.fullDayHours,
          waitingForData: false
        });
      });
  }

  calculateBookingStatus(
    orderedWeekSum,
    emptyStaffing,
    orderedAndInternalWeekSum,
    disableWarnOnOverbooked
  ) {
    let bookingStatus = [];
    let counter = 0;

    let vacationWeeks = this.state.vacationWeeks;
    let weeks = [];
    for (let i = 0; i < vacationWeeks.length; i++) {
      weeks.push(vacationWeeks[i] - moment.utc().format("W"));
    }

    for (let i = 0; i < orderedWeekSum.length; i++) {
      let vacation = false;
      for (let j = 0; j < vacationWeeks.length; j++) {
        if (i === weeks[j]) {
          vacation = true;
        }
      }
      let available = emptyStaffing[i].fullWeekHours > orderedWeekSum[i];
      let overbooked = false;
      if (!disableWarnOnOverbooked)
        overbooked = emptyStaffing[i].fullWeekHours < orderedWeekSum[i];
      if (overbooked) {
        this.setState({
          value:
            "Bemanningen din stemmer ikke. Her kan du justere timer for å ordne dette.",
          enable: true
        });
        counter++;
      }
      let holiday = emptyStaffing[i].fullWeekHours < 35;
      bookingStatus.push({
        available: available,
        overbooked: overbooked,
        holiday: holiday,
        vacation: vacation
      });
    }
    if (counter === 0) {
      this.setState({ value: "Bemanningen din er riktig.", enable: false });
    }
    return bookingStatus;
  }

  fetchCustomers() {
    fetch("/customers?department=" + this.state.department)
      .catch(reason => {
        console.log("Request resulted in an error", reason);
      })
      .then(response => {
        this.setState({ customers: response });
      });
  }
}

export default Holiday;
