import React from "react";
import styled from "styled-components";

const Title = styled.h1`
  font-size: 2rem;
  color: red;
`;

const Container = styled.div`
  margin: auto;
  background: #fffde0;
  padding: 3rem;
  min-height: 40%;
`;

export default function Error({ text, children }) {
  return (
    <Container>
      <Title>Error: {text}</Title>
      {children}
    </Container>
  );
}
